import React, { Component } from 'react';
import logo from '../ClientFolder/Images/rainbow.png';
import Select from 'react-select';
import axios from 'axios';
import swal from 'sweetalert';
import { TECHNICAL_ERROR_MESSAGE } from '../Customer/Constant/Constant';
import WSppiner from '../../common/WSppiner';
import { Redirect, Link } from 'react-router-dom';
import Header from './Header';
import SideBar from '../Admin/SideBar';
import SideBarMobile from './sidebar/Sidebar';
import { Container,Row,Col } from 'reactstrap';

const Role = [

    { name: "Role", value: "Role1", label: "Role1" },
    { name: "Role", value: "Role2", label: "Role2" },
    { name: "Role", value: "Role3", label: "Role3" }
]

export default class CreateRequest extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            mobile: "",
            altMobile: "",
            email: "",
            shopname: "",
            password:"",
            locality:'',
            landmark:'',
            city:'',
            country:'',
            state:'',
            pincode:'',
            errMobileMsg:'',
            isLoading: false
        }
    }
    componentDidMount(){
       // document.getElementById("currentEmailID").style.display = "none";
    }
    handleSubmit = (event) => {
        event.preventDefault();
        console.log(this.state);
        let password = this.generateP();
        console.log(password);
        this.setState({ password: password });
        const payload =   {

            name: this.state.name,
            mobile: this.state.mobile,
            altMobile: this.state.altMobile,
            email: this.state.email,
            password: this.state.password,
            shopname: this.state.shopname,  
            locality:this.state.locality,
            landmark:this.state.landmark,
            city:this.state.city,
            pincode:this.state.pincode,
            state:this.state.state,
            country:this.state.country
        }
        axios.post(`${process.env.REACT_APP_API_URL_BACKEND_CALL}/createCustomerRequest`,
            payload)

            .then(response => {

                if (response.data.status === "Success") {
                    this.setState({
                        isLoading: false,
                        
                    }); 
                    swal({
                        title: "Confirmed",
                        text: 'Customer created successfully',
                        icon: "success",
                        button: "Close",
                    });  
                }
                else {
                    this.setState({ isLoading: false });
                    swal({
                        title: "Oh snap! You got an error!!",
                        text: TECHNICAL_ERROR_MESSAGE,
                        icon: "error",
                        button: "Close",
                    });
                }

            })
            .catch(error => {
                this.setState({ isLoading: false });
                swal({
                    title: "Oh snap! You got an error!!",
                    text: TECHNICAL_ERROR_MESSAGE,
                    icon: "error",
                    button: "Close",
                });
                console.log(error)
            })
    }

    handleMobileValidation=(mobile)=>
    {
        if(this.state.mobile.length==10)
        {
            if(this.state.mobile.charAt(0)!=9)
            {
                console.log('if block')
                this.setState({errMobileMsg:'Please renter your mobile number'})
            }
            else
            {
                this.setState({errMobileMsg:''})
            }
            
        }
    }

    generateP() {
        var pass = '';
        var str = 'ABCDEFGHJKMNOPQRSTUVWXYZ' +
            'abcdefghjkmnopqrstuvwxyz0123456789@#$';

        for (let i = 1; i <= 8; i++) {
            var char = Math.floor(Math.random()
                * str.length + 1);

            pass += str.charAt(char)
        }

        return pass;
    }
    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }
    
    render() {
        if (this.state.checkStatus === true) {
            return <Redirect to="/" />
        }
        const { selectedOption } = this.state;
        return (
            <>
             {this.state.isLoading && <WSppiner isLoading={this.state.isLoading} />}
            <Container fluid>
              <Row md={12}>
              <div className="headerBarTop">
                    <Header />
              </div>
              </Row>
              <Row>
              <Col md={2}>
              <div className="sideBARColor">
                <SideBar />
           </div>
                </Col>
                <Col md={10}>
                <form onSubmit={this.handleSubmit} >
                <h5 className='text-center' style={{marginRight:'170px'}}>Create Request</h5>
                    <Container fluid>
                    <Row>
                        <Col md={5}>
                        <div className="field mt-2">
                                         <div className="label">Customer Name</div>
                                         <input type="text" name='name' value={this.state.name} onChange={this.handleChange} className='form-control-input form-control' />
                                     </div>
                                     <div className="field mt-2">
                                         <div className="label">Customer Mobile</div>
                                         <input type="number" id='mobile' name='mobile' value={this.state.mobile} onKeyUp={this.handleMobileValidation} onChange={this.handleChange} className='form-control-input form-control' />

                                     </div>
                                     <div className="field mt-2">
                                         <div className="label">Customer Alt Mobile</div>
                                         <input type="number" id='altMobile' name='altMobile' value={this.state.altMobile} onChange={this.handleChange} className='form-control-input form-control' />

                                     </div>
                                     <div className="field mt-2">
                                         <div className="label">Customer Email</div>
                                         <input type="email" id='email' name='email' value={this.state.email} onChange={this.handleChange} className='form-control-input form-control' />
                                     </div>
                                     <div className="field mt-2">
                                         <div className="label">Customer Password</div>
                                         <input type="password" id='password' name='password' value={this.state.password} onChange={this.handleChange} className='form-control-input form-control' />
                                     </div>
                                     <div className="field mt-2">
                                         <div className="label">Shop Name</div>
                                         <input type="text" id='shopname' name='shopname' value={this.state.shopname} onChange={this.handleChange} className='form-control-input form-control' />
                                     </div>
                        </Col>
                        <Col md={5}>
                       
                                     <div className="field mt-2">
                                         <div className="label">Locality</div>
                                         <input type="text" id='locality' name='locality' value={this.state.locality} onChange={this.handleChange} className='form-control-input form-control' />
                                     </div>

                                     <div className="field mt-2">
                                         <div className="label">Landmark</div>
                                         <input type="text" id='landmark' name='landmark' value={this.state.landmark} onChange={this.handleChange} className='form-control-input form-control' />
                                     </div>

                                     <div className="field mt-2">
                                         <div className="label">City</div>
                                         <input type="text" id='city' name='city' value={this.state.city} onChange={this.handleChange} className='form-control-input form-control' />
                                     </div>
                                     <div className="field mt-2">
                                         <div className="label">Pincode</div>
                                         <input type="text" id='pincode' name='pincode' value={this.state.pincode} onChange={this.handleChange} className='form-control-input form-control' />
                                     </div>
                                     <div className="field mt-2">
                                         <div className="label">State</div>
                                         <input type="text" id='state' name='state' value={this.state.state} onChange={this.handleChange} className='form-control-input form-control' />
                                     </div>

                                     <div className="field mt-2">
                                         <div className="label">Country</div>
                                         <input type="text" id='coountry' name='country' value={this.state.country} onChange={this.handleChange} className='form-control-input form-control' />
                                     </div>
                        </Col>
                    </Row>
                    <div className="field mt-3">
                                         <input type="submit" className="OrderButtionsAddPro" id='submitButton' value="Submit" />
                                     </div>
                    </Container>
                    </form>
                </Col>
              </Row>
                
                 
            </Container>
            </>

        )
    }
}
