import axios from 'axios';
import react, { Component } from 'react'
import { Modal } from 'react-bootstrap';
import { Button,Input,Badge } from 'reactstrap';
import Select from 'react-select';
import './PurchaseModal.css';
import ReactTable from '../ReactTable';
class PurchaseModal extends Component
{
    constructor(props)
    {
        super(props)
        this.state={
            isModalOpen: false,
            type:[
                { name: "category", value: "Men's", label: "Men's" },
                { name: "category", value: "Kids(Boy)", label: "Kids(Boy)" }
            ],
            size:[
                { name: "size", value: "S", label: "S" },
                { name: "size", value: "M", label: "M" },
                { name: "size", value: "L", label: "L" },
                { name: "size", value: "S,M,L", label: "S,M,L" },
                { name: "size", value: "XL", label: "XL" },
                { name: "size", value: "XXL", label: "XXL" },
                { name: "size", value: "32", label: "32" },
                { name: "size", value: "34", label: "34" },
                { name: "size", value: "36", label: "36" },
                { name: "size", value: "32,34,36", label: "32,34,36"}
            ]
            ,
            category:[
                { name: "category", value: "Silver", label: "Silver" },
                { name: "category", value: "Golden", label: "Golden" },
                { name: "category", value: "Diamond", label: "Diamond" },
            ],
            productName:[
                { name: "productName", value: "Shirt-Box", label: "Shirt-Box" },
                { name: "productName", value: "Shirt-Loose", label: "Shirt-Loose" },

                { name: "productName", value: "Shorts-Packing", label: "Shorts-Packing" },
                { name: "productName", value: "Shorts-Loose", label: "Shorts-Loose" },
                { name: "productName", value: "Shorts-Sports", label: "Shorts-Sports" },

                { name: "productName", value: "Trouser-Packing", label: "Trouser-Packing" },
                { name: "productName", value: "Trouser-Loose", label: "Trouser-Loose" },
                { name: "productName", value: "Trouser-Sports", label: "Trouser-Sports" },

                { name: "productName", value: "T-Shirt-Collar", label: "T-Shirt-Collar" },
                { name: "productName", value: "T-Shirt-Round-Neck", label: "T-Shirt-Round-Neck" },
                { name: "productName", value: "T-Shirt-Round-Neck-Sports", label: "T-Shirt-Round-Neck-Sports" },
            ]
        }
        
    }
   
    handleClose = () =>{
       this.props.handlePurchaseModal()
    }

    handleItem=()=>
    {
        this.props.handleAddItem()
    }

    handleChange=(event,index,inputType)=>
    {
        this.props.handleChange(event,index,inputType)
    }

    handleDropdownChange=(selectedOption,index,inputType)=>
    {
        this.props.handleDropdownChange(selectedOption,index,inputType);
    }

    handleRemove=(index)=>
    {
        this.props.handleRemove(index)
    }

    handlePurchaseList=()=>
    {
        this.props.handlePurchaseList();
    }

    handleChangeAmt=(event)=>
    {
        this.props.handleChangeAmt(event)
    }

    render()
    {
        const { selectedOption } = this.state;
        const {totalAmount,handleApplyGst,gstAmount,gstButtonText,gstButtonStatus,handleRemoveGst,handleChangeAmt,dueAmount,productPreviewTableBody,productPreviewTableHeader} = this.props;
        const itemList = this.props.itemList
        return(
            <div>
           <Modal size="xl" show={this.props.show} >
            <Modal.Header >
                <Modal.Title> Add New Purchase </Modal.Title>
                <Button onClick={this.handleItem} outline color="warning">Add Item</Button>
            </Modal.Header>
            <Modal.Body>
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12 col-md-12 col-sm-12">
                            {(itemList.length>0) ? (
                                <>
                                {itemList.map((items,index)=>
                                (
                                    <div className='row'>
                                        <div className='col-xl-2'>
                                        <Select isSearchable   value={selectedOption} onChange={(event)=>this.handleDropdownChange(event,index,"item")} options={this.state.productName} placeholder="Product" />
                                        </div>
                                        <div className='col-xl-2 PurchaseModalInput'  >
                                        <Select isSearchable   value={selectedOption} onChange={(event)=>this.handleDropdownChange(event,index,"category")} options={this.state.category} placeholder="Category" />
                                        </div>
                                        <div className='col-xl-2 PurchaseModalInput'  >
                                        <Select isSearchable   value={selectedOption} onChange={(event)=>this.handleDropdownChange(event,index,"type")} options={this.state.type} placeholder="Type" />
                                        </div>
                                        <div className='col-xl-1 PurchaseModalInput'  >
                                        <Select isSearchable   value={selectedOption} onChange={(event)=>this.handleDropdownChange(event,index,"size")} options={this.state.size} placeholder="Size" />
                                        </div>
                                        <div className='col-xl-1 PurchaseModalInput'  >
                                        <Input type="text"   value={items.quantity} onChange={(event)=>this.handleChange(event,index,"quantity")} placeholder="Qty"/>
                                        </div>
                                        <div className='col-xl-1 PurchaseModalInput' >
                                        <Input type="text"   value={items.rate} onChange={(event)=>this.handleChange(event,index,"rate")} placeholder="Rate"/>
                                        </div>
                                        <div className='col-xl-1 PurchaseModalInput'>
                                            {/* <label>{(items.amount!="")?items.amount:0}</label> */}
                                            <p style={{textAlign:"right"}}>
                                            <Badge color="secondary">{(items.amount!="")?items.amount:0}</Badge>
                                            </p>
                                            
                                        </div>
                                        <div className='col-xl-2 PurchaseModalInput'>
                                        <Button outline color="warning" onClick={()=>this.handleRemove(index)}>Remove</Button>
                                        </div>
                                    
                                    </div>
                                    
                                ))}
                                </>
                            ):("")}
                            {(itemList?.length>0) &&
                             <div className='row mt-3'>
                             <div className='col-xl-2'>
                             <label>Total Amt:<span>&#8377;{totalAmount}</span></label>
                             </div>
                             <div className='col-xl-4'>
                             <p><Button onClick={(gstButtonStatus == true)? handleApplyGst:handleRemoveGst}>{gstButtonText}</Button> <label>Applied GST:<span>&#8377;{gstAmount}</span></label></p>
                             
                             </div>
                             <div className='col-xl-3'>
                             <p>Received: <input type="number"  placeholder='Enter Amount' onChange={(event)=>this.handleChangeAmt(event)}/></p>
                             
                             </div>
                             <div className='col-xl-3'>
                             <label>Due Amt:</label>
                             <span>&#8377;{dueAmount}</span>
                             </div>
                         </div>  
                            }
                             
                        </div>
                    </div>
                </div>
                

                <div className='row mt-3'>

                <div className="col-xl-12 col-md-12 col-sm-12">

                {(itemList.length>0) ? (
                                <>
                                    <ReactTable 
                                    tableHeader={productPreviewTableHeader}
                                    tableBody={productPreviewTableBody}

                                    />
                                </>
                            ):("")}
                    

                </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button style={{backgroundColor:'#F39C12',borderColor:'#F39C12'}} onClick={this.handlePurchaseList}>
                   Save
                </Button>
                <Button style={{backgroundColor:'#F39C12',borderColor:'#F39C12'}} onClick={this.handleClose}>
                   Cancel
                </Button>
            </Modal.Footer>
        </Modal>

            </div>
        )
    }
}
export default PurchaseModal;