import * as ActionType from '../Constants/ServicesConstant';

export const adminLoginDetails = payload => dispatch =>
{
    dispatch({
        data:payload,
        type:ActionType.ADMIN_DETAILS
    })
}

export const employessLoginDetails = payload => dispatch =>
{
    dispatch({
        data:payload,
        type:ActionType.EMPLOYESS_DETAILS
    })
}