export let lineChartOption = {
    chart: {
     // height: 350,
      type: 'line',
      dropShadow: {
        enabled: true,
        color: '#000',
      },
      toolbar: {
        show: false
      }
    },
    colors: ['#77B6EA', '#545454'],
    plotOptions: {
      bar: {
        distributed: true,
      }
    },
    dataLabels: {
      enabled: true,
    },
    stroke: {
      curve: 'smooth'
    },
    title: {
      text: 'Sell on every month',
      align: 'center'
    },
    grid: {
      borderColor: '#e7e7e7',
      row: {
        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
        opacity: 0.5
      },
    },
    markers: {
      size: 1
    },
    xaxis: {
      categories: [],
      title: {
        text: 'Months'
      }
    },
    yaxis: {
      title: {
        text: 'No of sell'
      },
      min: 1,
      max: 6
    },
    legend: {
      show: true,
      position: 'top',
      horizontalAlign: 'right',
      floating: true,
    //   offsetY: -25,
    //   offsetX: -5
    }
  }; 


  export let lineChartOptionForSellAmount = {
    chart: {
     // height: 350,
      type: 'line',
      dropShadow: {
        enabled: true,
        color: '#000',
      },
      toolbar: {
        show: false
      }
    },
    colors: ['#77B6EA', '#545454'],
    plotOptions: {
      bar: {
        distributed: true,
      }
    },
    dataLabels: {
      enabled: true,
    },
    stroke: {
      curve: 'smooth'
    },
    title: {
      text: 'Sell on every month',
      align: 'center'
    },
    grid: {
      borderColor: '#e7e7e7',
      row: {
        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
        opacity: 0.5
      },
    },
    markers: {
      size: 1
    },
    xaxis: {
      categories: [],
      title: {
        text: 'Month'
      }
    },
    yaxis: {
      title: {
        text: 'No of sell'
      },
      min: 1,
      max: 6
    },
    legend: {
      position: 'top',
      horizontalAlign: 'right',
      floating: true,
    //   offsetY: -25,
    //   offsetX: -5
    }
  }; 