import React, { useState, useEffect } from "react";
import { Link, Redirect, useHistory } from "react-router-dom";
import "./css/SearchBar.css";
import ReactStars from "react-rating-stars-component";
import "./css/DisplayProduct.css";
// import { createTheme } from "@material-ui/core/styles";
import Slider from "@mui/material/Slider";
import { ThemeProvider } from "@material-ui/styles";

import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  status: {
    danger: '#e53e3e',
  },
  palette: {
    primary: {
      main: '#0971f1',
      darker: '#053e85',
    },
    neutral: {
      main: '#64748B',
      contrastText: '#fff',
    },
  },
});

// import   Range from 'framework7-react';

const firstExample = {
  size: 20,
  value: 4.5,
  edit: false,
};
function valuetext(value) {
  return `${value}°C`;
}

const minDistance = 10;
let minValueForSlider = 0;
let maxValueForSlider = 10000;

let pageLoadingStatus = false;
function Displayproduct(props) {
  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState(10000);
  const [value1, setValue1] = React.useState([minValue, maxValue]);
  const [Query, setQuery] =
    useState(""); /* useState to get the updated search query */
  const [productStatus, setProductStatus] = useState(false);
  const [productInfomations, setProductInfomation] = useState([]);
  const history = useHistory();

  const getfilteredItems = () => {
    sessionStorage.setItem("props", JSON.stringify(props.data));
    let result = JSON.parse(sessionStorage.getItem("props"));

    // console.log("result from get filtered items", result.length);
    if (!Query) {
      return result;
    }
    return result.filter((item) => {
      //const postName = item.pCompanyName.toLowerCase();
      const postprod = item.productName.toLowerCase();
      const pCategory = item.pCategory.toLowerCase();
      const pCategory1 = item.pCategory1.toLowerCase();
      const pCategory2 = item.pCategory2.toLowerCase();
      return (
        //postName.includes(Query.toLowerCase()) ||
        postprod.includes(Query.toLowerCase()) ||
        pCategory.includes(Query.toLowerCase()) ||
        pCategory1.includes(Query.toLowerCase()) ||
        pCategory2.includes(Query.toLowerCase())
      );
    });
  };

  let filteredItems = getfilteredItems();

  const filterByPrice = () => {
    return filteredItems.filter((item) => {
      const pPrice = parseInt(item.pPrice);

      return pPrice <= value1[1] && pPrice >= value1[0];
      // return pPrice <= maxValue && pPrice >= minValue;
    });
  };

  filteredItems = filterByPrice();

  const getMinMaxValue = () => {
    let localminvalue = 5000000;
    console.log("filtereditems", filteredItems);
    filteredItems.map((entry, index) => {
      if (parseInt(entry.pPrice) < localminvalue) {
        localminvalue = parseInt(entry.pPrice);
      }
    });

    let localmaxvalue = 0;
    filteredItems.map((entry, index) => {
      if (parseInt(entry.pPrice) > localmaxvalue) {
        localmaxvalue = parseInt(entry.pPrice);
      }
    });
    // minValueForSlider = localminvalue;
    // maxValueForSlider = localmaxvalue;
    // setMinValue(localminvalue);
    // setMaxValue(localmaxvalue);
   
    console.log(props.displayRangeBarStatus)
    console.log(filteredItems.length)
    if (props.displayRangeBarStatus == true && filteredItems.length > 0) {
      console.log("this getting called.. there are values calculated");

      minValueForSlider = localminvalue;
      maxValueForSlider = localmaxvalue;
      props.handleChangeDisplayRangeBarStatus();
    }
    pageLoadingStatus = true;
  };

  const handleChange1 = (event, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) {
      return;
    }
    if (activeThumb === 0) {
      setValue1([Math.min(newValue[0], value1[1] - minDistance), value1[1]]);
      // setMinValue(Math.min(newValue[0], value1[1] - minDistance))
      // setMaxValue(value1[1])
    } else {
      setValue1([value1[0], Math.max(newValue[1], value1[0] + minDistance)]);
      // setMinValue(value1[0])
      // setMaxValue(Math.max(newValue[1], value1[0] + minDistance))
    }
  };

  useEffect(() => {
    getMinMaxValue();
  });

  const handleEvent = (user) => {
    setProductStatus(true);
    setProductInfomation(user);
  };
  let { addToCard, buyNow } = props;
  let image = props.imageTable;
  let pTable = props.productTable;
  if (productStatus === true) {
    const productInformation = {
      productInformation: productInfomations,
      productTableName: pTable,
      imagetable: image,
      proID: productInfomations.proID,
      hash: "ProductInfo",
    };
    // console.log("test",productInformation);
    sessionStorage.setItem(
      "productDataForAddtoCart",
      JSON.stringify(productInformation)
    );
    history.push("/ProductInfo", {
      productInfomation: productInfomations,
      productTableName: pTable,
    });
    return <div></div>;
  } else {
    return (
      <div>
        <br />
        <div className=" container searchBarAndPriceFilterDiv ">
          <div className="row">
            <div className="col-xl-9 col-lg-9 col-md-12 col-sm-12">
              <input
                type="text"
                className="bar"
                placeholder="Search by product name"
                onChange={(e) => setQuery(e.target.value)}
              />
            </div>
            <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12    multiRangeSliderDiv">
              <span className="priceRangeSpan">Price range </span>

              {/* style={{border: '1px solid rgba(0,255,0,0.3)'}} */}
              <div>
                {console.log("Value",value1,"min",minValueForSlider,"max",maxValueForSlider)}
                <ThemeProvider theme={theme }>
                  {pageLoadingStatus && (
                    <Slider
                      getAriaLabel={() => "Minimum distance"}
                      value={value1}
                      // value={[3,8000]}
                      onChange={handleChange1}
                      min={minValueForSlider}
                      max={maxValueForSlider}
                      valueLabelDisplay="auto"
                      getAriaValueText={valuetext}
                      disableSwap
                    />
                  )}
                </ThemeProvider>
              </div>
            </div>
          </div>
        </div>

        {props.resultShow ? (
          <div className="showProductImage">
            {filteredItems?.map((user, index) => (
              <div className="row cartDivProduct">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                  <div
                    className="card hvr-grow rescard"
                    script={(user["imagepathtable"] = image)}
                  >
                    <Link onClick={() => handleEvent(user)}>
                      <img
                        className="card-img-top productImageShowShopProduct"
                        src={user.imagePath}
                        alt="Card image cap"
                      />
                    </Link>
                    <div className="card-body">
                      <p className="shopInformation">
                        <b>
                          {user.pCompanyName} {user.productName}
                        </b>
                      </p>
                      <p className="shopInformation">
                        Color: {user.pCategory}, Size :{user.pSize}
                      </p>
                      <p className="shopInformation">
                        Price: <b> &#8377;{user.pPrice} </b> &#8377;
                        <s>
                          {parseInt(user.pPrice) +
                            parseInt(user.pPrice) * (user.offer / 100)}
                        </s>{" "}
                        <span style={{ color: "green", fontSize: "90%" }}>
                          {user.offer}% off
                        </span>{" "}
                      </p>
                      <ReactStars {...firstExample} />
                      <p
                        className="shopInformation tooltip2"
                        style={{ textAlign: "left" }}
                      >
                        About Product : {user.pDescription.substring(0, 15)}...
                        <span className="tooltiptext">{user.pDescription}</span>
                      </p>
                      <button
                        className="addTocartButton"
                        onClick={() => addToCard(user.proID)}
                      >
                        Add to cart
                      </button>

                      <button
                        className="buyNameButton"
                        onClick={() => buyNow(user)}
                      >
                        Buy now
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div>
            <p>Data not avalible</p>
          </div>
        )}
      </div>
    );
  }
}

export default Displayproduct;
