import ReactApexChart from 'react-apexcharts';
import React, { Component } from 'react';

class ChartComponent extends Component
{
    constructor(props)
    {
        super(props);
        this.state = {

        }
    }

    render(){

        const {options,series,chartType,chartHeight} = this.props;

        return(
            <>
                <ReactApexChart  
                    options={options}
                    series={series}
                    type={chartType}
                    height={chartHeight}
                 
                  />
            </>
        )
    }
}


export default ChartComponent;
