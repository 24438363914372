export const employessRole = [
    { name: "employessRole", value: "Karigar", label: "Karigar" },
    { name: "employessRole", value: "Sales Man", label: "Sales Man" },
    { name: "employessRole", value: "Agent", label: "Agent" },
    { name: "employessRole", value: "Helper", label: "Helper" },
]

export const billStatusFilter = [
    { name: "billStatus", value: "All", label: "All" },
    { name: "billStatus", value: "Open", label: "Open" },
    { name: "billStatus", value: "Closed", label: "Closed" }
]