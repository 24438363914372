import React, { Component, useState, useEffect } from "react";
import Slider from "react-slick";
import axios from "axios"
import easyToUse from './Images/easyToUse.jpg';
import './css/ProductSlider.css';
import shirt1 from './Images/shirt-2.jpg';
import jeans from './Images/jeans-2.jpg';
import kurti1 from './Images/kurti-1.jpg';
import kurti2 from './Images/kurti-2.jpg';
import ReactStars from "react-rating-stars-component";
import { Link } from "react-router-dom";
const firstExample = {
  size: 15,
  value: 5,
  edit: false
};
const ProductSlider = (props) => {
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 4,

    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: true
  };



  return (
    <>
      <h2 style={{ textAlign: "left", margin: "1%", fontSize: "130%", marginTop: "2%" }}>Best selling products</h2>

      <div className="productSliderMainDiv pt-2 ">

        <Slider {...settings}>

          {props.productDetails.map((image) => {
            let firstExample = {
              size: 15,
              value: image.pRating,
              edit: false
            };
            return (
              <div>
                <Link to={{
                  pathname: `ShowProductSliderInfo`,
                  state: { product: image }

                }} style={{ textDecoration: "none" }}>
                  <img src={image.imagePath} className="productSliderImage" />
                  <div className="productinformation">
                    <p className=""><b>{image.productName}</b><br />Price: <b> &#8377;{image.pPrice} </b>
                      <span style={{ color: "green", fontSize: "90%" }} >{image.offer}% off</span> <ReactStars {...firstExample} /></p>
                  </div>
                </Link>
              </div>
            )
          })}







        </Slider>
      </div >
    </>
  )
}
export default ProductSlider;


{/* <div>
          <Link to="/" style={{textDecoration:"none"}}>
            <img src={image.product_image_url} className="productSliderImage" />
            <div className="productinformation">
              <p className=""><b>{image.product_name}</b><br />Price: <b> &#8377;2000 </b> &#8377;<s>{image.product_price}</s>
                <span style={{ color: "green", fontSize: "90%" }} >{image.discount_on_product}% off</span> <ReactStars {...firstExample} /></p>
            </div>
            </Link>
          </div> */}