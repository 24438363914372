import React, { Component } from 'react';
//import logo from '../ClientFolder/Images/rainbow.png';
import Select from 'react-select';
import axios from 'axios';
import swal from 'sweetalert';
// import { TECHNICAL_ERROR_MESSAGE } from '../Customer/Constant/Constant';
import WSppiner from '../../common/WSppiner';
import { Redirect, Link } from 'react-router-dom';
// import Header from './Header';
// import SideBar from '../Admin/SideBar';
// import SideBarMobile from './sidebar/Sidebar';
import ReactTable from '../../common/ReactTable';
import moment from 'moment';
import {calculateTotals, currencyFormatterFunction} from '../../Utils/Utils';
//import {minusIcon} from '../../assets/minus.png'; 
import minusIcon from '../../assets/minus.png'; 
import addIcon from '../../assets/add.png'; 
import viewIcon from '../../assets/eye.png'

const Role = [
    { name: "Role", value: "Role1", label: "Role1" },
    { name: "Role", value: "Role2", label: "Role2" },
    { name: "Role", value: "Role3", label: "Role3" }
]

const daysFilter = [
    { name: "daysFilter", value: "7 DAY", label: "Last one week" },
    { name: "daysFilter", value: "15 DAY", label: "Last 15 days" },
    { name: "daysFilter", value: "1 MONTH", label: "Last one month" },
    { name: "daysFilter", value: "3 MONTH", label: "Last three month" },
    { name: "daysFilter", value: "6 MONTH", label: "Last six month" },
    { name: "daysFilter", value: "9 MONTH", label: "Last nine month" },
    { name: "daysFilter", value: "1 YEAR", label: "Last one year" },
    { name: "daysFilter", value: "2 YEAR", label: "Last two year" },
    { name: "daysFilter", value: "3 YEAR", label: "Last three year" }
]

export default class ProductStock extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchData:"",
            editFlag:false,
            updatedStock:"",
            action:"",
            quantity:"",
            psId:"",
            tableData:[]
        }
    }

    static getDerivedStateFromProps(nextProps,prevProps)
    {
        return{
            ...prevProps,
            tableData:nextProps?.tableData
        }
    }

    addOrMinusStock = (action,quantity,psId,index) =>
    {
        let tempData = this.state?.tableData;
        tempData[index].editFlag = true;

        this.setState({
            editFlag:true,
            action:action,
            quantity:quantity,
            psId:psId,
            tableData:tempData
        })
        
    }
    
    handleSave = (index) =>
    {
        let tempData = this.state?.tableData;
        tempData[index].editFlag = false;
        this.setState({updatedStock:"",tableData:tempData})
        const {handleStockChange} = this.props;
        handleStockChange(this.state.action,this.state.quantity,this.state.psId,this.state.updatedStock)
    }

    handleCancel = (index) =>
    {
        let tempData = this.state?.tableData;
        tempData[index].editFlag = false;
        this.setState({
            tableData:tempData,
            updatedStock:"",
            editFlag:false,
            action:"",
            quantity:"",
            psId:""
        })
    }

    handleChange = (e) =>
    {
        this.setState({[e.target.name]:e.target.value})
    }

    showMoreDetails = (data)=>
    {
        const {showMoreStockDetails} = this.props;
        showMoreStockDetails(data)
    }


    render() {
        if (this.state.checkStatus === true) {
            return <Redirect to="/" />
        }

        let tableBodyData = []
        if (this.state.tableData?.length > 0) {
          tableBodyData = this.state.tableData?.filter((val) => {
            if (this.state.searchData == "") {
              return val
            } else if (val.shopName.toLowerCase().includes(this.state.searchData.toLowerCase())) {
              return val
            } 
          })?.map((user, index) => {
    
            return <tr key={index}>
              <td>{index + 1}</td>
              <td>{user.item}</td>
              <td>{user.Category}</td>
              <td>{user.Type}</td>
              <td>{user.Quantity}</td>
              <td>{user.price}</td>
              <td><img src={addIcon} style={{height:"20px",width:"20px",cursor:"pointer"}} onClick={()=>{this.addOrMinusStock('add',user.Quantity,user.psid,index)}} /></td>
              <td><img src={minusIcon} style={{height:"20px",width:"20px",cursor:"pointer"}} onClick={()=>{this.addOrMinusStock('minus',user.Quantity,user.psid,index)}} /></td>
              <td>{(user.editFlag == true) ? 
              <>
              <input type="text" name="updatedStock" value={this.state.updatedStock} onChange={this.handleChange}/> &nbsp;
              <button className='btn btn-primary' onClick={()=>{this.handleSave(index)}}>Save</button> &nbsp;
              <button className='btn btn-primary' onClick={()=>{this.handleCancel(index)}}>Cancel</button>
              </>
                :
                "-"
              }
              </td>
              <td>{moment(user.regDate).format('LL')}</td>
              <td><img src={viewIcon} style={{height:"20px",width:"20px",cursor:"pointer"}}  onClick={()=>{this.showMoreDetails(user)}} /></td>
              </tr>
          })
        }

        /// 


        let tableBodyDataForStockDetails = []
        if (this.props.stockMoreDetailsData?.length > 0) {
            tableBodyDataForStockDetails = this.props.stockMoreDetailsData?.filter((val) => {
            if (this.state.searchData == "") {
              return val
            } else if (val.shopName.toLowerCase().includes(this.state.searchData.toLowerCase())) {
              return val
            } 
          })?.map((user, index) => {
    
            return <tr key={index}>
              <td>{index + 1}</td>
              <td>{user.Quantity}</td>
              <td>{user.type}</td>
              <td>{moment(user.regDate).format('LL')}</td>
              </tr>
          })
        }

        const { transactionText,selectedOption } = this.state;

        return (
            
            <div>
                    <div className="tableDiv inMobileViewButton " style={{marginTop:'10px'}}>
                        {(this.props.stockMoreDetailsFlag) ? 
                            
                            <ReactTable 
                            tableHeader={this.props.tableHeaderForStockDetails} 
                            tableBody={tableBodyDataForStockDetails}
                        />
                        :
                        <>
                            {(tableBodyData?.length > 0) && 
                                <ReactTable 
                                tableHeader={this.props.tableHeader} 
                                tableBody={tableBodyData}
                            />
                        }
                        </>
                        }
                    </div>
            </div>
         

        )
    }
}
