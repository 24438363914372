import currencyFormatter from 'currency-formatter';

export const calculateTotals = (data) =>
{
    let totalAmount = 0;
    let totalGStAmount= 0;
    let totalRecivedAmount = 0;
    let totalDuesAmount = 0;
    for(let i=0;i<data?.length;i++)
    {
        totalAmount =  totalAmount + (parseInt(data[i].Amount) + parseInt(data[i].gstAmount) );
        totalRecivedAmount = totalRecivedAmount + parseInt(data[i].receivedAmount);
        totalGStAmount = totalGStAmount + parseInt(data[i]?.gstAmount)
    }

    totalDuesAmount = totalAmount - totalRecivedAmount;

    return {
        totalAmount,
        totalGStAmount,
        totalRecivedAmount,
        totalDuesAmount,
    }
}

export const currencyFormatterFunction = (data) =>
{
    return currencyFormatter.format(data, {
        locale: 'en-IN',
    })
}

export const createChartOption = (chartOption,seriesData,lineChartMonthInfo,title,topDownValue,legendShow=true) =>
{
    let chartOptionResult =  JSON.parse(JSON.stringify(chartOption));

    chartOptionResult.xaxis.categories = lineChartMonthInfo;
    chartOptionResult.yaxis.max = (Math.max(...seriesData) + topDownValue);
    chartOptionResult.title.text = title;
    chartOptionResult.legend.show = legendShow;
    if((Math.min(...seriesData) - topDownValue) >= 0)
    {
        chartOptionResult.yaxis.min = (Math.min(...seriesData) - topDownValue);
    }
    else
    {
        chartOptionResult.yaxis.min =topDownValue;
    }

    return chartOptionResult;
}

export const convertNumberToMonth = (monthNumber)=>
{

    if(monthNumber == 1)
    {
        return "Jan";
    }
    else if(monthNumber == 2)
    {
        return "Feb";
    }
    else if(monthNumber == 3)
    {
        return "Mar";
    }
    else if(monthNumber == 4)
    {
        return "Apr";
    }
    else if(monthNumber == 5)
    {
        return "May";
    }
    else if(monthNumber == 6)
    {
        return "Jun";
    }
    else if(monthNumber == 7)
    {
        return "Jul";
    }
    else if(monthNumber == 8)
    {
        return "Aug";
    }
    else if(monthNumber == 9)
    {
        return "Sep";
    }
    else if(monthNumber == 10)
    {
        return "Oct";
    }
    else if(monthNumber == 11)
    {
        return "Nov";
    }
    else if(monthNumber == 12)
    {
        return "Dec";
    }
    else
    {
        return "";
    }
} 