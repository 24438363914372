import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import swal from 'sweetalert';
import { TECHNICAL_ERROR_MESSAGE } from '../Customer/Constant/Constant';
import Header from '../ClientFolder/Header';
import SideBar from './SideBar';
import '../ClientFolder/css/Home.css';
import ReactPaginate from 'react-paginate';
import { Redirect, Link } from 'react-router-dom';
// import filters from '../ClientFolder/Filters';
import moreDatails from '../ClientFolder/Images/moreDatails.png';
// import check from './Images/check.png';
// import addresImage from './Images/site.png';
// import customerImage from './Images/user.png';
// import close from './Images/close.png';
import { Button, Card, Modal } from 'react-bootstrap';
import SideBarMobile from './sidebar/Sidebar';
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import WSppiner from '../../common/WSppiner';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import '../ClientFolder/css/NewOrderDetails.css';
import { getDateFormat } from '../sharedModuls/Utils/Utils';



class ordermoredetails extends Component {
    constructor(props) {
        super(props);

        this.state = {
            checkLoginStatus: true,
            data: [],
            data2: [],
            offset: 0,
            tableData: [],
            orgtableData: [],
            perPage: 10,
            currentPage: 0,
            orderID: 0,
            afterDiscount: 0,
            totalQunatity: 0,

            usrData: this.props.match.params.userData,
            totalAmt: 0,
            addressTab: false,
            customerTab: false,
            customerID: 0,
            delAddressID: 0,

            delLocality: "",
            delLandmark: "",
            delCity: "",
            delState: "",
            delCountry: "",
            delPincode: "",
            delMobileF: "",
            delMobileS: "",


            cusName: "",
            cusEmail: "",
            cusAddress: "",
            cusCity: "",
            cusState: "",
            cusCountry: "",
            cusPincode: "",
            cusMobile: "",
            cusGender: "",
            isLoading: false


        }

        let token = sessionStorage.getItem("deliveryBoyEmail");

        if (token == null || token == undefined || token == "") {

            this.state.checkLoginStatus = false;
        }
    }

    componentDidMount() {

        if (this.state.checkLoginStatus == true) {


            let ID = sessionStorage.getItem("deliveryBoyId");

            // let productTable = ID+"producttable";
            // let sum = 0;
            // let totalQunatity = 0;
            // let afterDiscountPrice = 0;
            axios.post(`${process.env.REACT_APP_API_URL_BACKEND_CALL}/getDeliveryOrders`, { deliveryBoyId: ID }
                , { headers: { 'x-access-token': localStorage.getItem("accessToken") } })
                .then(response => {
                    this.setState({
                        isLoading: false,
                        data: response.data
                    });


                    console.log(response.data)

                })
                .catch(error => {
                    this.setState({ isLoading: false });
                    swal({
                        title: "Oh snap! You got an error!!",
                        text: TECHNICAL_ERROR_MESSAGE,
                        icon: "error",
                        button: "Close",
                    });
                    console.log(error)
                })
        }

    }

    // 

    handleModalAddressTab = () => {
        this.setState({ addressTab: !this.state.addressTab });
    }

    handleModalCustomerTab = () => {
        this.setState({ customerTab: !this.state.customerTab });
    }



    getCustomerAddress = () => {
        this.setState({ isLoading: true });
        axios.post(`${process.env.REACT_APP_API_URL_BACKEND_CALL}/getCustomerAddress`, { delAddress: this.state.delAddressID }
            , { headers: { 'x-access-token': localStorage.getItem("accessToken") } }).
            then(response => {



                this.setState({
                    delMobileF: response.data[0].mobile,
                    delMobileS: response.data[0].altmobile,
                    delLocality: response.data[0].locality,
                    delLandmark: response.data[0].landmark,
                    delCity: response.data[0].city,
                    delState: response.data[0].state,
                    delPincode: response.data[0].pincode,
                    delCountry: response.data[0].country,
                    addressTab: true,
                    isLoading: false
                });
            })
            .catch(error => {
                this.setState({ isLoading: false });
                swal({
                    title: "Oh snap! You got an error!!",
                    text: TECHNICAL_ERROR_MESSAGE,
                    icon: "error",
                    button: "Close",
                });
                console.log(error)
            })
    }


    // getCustomerDetails 

    getCustomerDetails = () => {
        this.setState({ isLoading: true });
        axios.post(`${process.env.REACT_APP_API_URL_BACKEND_CALL}/getCustomerDetails1`, { customerID: this.state.customerID }
            , { headers: { 'x-access-token': localStorage.getItem("accessToken") } }).
            then(response => {



                this.setState({
                    cusName: response.data[0].cName,
                    cusMobile: response.data[0].cMobile,
                    cusEmail: response.data[0].cEmail,
                    cusAddress: response.data[0].cAddress,
                    cusCity: response.data[0].cCity,
                    cusState: response.data[0].cState,
                    cusPincode: response.data[0].cPincode,
                    cusCountry: response.data[0].cCountry,
                    cusGender: response.data[0].cGender,
                    customerTab: true,
                    isLoading: false
                });
            })
            .catch(error => {
                this.setState({ isLoading: false });
                swal({
                    title: "Oh snap! You got an error!!",
                    text: TECHNICAL_ERROR_MESSAGE,
                    icon: "error",
                    button: "Close",
                });
                console.log(error)
            })
    }



    downloadCSV = () => {
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

        const fileExtension = '.csv';

        const ws = XLSX.utils.json_to_sheet(this.state.data);

        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };

        const excelBuffer = XLSX.write(wb, { bookType: 'csv', type: 'array' });

        const data = new Blob([excelBuffer], { type: fileType });

        FileSaver.saveAs(data, "report" + fileExtension);

    }

    render() {
        if (this.state.checkLoginStatus === false) {
            return <Redirect to="/userLogin" />
        }


        return (

            <div>
                {this.state.isLoading && <WSppiner isLoading={this.state.isLoading} />}
                <div className="headerBarTop">

                    <Header />
                </div>

                <div className="mobileViewSidebar">
                    <SideBarMobile />
                </div>

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-2 col-md-12 col-sm-12 sideBARColor">

                            <SideBar />

                        </div>

                        <div className="col-xl-10 col-md-12 col-sm-12  mainBar">


                            <div className="container-fluid">
                                <div className="row">

                                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">

                                        <h5>Delivery Orders Details </h5>

                                        <div className="tableDiv">

                                            <table className="table">
                                                <thead className="tableHead">
                                                    <tr>
                                                    <th>S.no</th>
                                                            <th>OrderId</th>
                                                            <th>DeliveryStatus</th>
                                                            <th>Order Date</th>
                                                            <th>More Details</th>
                                                    </tr>
                                                </thead>

                                                <tbody>

                                                    {
                                                        this.state.data.map((user, index) => (

                                                            <tr key={index}>
                                                                <td>{index + 1}</td>
                                                                <td>{user.orderid} </td>
                                                                <td>{user.deliverystatus == 0 ?
                                                                    'Not Delivered' : 'Delivered'}</td>
                                                                <td>{getDateFormat(user.regdate)}</td>
                                                                {/* <button className="mt-2 OrderButtionsInvoice" style={{ color: "white", outline: "none" }} onClick={() => this.UpdateAssignDeliveryboy(user.deliveryboyid, user.orderid)}>More Details</button> */}
                                                                <td><Link to={`/MoreOrderDetails/${user.assignmentid}pro${user.orderid}`}><img src={moreDatails} className="buttonLogo" title="Edit" /></Link></td>
                                                            </tr>

                                                        ))
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}



export default ordermoredetails;