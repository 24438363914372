import React, { Component } from 'react';
import logo from '../ClientFolder/Images/rainbow.png';
import Select from 'react-select';
import axios from 'axios';
import swal from 'sweetalert';
import { TECHNICAL_ERROR_MESSAGE } from '../Customer/Constant/Constant';
import WSppiner from '../../common/WSppiner';
import { Redirect, Link } from 'react-router-dom';
import Header from './Header';
import SideBar from '../Admin/SideBar';
import SideBarMobile from './sidebar/Sidebar';
import ReactTable from '../../common/ReactTable';
import moment from 'moment';
import {calculateTotals, currencyFormatterFunction} from '../../Utils/Utils';
import ProductStock from '../../common/Stock/ProductStock';


const Role = [

    { name: "Role", value: "Role1", label: "Role1" },
    { name: "Role", value: "Role2", label: "Role2" },
    { name: "Role", value: "Role3", label: "Role3" }
]

const daysFilter = [
    { name: "daysFilter", value: "7 DAY", label: "Last one week" },
    { name: "daysFilter", value: "15 DAY", label: "Last 15 days" },
    { name: "daysFilter", value: "1 MONTH", label: "Last one month" },
    { name: "daysFilter", value: "3 MONTH", label: "Last three month" },
    { name: "daysFilter", value: "6 MONTH", label: "Last six month" },
    { name: "daysFilter", value: "9 MONTH", label: "Last nine month" },
    { name: "daysFilter", value: "1 YEAR", label: "Last one year" },
    { name: "daysFilter", value: "2 YEAR", label: "Last two year" },
    { name: "daysFilter", value: "3 YEAR", label: "Last three year" }
]

export default class AdminStock extends Component {
    constructor(props) {
        super(props);
        this.state = {
            transactionText:"Total transaction",
            tableHeader: [
                "Sl. No",
                "Item",
                "Category",
                "Type",
                "Quantity",
                "Price",
                "Minus",
                "Add",
                "Action",
                "Update date",
                "Details"
              ],
              tableHeaderForStockDetails: [
                "Sl. No",
                "Quantity",
                "Action",
                "Date",
              ],
              tableData:[],
              searchData: "",
              startDate:"",
              endDate:"",
              totalAmount:0,
              totalGStAmount:0,
              totalDuesAmount:0,
              totalRecivedAmount:0,
              loading:false,
              stockMoreDetailsFlag:false,
              stockMoreDetailsData:[],
              productStockHeading:""
        }
    }
    componentDidMount(){

      this.setState({
        loading:true
    })
       this.getStockDetails();
       // document.getElementById("currentEmailID").style.display = "none";
    }


    handleStockChange = (action,value,psId,updatedStock) =>
    {
        let totalStock = value;
        if(action == 'add')
        {
            totalStock = parseInt(value) + parseInt(updatedStock);
        }
        else
        {
            totalStock = parseInt(value) - parseInt(updatedStock);
        }

        const payload = {
            psId:psId,
            totalStock:totalStock,
            type:action,
            quantity:updatedStock
        }
        axios.post(`${process.env.REACT_APP_API_URL_BACKEND_ENDPOINT}/updateStockInfo`,payload,
        {headers : {'x-access-token' : localStorage.getItem("accessToken")}})
        .then((response)=>{

            if(response.data?.statusFlag == true)
            {
                this.getStockDetails();
            }
        })
        .catch((error)=>{

            swal({
              title: "Confirmed",
              text: TECHNICAL_ERROR_MESSAGE,
              icon: "error",
              button: "Close",
          }); 
          this.setState({
              loading:false
          })
        })
    }

    getStockDetails = () =>
    {
        axios.post(`${process.env.REACT_APP_API_URL_BACKEND_ENDPOINT}/totalStockInfo`,{},
        {headers : {'x-access-token' : localStorage.getItem("accessToken")}})
        .then((response)=>{

            if(response.data?.stockList?.length > 0)
            {
                let tempData = response.data?.stockList;
                for(let i=0;i<tempData?.length;i++)
                {
                    tempData[i]['editFlag'] = false;
                }
                this.setState({
                    tableData:tempData,
                    loading:false
                })
            }
        })
        .catch((error)=>{

            swal({
              title: "Confirmed",
              text: TECHNICAL_ERROR_MESSAGE,
              icon: "error",
              button: "Close",
          }); 
          this.setState({
              loading:false
          })
        })
    }

    getTransactionDetailsBetweenTwoDate = (filterApply,filterValue = []) =>
    {
        const payload = {
            startDate:this.state.startDate,
            endDate:this.state.endDate
          }
        axios.post(`${process.env.REACT_APP_API_URL_BACKEND_ENDPOINT}/totalTransactionsBetweenTwoDates`,payload,
        {headers : {'x-access-token' : localStorage.getItem("accessToken")}})
        .then((response)=>{
                if(response.data?.itemList?.length > 0)
                {
                  const totalInfo = calculateTotals(response.data?.itemList);
                  this.setState({
                      
                      tableData:response.data?.itemList,
                      ...totalInfo
                      
                  })
                }
        })
        .catch((error)=>{

        })
    }


    handleChangesDate = (event)=>
    {
        const splitDate = event.value?.split(" ")
        this.getTransactionDetails(true,splitDate);
        this.setState({
          transactionText:event.label,

        })
    }
    
    handleStartDate = (event)=>
    {
        this.setState({startDate:event.target.value})
    }

    handleEndDate = (event)=>
    {
        this.setState({endDate:event.target.value})
    }


    showMoreStockDetails = (stockDetails)=>
    {
        axios.post(`${process.env.REACT_APP_API_URL_BACKEND_ENDPOINT}/getProductStockDetails`,
        {psid:stockDetails.psid},
        {headers : {'x-access-token' : localStorage.getItem("accessToken")}})
        .then((response)=>{

            if(response.data?.stockList?.length > 0)
            {
                this.setState({
                    stockMoreDetailsData:response.data?.stockList,
                    loading:false,
                    stockMoreDetailsFlag:true,
                    productStockHeading:`${stockDetails.item}-${stockDetails.Category}-${stockDetails.Type}`
                })
            }
        })
        .catch((error)=>{

            swal({
              title: "Confirmed",
              text: TECHNICAL_ERROR_MESSAGE,
              icon: "error",
              button: "Close",
          }); 
          this.setState({
              loading:false
          })
        })
    }
    
    handleBack = ()=>
    {
        this.setState({
            stockMoreDetailsFlag:false,
            productStockHeading:""
        })
    }

    render() {
        if (this.state.checkStatus === true) {
            return <Redirect to="/" />
        }

        const { transactionText,selectedOption } = this.state;

        return (
            
            <div>

            {this.state.loading && 
                <WSppiner isLoading={this.state.loading}/>
                }

                 <div className="headerBarTop">

                <Header />
                </div>

                <div className="mobileViewSidebar">
                <SideBarMobile />
                </div>

                <div className="container-fluid">
                <div className="row">
                <div className="col-xl-2 col-md-12 col-sm-12 sideBARColor">

                <SideBar />

                </div> 

              <div className="col-xl-10 col-md-12 col-sm-12  mainBar">
                <h5 style={{textAlign:"left"}}>Product Stock{(this.state.productStockHeading) && 
                    <span style={{fontSize:"14px"}}>: {this.state.productStockHeading}</span>
                }</h5>
                {(this.state.tableData?.length > 0) && 

                    <ProductStock 
                            tableHeader={this.state.tableHeader}
                            tableData={this.state.tableData}
                            tableHeaderForStockDetails={this.state.tableHeaderForStockDetails}
                            handleStockChange={this.handleStockChange}
                            showMoreStockDetails={this.showMoreStockDetails}
                            stockMoreDetailsFlag={this.state.stockMoreDetailsFlag}
                            stockMoreDetailsData={this.state.stockMoreDetailsData}
                       />
                }
                
                {(this.state.stockMoreDetailsFlag == true) && 
                    <button className='StockDetailsBackButton'  onClick={this.handleBack}>Back</button>
                }
                
                   
              </div>
              </div>
              </div>
                
            </div>
         

        )
    }
}
