import React, { Component } from "react";
import ReactDOM from "react-dom";
import swal from "sweetalert";
import { TECHNICAL_ERROR_MESSAGE } from "../Customer/Constant/Constant";
import axios from "axios";
import Header from "./Header";
import SideBar from "./SideBar";
import Select from "react-select";
import "./css/Home.css";
import Compressor from "compressorjs";
import { Redirect, Link } from "react-router-dom";
import { Button, Card, Modal } from "react-bootstrap";
import edit from "./Images/pencil.svg";
import deleteImage from "./Images/delete.svg";
import filters from "./Filters";
import ReactPaginate from "react-paginate";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import shirt from "./Images/shirt.svg";
import WSppiner from "../../common/WSppiner";
import "./css/ShowSliderData.css";
import SideBarMobile from "./sidebar/Sidebar";
let category = [
  { name: "category", value: "Men", label: "Men" },
  { name: "category", value: "Women", label: "Women" },
  { name: "category", value: "Kids(Boy)", label: "Kids(Boy)" },
  { name: "category", value: "Kids(Girls)", label: "Kids(Girls)" },
];

let allSize = [
  { name: "size", value: "XS,34,85", label: "XS,34,85" },
  { name: "size", value: "S,36,90", label: "S,36,90" },
  { name: "size", value: "M,38,95", label: "M,38,95" },
  { name: "size", value: "L,40,100", label: "L,40,100" },
  { name: "size", value: "XL,42,105", label: "XL,42,105" },
  { name: "size", value: "XXL,44,110", label: "XXL,44,110" },
  { name: "size", value: "3XL,46,115", label: "3XL,46,115" },
  { name: "size", value: "4XL,48,120", label: "4XL,48,120" },
];

class ShowSliderData extends Component {
  constructor(props) {
    super(props);

    this.state = {
      checkLoginStatus: true,
      show: false,
      showarrivalsmodal : false,

      offset: 0,
      tableData: [],
      orgtableData: [],
      perPage: 10,
      currentPage: 0,
      editednameNewArrivals : "",
      editedratingsNewArrivals : "",
      editedpriceNewArrivals : "",
      editeddiscountNewArrivals : "",
      product_image_urlNewArrivals : "",
      data: [],
      data2: [],

      data_from_slider: [],
      data_from_newarrivals: [],
      filterArray: [],
      temp: [],
      temp1: [],
      dol: "",

      shownewarrivals: false,
      showbestselling: false,

      proID: "",
      companyNameE: "",
      productNameE: "",
      categorySet: "",
      colorSet: "",
      sizeName: "",
      quantity: "",
      mrp: "",
      price: "",
      description: "",

      companyFilter: [],
      productFilter: [],
      sizeFilter: [],
      colorFilter: [],
      productImage: [],

      companyDefault: [],
      productDefault: [],
      colorDefault: [],
      categotyDefault: [],
      sizeDefault: [],
      isLoading: false,
      p_id: "",
      product_name: "",
      product_ratings: "",
      product_price: "",
      discount_on_product: "",

      product_image_url: "",

      imageedited: false,

      editedprice: "",
      editedname: "",
      editedratings: "",
      editeddiscount: "",
      fileObj: [],
      imageData: [],
      fileArray: [],
      f: {
        pCompanyName: [],
        productName: [],
        pCategory: [],
        pColor: [],
        pSize: [],
      },
    };

    let token = sessionStorage.getItem("emailID");

    if (token == null || token == undefined || token == "") {
      this.state.checkLoginStatus = false;
    }

    this.handleChange1 = this.handleChange1.bind(this);
    this.handleChange2 = this.handleChange2.bind(this);
    this.handleChange3 = this.handleChange3.bind(this);
    this.handleChange4 = this.handleChange4.bind(this);
    this.handleChange5 = this.handleChange5.bind(this);
  }

  uploadMultipleFiles = async (e) => {
    if (this.state.imageData.length < 1) {
      console.log("can be executed");
      this.setState({ imageedited: true });
      console.log(e.target.files);
      new Compressor(e.target.files[0], {
        quality: 0.1,
        success: (compressedResult) => {
          this.state.imageData.push(compressedResult);
        },
      });
      console.log("this is the imageData now ", this.state.imageData);

      this.state.fileObj.push(e.target.files);

      for (let i = 0; i < this.state.fileObj[0].length; i++) {
        this.state.fileArray.push(
          URL.createObjectURL(this.state.fileObj[0][i])
        );
      }

      this.setState({ file: this.state.fileArray });
    } else {
      console.log("cant upload more than 1 image");
    }
  };
  handleChange1 = (selectedOptions) => {
    let a = { ...this.state.f };
    a.pCompanyName = selectedOptions;

    let b = [];
    this.setState(
      {
        f: a,
        filterArray: [...b],
        temp1: [...b],
        temp: [...b],
        category: [...b],
      },
      this.method
    );
  };

  handleChange2 = (selectedOptions) => {
    let a = { ...this.state.f };
    a.productName = selectedOptions;
    let b = [];
    this.setState(
      { f: a, filterArray: [...b], temp1: [...b], temp: [...b] },
      this.method
    );
  };

  handleChange3 = (selectedOptions) => {
    let a = { ...this.state.f };
    a.pCategory = selectedOptions;
    let b = [];
    this.setState(
      { f: a, filterArray: [...b], temp1: [...b], temp: [...b] },
      this.method
    );
  };

  handleChange4 = (selectedOptions) => {
    let a = { ...this.state.f };
    a.pColor = selectedOptions;
    let b = [];
    this.setState(
      { f: a, filterArray: [...b], temp1: [...b], temp: [...b] },
      this.method
    );
  };

  handleChange5 = (selectedOptions) => {
    let a = { ...this.state.f };
    a.pSize = selectedOptions;
    let b = [];
    this.setState(
      { f: a, filterArray: [...b], temp1: [...b], temp: [...b] },
      this.method
    );
  };

  handleChangeAll = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  deleteDataFromNewArrivals = (userID) => {
    console.log("deleting  ", userID);
    // let sampleID = 4 ;
    let formData = new FormData();
    // console.log("trying to delete entry no "  , userID)
    formData.append("p_id", userID);
    // formData.append("p_id", userID);
    this.setState({ isLoading: true });
    axios
      .post(
        `${process.env.REACT_APP_API_URL_BACKEND_CALL}/deleteDataFromNewArrivals`,
        formData,
        { headers: { "x-access-token": localStorage.getItem("accessToken") } }
      )
      .then((response) => {
        this.setState({ isLoading: false });
        if (response.data.deleted) {
          toast.success("Product deleted !!!", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });

          setTimeout(function () {}, 3000);

          this.componentDidMount();
        } else {
          console.log("kuch delete nai hua hai ");
        }
      })
      .catch(function (error) {
        this.setState({ isLoading: false });
        swal({
          title: "Oh snap! You got an error!!",
          text: TECHNICAL_ERROR_MESSAGE,
          icon: "error",
          button: "Close",
        });
        alert(error);
      });
  };
  deleteData = (userID) => {
    console.log("deleting  ", userID);
    // let sampleID = 4 ;
    let formData = new FormData();
    // console.log("trying to delete entry no "  , userID)
    formData.append("p_id", userID);
    // formData.append("p_id", userID);
    this.setState({ isLoading: true });
    axios
      .post(
        `${process.env.REACT_APP_API_URL_BACKEND_CALL}/deleteProductSliderData`,
        formData,
        { headers: { "x-access-token": localStorage.getItem("accessToken") } }
      )
      .then((response) => {
        this.setState({ isLoading: false });
        if (response.data.deleted) {
          toast.success("Product deleted !!!", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });

          setTimeout(function () {}, 3000);

          this.componentDidMount();
        } else {
          console.log("kuch delete nai hua hai ");
        }
      })
      .catch(function (error) {
        this.setState({ isLoading: false });
        swal({
          title: "Oh snap! You got an error!!",
          text: TECHNICAL_ERROR_MESSAGE,
          icon: "error",
          button: "Close",
        });
        alert(error);
      });
  };

  accessProduct = () => {
    this.state.productFilter = [];
    this.setState({ isLoading: true });
    axios
      .post(`${process.env.REACT_APP_API_URL_BACKEND_CALL}/allRegProduct`, [], {
        headers: { "x-access-token": localStorage.getItem("accessToken") },
      })
      .then((response) => {
        this.setState({ isLoading: false });
        this.state.productName = [];
        for (let i = 0; i < response.data.length; i++) {
          this.state.productFilter.push({
            name: "product",
            value: response.data[i].productName,
            label: response.data[i].productName,
          });
        }

        this.setState({ demo: "kol" });
      })
      .catch(function (error) {
        this.setState({ isLoading: false });
        swal({
          title: "Oh snap! You got an error!!",
          text: TECHNICAL_ERROR_MESSAGE,
          icon: "error",
          button: "Close",
        });
        alert(error);
      });
  };

  accessCompany = () => {
    this.state.companyFilter = [];
    this.setState({ isLoading: true });
    axios
      .post(
        `${process.env.REACT_APP_API_URL_BACKEND_CALL}/ShowAllCompany`,
        [],
        { headers: { "x-access-token": localStorage.getItem("accessToken") } }
      )
      .then((response) => {
        this.setState({ isLoading: false });

        for (let i = 0; i < response.data.length; i++) {
          this.state.companyFilter.push({
            name: "company",
            value: response.data[i].companyName,
            label: response.data[i].companyName,
          });
        }
      })
      .catch(function (error) {
        this.setState({ isLoading: false });
        swal({
          title: "Oh snap! You got an error!!",
          text: TECHNICAL_ERROR_MESSAGE,
          icon: "error",
          button: "Close",
        });
        alert(error);
      });
  };

  accessColor = () => {
    this.state.colorFilter = [];

    this.setState({ isLoading: true });
    axios
      .post(`${process.env.REACT_APP_API_URL_BACKEND_CALL}/allRegColors`, [], {
        headers: { "x-access-token": localStorage.getItem("accessToken") },
      })
      .then((response) => {
        this.setState({ isLoading: false });
        for (let i = 0; i < response.data.length; i++) {
          this.state.colorFilter.push({
            name: "colors",
            value: response.data[i].colorName,
            label: response.data[i].colorName,
          });
        }

        this.setState({ demo: "kol" });
      })
      .catch(function (error) {
        this.setState({ isLoading: false });
        swal({
          title: "Oh snap! You got an error!!",
          text: TECHNICAL_ERROR_MESSAGE,
          icon: "error",
          button: "Close",
        });
        alert(error);
      });
  };

  // For pagination

  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState(
      {
        currentPage: selectedPage,
        offset: offset,
      },
      () => {
        this.loadMoreData();
      }
    );
  };

  loadMoreData() {
    const data = this.state.orgtableData;

    const slice = data.slice(
      this.state.offset,
      this.state.offset + this.state.perPage
    );
    this.setState({
      pageCount: Math.ceil(data.length / this.state.perPage),
      tableData: slice,
    });
  }

  method = () => {
    let p = [...this.state.data];
    let user = filters(this.state.f, p);

    this.setState({ data2: user });
    var tdata = user;
    var slice = tdata.slice(
      this.state.offset,
      this.state.offset + this.state.perPage
    );
    this.setState({
      pageCount: Math.ceil(tdata.length / this.state.perPage),
      orgtableData: tdata,
      tableData: slice,
    });
  };

  componentDidMount = () => {
    this.getProductSliderData();
    this.getNewArrivalsData();
  };

  getNewArrivalsData = () => {
    let formData = new FormData();

    axios
      .get(
        `${process.env.REACT_APP_API_URL_BACKEND_CALL}/getNewArrivalsInformation`
      )
      .then((response) => {
        this.setState({ isLoading: false });
        this.accessCompany();
        this.accessProduct();
        this.accessColor();
        if (response.data.newarrivals.length > 0) {
         
          this.setState({ data_from_newarrivals: response.data.newarrivals });
        
        }
      })
      .catch((error) => {
        // conso.elog("")
        swal({
          title: "Oh snap! You got an error!!",
          text: TECHNICAL_ERROR_MESSAGE,
          icon: "error",
          button: "Close",
        });
        alert(error);
      });
  };
  getProductSliderData = () => {
    let formData = new FormData();

    axios
      .get(
        `${process.env.REACT_APP_API_URL_BACKEND_CALL}/getProductSliderInformation`
      )
      .then((response) => {
        this.setState({ isLoading: false });
        this.accessCompany();
        this.accessProduct();
        this.accessColor();
        if (response.data.productDetails.length > 0) {
          
          this.setState({ data_from_slider: response.data.productDetails });
          //   this.setState({ data: response.data });
          // this.setState({ data: response.data }, this.method);
        }
        // this.setState({productDetails : response.data.productDetails})
      })
      .catch((error) => {
        // conso.elog("")
        swal({
          title: "Oh snap! You got an error!!",
          text: TECHNICAL_ERROR_MESSAGE,
          icon: "error",
          button: "Close",
        });
        alert(error);
      });
  };
  submitModal = () => {
    let formData = new FormData();

    let p_id = this.state.p_id;

    formData.append("newname", this.state.editedname);
    formData.append("newprice", this.state.editedprice);
    formData.append("newdiscount", this.state.editeddiscount);
    formData.append("newrating", this.state.editedratings);
    formData.append("p_id", p_id);
    formData.append("imageedited", this.state.imageedited);
    formData.append(`imageData`, this.state.imageData[0]);
    // formData.append("descryption", this.state.description);

    console.log("this is imageedited ", this.state.imageedited);
    console.log("this is iamge data");
    this.setState({ isLoading: true });
    axios
      .post(
        `${process.env.REACT_APP_API_URL_BACKEND_CALL}/updateProductSlider`,
        formData,
        { headers: { "x-access-token": localStorage.getItem("accessToken") } }
      )
      .then((response) => {
        if (response.data === "updatesuccess") {
          this.setState({ show: !this.state.show, isLoading: false });

          toast.success("Product updated !!!", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });

          this.getProductSliderData();
        }

        this.getProductSliderData();
      })
      .catch(function (error) {
        console.log("error", error);
        this.setState({ isLoading: false });
        swal({
          title: "Oh snap! You got an error!!",
          text: TECHNICAL_ERROR_MESSAGE,
          icon: "error",
          button: "Close",
        });
        // alert(error);
      });
  };
  submitModalNewArrivals = () => {
    let formData = new FormData();

    let p_id = this.state.p_id;

    console.log("trying to save changes to new arrivals")
    formData.append("newname", this.state.editednameNewArrivals);
    formData.append("newprice", this.state.editedpriceNewArrivals);
    formData.append("newdiscount", this.state.editeddiscountNewArrivals);
    formData.append("newrating", this.state.editedratingsNewArrivals);
    formData.append("p_id", p_id);
    // formData.append("imageedited", this.state.imageedited);
    // formData.append(`imageData`, this.state.imageData[0]);
    // // formData.append("descryption", this.state.description);

    // console.log("this is imageedited ", this.state.imageedited);
    // console.log("this is iamge data");
    this.setState({ isLoading: true });
    axios
      .post(
        `${process.env.REACT_APP_API_URL_BACKEND_CALL}/updateNewArrivals`,
        formData,
        { headers: { "x-access-token": localStorage.getItem("accessToken") } }
      )
      .then((response) => {
        if (response.data === "updatesuccess") {
          this.setState({ showarrivalsmodal: !this.state.showarrivalsmodal, isLoading: false });

          toast.success("Product updated !!!", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });

          this.getNewArrivalsData();
        }

        this.getNewArrivalsData();
      })
      .catch(function (error) {
        console.log("error", error);
        this.setState({ isLoading: false });
        swal({
          title: "Oh snap! You got an error!!",
          text: TECHNICAL_ERROR_MESSAGE,
          icon: "error",
          button: "Close",
        });
        // alert(error);
      });
  };

  setShowNewArrivals = () => {
    console.log("showing new arrivals");
    this.setState({ shownewarrivals: true });
    this.setState({ showbestselling: false });
  };
  setShowBestSelling = () => {
    console.log("showing best seling");
    this.setState({ shownewarrivals: false });
    this.setState({ showbestselling: true });
  };

  handleModalGetDataFromNewArrivals = (userID) => {
    this.state.colorDefault = [];
    this.state.categotyDefault = [];
    this.state.sizeDefault = [];
    this.state.productDefault = [];
    this.state.companyDefault = [];

    let formData = new FormData();

    let sampleId = userID;
    formData.append("emailID", sessionStorage.getItem("emailID"));
    formData.append("shopid", sessionStorage.getItem("shopID"));
    formData.append("proID", sampleId);
    console.log("trying to call this ")
    // formData.append("proID", userID);
    this.setState({ isLoading: true });
    axios
      .post(
        `${process.env.REACT_APP_API_URL_BACKEND_CALL}/getNewArrivalInfo`,
        formData,
        { headers: { "x-access-token": localStorage.getItem("accessToken") } }
      )
      .then((response) => {
        this.setState({
          p_id: response.data[0].p_id,
          editednameNewArrivals: response.data[0].product_name,
          editedratingsNewArrivals: response.data[0].product_ratings,
          editedpriceNewArrivals: response.data[0].product_price,
          editeddiscountNewArrivals: response.data[0].discount_on_product,
          product_image_urlNewArrivals: response.data[0].product_image_url,
          isLoading: false,
        });

        // this.state.colorDefault.push({
        //   name: "colors",
        //   value: response.data[0].pColor,
        //   label: response.data[0].pColor,
        // });

        // this.state.companyDefault.push({
        //   name: "company",
        //   value: response.data[0].pCompanyName,
        //   label: response.data[0].pCompanyName,
        // });

        // this.state.productDefault.push({
        //   name: "product",
        //   value: response.data[0].productName,
        //   label: response.data[0].productName,
        // });

        // this.state.categotyDefault.push({
        //   name: "category",
        //   value: response.data[0].pCategory,
        //   label: response.data[0].pCategory,
        // });

        // this.state.sizeDefault.push({
        //   name: "size",
        //   value: response.data[0].pSize,
        //   label: response.data[0].pSize,
        // });

        this.setState({ showarrivalsmodal: !this.state.showarrivalsmodal });
      })
      .catch(function (error) {
        // this.setState({ isLoading: false });
        swal({
          title: "Oh snap! You got an error!!",
          text: TECHNICAL_ERROR_MESSAGE,
          icon: "error",
          button: "Close",
        });
        alert(error);
      });
  };

  handleModalGetData = (userID) => {
    this.state.colorDefault = [];
    this.state.categotyDefault = [];
    this.state.sizeDefault = [];
    this.state.productDefault = [];
    this.state.companyDefault = [];

    let formData = new FormData();

    let sampleId = userID;
    formData.append("emailID", sessionStorage.getItem("emailID"));
    formData.append("shopid", sessionStorage.getItem("shopID"));
    formData.append("proID", sampleId);
    // formData.append("proID", userID);
    this.setState({ isLoading: true });
    axios
      .post(
        `${process.env.REACT_APP_API_URL_BACKEND_CALL}/getProductInfoSlider`,
        formData,
        { headers: { "x-access-token": localStorage.getItem("accessToken") } }
      )
      .then((response) => {
        this.setState({
          p_id: response.data[0].p_id,
          editedname: response.data[0].product_name,
          editedratings: response.data[0].product_ratings,
          editedprice: response.data[0].product_price,
          editeddiscount: response.data[0].discount_on_product,
          product_image_url: response.data[0].product_image_url,
          isLoading: false,
        });

        // this.state.colorDefault.push({
        //   name: "colors",
        //   value: response.data[0].pColor,
        //   label: response.data[0].pColor,
        // });

        // this.state.companyDefault.push({
        //   name: "company",
        //   value: response.data[0].pCompanyName,
        //   label: response.data[0].pCompanyName,
        // });

        // this.state.productDefault.push({
        //   name: "product",
        //   value: response.data[0].productName,
        //   label: response.data[0].productName,
        // });

        // this.state.categotyDefault.push({
        //   name: "category",
        //   value: response.data[0].pCategory,
        //   label: response.data[0].pCategory,
        // });

        // this.state.sizeDefault.push({
        //   name: "size",
        //   value: response.data[0].pSize,
        //   label: response.data[0].pSize,
        // });

        this.setState({ show: !this.state.show });
      })
      .catch(function (error) {
        // this.setState({ isLoading: false });
        swal({
          title: "Oh snap! You got an error!!",
          text: TECHNICAL_ERROR_MESSAGE,
          icon: "error",
          button: "Close",
        });
        alert(error);
      });
  };

  handleModalProImage = () => {
    this.setState({ proImgShow: !this.state.proImgShow });
  };

  getProductImagesFromNewArrivals = (proID) => {
    let formData = new FormData();

    console.log("getting product image for new arrivals" , proID)
    // formData.append("shopID", sessionStorage.getItem("shopID"));

    let sample_id = proID;
    // formData.append("proID", proID);
    formData.append("proID", sample_id);
    this.setState({ isLoading: true });
    axios
      .post(
        `${process.env.REACT_APP_API_URL_BACKEND_CALL}/getProductImageForNewArrival`,
        formData,
        { headers: { "x-access-token": localStorage.getItem("accessToken") } }
      )
      .then((response) => {
        this.setState({ productImage: response.data, isLoading: false });

        this.setState({ proImgShow: !this.state.proImgShow });
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        swal({
          title: "Oh snap! You got an error!!",
          text: TECHNICAL_ERROR_MESSAGE,
          icon: "error",
          button: "Close",
        });
        alert(error);
      });
  };
  getProductImages = (proID) => {
    let formData = new FormData();

    // formData.append("shopID", sessionStorage.getItem("shopID"));

    let sample_id = proID;
    // formData.append("proID", proID);
    formData.append("proID", sample_id);
    this.setState({ isLoading: true });
    axios
      .post(
        `${process.env.REACT_APP_API_URL_BACKEND_CALL}/getProductImageForSlider`,
        formData,
        { headers: { "x-access-token": localStorage.getItem("accessToken") } }
      )
      .then((response) => {
        this.setState({ productImage: response.data, isLoading: false });

        this.setState({ proImgShow: !this.state.proImgShow });
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        swal({
          title: "Oh snap! You got an error!!",
          text: TECHNICAL_ERROR_MESSAGE,
          icon: "error",
          button: "Close",
        });
        alert(error);
      });
  };

  handleModal = () => {
    this.setState({ show: !this.state.show });
  };
  handleModalNewArrivals = () => {
    this.setState({ showarrivalsmodal: !this.state.showarrivalsmodal });
  };

  render() {
    if (this.state.checkLoginStatus === false) {
      return <Redirect to="/userLogin" />;
    }

    const { selectedOption } = this.state;

    let imageAllData = this.state.productImage.map((user, index) => {
      return (
        <img
          src={process.env.PUBLIC_URL + `${user.product_image_url}`}
          className="productImage"
        />
      );
    });

    return (
      <div>
        {this.state.isLoading && <WSppiner isLoading={this.state.isLoading} />}
        <div className="headerBarTop">
          <Header />
        </div>

        <div className="mobileViewSidebar">
          <SideBarMobile />
        </div>

        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-2 col-md-12 col-sm-12 sideBARColor">
              <SideBar />
            </div>

            <div className="col-xl-10 col-md-12 col-sm-12  mainBar">
              <button
                onClick={() => {
                  this.setShowNewArrivals();
                }}
                className="toggleShowFormButton"
              >
               New arrivals products list
              </button>
              <button
                onClick={() => {
                  this.setShowBestSelling();
                }}
                className="toggleShowFormButton"
              >
                Best selling produts list
              </button>
              <h5 style={{ marginTop: "10px" }}>
                {this.state.showbestselling == true &&
                this.state.shownewarrivals == false
                  ? "Best selling "
                  : null}
              </h5>
              <h5 style={{ marginTop: "10px" }}>
                {this.state.showbestselling == false &&
                this.state.shownewarrivals == true
                  ? "New arrivals "
                  : null}
              </h5>

              <div className="tableDiv inMobileViewButton ">
                {this.state.showbestselling ? (
                  this.state.data_from_slider.length > 0 ? (
                    <table className="table">
                      <thead className="tableHead">
                        <tr>
                          <th className="th-sm">S.no</th>
                          <th className="th-sm">productname</th>
                          <th className="th-sm">price</th>
                          <th className="th-sm">discount</th>
                          <th className="th-sm">ratings</th>

                          <th className="th-sm">Image</th>
                          <th className="th-sm">Edit</th>
                          <th className="th-sm">Delete</th>
                        </tr>
                      </thead>

                      <tbody className="">
                        {this.state.data_from_slider.map((user, index) => (
                          <tr key={user.proID}>
                            {/* <td>{user.p_id }</td> */}
                            <td>{index + 1}</td>
                            <td>{user.product_name}</td>
                            <td>{user.product_price}</td>
                            <td>{user.discount_on_product}</td>
                            <td>{user.product_ratings}</td>
                            {/* <td>{user.pSize}</td>
                        
                          <td><textarea className="descriptionBox">{user.pDescription}</textarea></td> */}
                            <td>
                              <img
                                src={shirt}
                                className="shirtImage"
                                onClick={() => this.getProductImages(user.p_id)}
                              />
                            </td>
                            <td>
                              <img
                                src={edit}
                                className="buttonLogo"
                                title="Edit"
                                onClick={() =>
                                  this.handleModalGetData(user.p_id)
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={deleteImage}
                                className="buttonLogo"
                                title="Delete"
                                onClick={() => this.deleteData(user.p_id)}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <h5>Data Not Found</h5>
                  )
                ) : null}
                {this.state.shownewarrivals ? (
                  this.state.data_from_newarrivals.length > 0 ? (
                    <table className="table">
                      <thead className="tableHead">
                        <tr>
                          <th className="th-sm">S.no</th>
                          <th className="th-sm">productname</th>
                          <th className="th-sm">price</th>
                          <th className="th-sm">discount</th>
                          <th className="th-sm">ratings</th>

                          <th className="th-sm">Image</th>
                          <th className="th-sm">Edit</th>
                          <th className="th-sm">Delete</th>
                        </tr>
                      </thead>

                      <tbody className="">
                        {this.state.data_from_newarrivals.map((user, index) => (
                          <tr key={user.proID}>
                            {/* <td>{user.p_id }</td> */}
                            <td>{index + 1}</td>
                            <td>{user.product_name}</td>
                            <td>{user.product_price}</td>
                            <td>{user.discount_on_product}</td>
                            <td>{user.product_ratings}</td>
                            {/* <td>{user.pSize}</td>
                        
                          <td><textarea className="descriptionBox">{user.pDescription}</textarea></td> */}
                            <td>
                              <img
                                src={shirt}
                                className="shirtImage"
                                onClick={() => this.getProductImagesFromNewArrivals(user.p_id)}
                              />
                            </td>
                            <td>
                              <img
                                src={edit}
                                className="buttonLogo"
                                title="Edit"
                                onClick={() =>
                                  this.handleModalGetDataFromNewArrivals(user.p_id)
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={deleteImage}
                                className="buttonLogo"
                                title="Delete"
                                onClick={() => this.deleteDataFromNewArrivals(user.p_id)}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <h5>Data Not Found</h5>
                  )
                ) : null}
              </div>

              {/* <ReactPaginate
                previousLabel={"prev"}
                nextLabel={"next"}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={this.state.pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={this.handlePageClick}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
              /> */}
            </div>
          </div>
        </div>

        {/*  Edit Start Model */}

        <div id="exampleModal">
          <Modal
            size="md"
            show={this.state.showarrivalsmodal}
            onHide={() => this.handleModalNewArrivals()}
          >
            <Modal.Header closeButton>
              <Modal.Title>Edit Product Details - new arrivals</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="container">
                <div className="row">
                  <div className="col-md-6">
                    <label>Company Name </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="name"
                      name="editednameNewArrivals"
                      value={this.state.editednameNewArrivals}
                      onChange={this.handleChangeAll}
                    />
                  </div>

                  <div className="marginClass col-md-6">
                    <label>Price</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Price"
                      name="editedpriceNewArrivals"
                      value={this.state.editedpriceNewArrivals}
                      onChange={this.handleChangeAll}
                    />
                  </div>
                </div>

                <div className="row updateModel">
                  <div className="col-md-6">
                    <div className="marginClass">
                      <label>Discount</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Quantity"
                        name="editeddiscountNewArrivals"
                        value={this.state.editeddiscountNewArrivals}
                        onChange={this.handleChangeAll}
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="marginClass">
                      <label>Rating</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="ratings"
                        name="editedratingsNewArrivals"
                        value={this.state.editedratingsNewArrivals}
                        onChange={this.handleChangeAll}
                      />
                    </div>
                  </div>
                </div>
                <div className="row updateModel">
                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                    {/* {this.state.imageData[0] == undefined ?<img src={this.state.product_image_url}  alt="image shoudl be here" className="imageInEdit"></img>: <img src={this.state.imageData[0]}></img>} */}

                    {/* <img
                      src={this.state.product_image_url}
                      alt="image shoudl be here"
                      className="imageInEdit"
                    ></img> */}

                    {this.state.imageedited
                      ? "image edited "
                      : "image not edited"}
                    <br />
                    <br />
                    <input
                      type="file"
                      id="file"
                      width="2000px"
                      // disabled = {true}
                      onChange={this.uploadMultipleFiles}
                      className="SubmitButtonAddToProductSlider"
                    />
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="primary" onClick={() => this.submitModalNewArrivals()}>
                Save Changes
              </Button>
              <Button onClick={() => this.handleModalNewArrivals()} variant="secondary">
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
        <div id="exampleModal">
          <Modal
            size="md"
            show={this.state.show}
            onHide={() => this.handleModal()}
          >
            <Modal.Header closeButton>
              <Modal.Title>Edit Product Details - Best SElling</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="container">
                <div className="row">
                  <div className="col-md-6">
                    <label>Company Name </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="name"
                      name="editedname"
                      value={this.state.editedname}
                      onChange={this.handleChangeAll}
                    />
                  </div>

                  <div className="marginClass col-md-6">
                    <label>Price</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Price"
                      name="editedprice"
                      value={this.state.editedprice}
                      onChange={this.handleChangeAll}
                    />
                  </div>
                </div>

                <div className="row updateModel">
                  <div className="col-md-6">
                    <div className="marginClass">
                      <label>Discount</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Quantity"
                        name="editeddiscount"
                        value={this.state.editeddiscount}
                        onChange={this.handleChangeAll}
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="marginClass">
                      <label>Rating</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="ratings"
                        name="editedratings"
                        value={this.state.editedratings}
                        onChange={this.handleChangeAll}
                      />
                    </div>
                  </div>
                </div>
                <div className="row updateModel">
                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                    {/* {this.state.imageData[0] == undefined ?<img src={this.state.product_image_url}  alt="image shoudl be here" className="imageInEdit"></img>: <img src={this.state.imageData[0]}></img>} */}

                    {/* <img
                      src={this.state.product_image_url}
                      alt="image shoudl be here"
                      className="imageInEdit"
                    ></img> */}

                    {this.state.imageedited
                      ? "image edited "
                      : "image not edited"}
                    <br />
                    <br />
                    <input
                      type="file"
                      id="file"
                      width="2000px"
                      // disabled = {true}
                      onChange={this.uploadMultipleFiles}
                      className="SubmitButtonAddToProductSlider"
                    />
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="primary" onClick={() => this.submitModal()}>
                Save Changes
              </Button>
              <Button onClick={() => this.handleModal()} variant="secondary">
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
        {/*  End Edit Model */}

        <div id="">
          <Modal
            size="lg"
            show={this.state.proImgShow}
            onHide={() => this.handleModalProImage()}
          >
            <Modal.Header closeButton>
              <Modal.Title>Product Images </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="container">
                <div className="row">
                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                    {imageAllData}
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                onClick={() => this.handleModalProImage()}
                className="modelSaveButton"
              >
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </div>

        <ToastContainer />
      </div>
    );
  }
}

export default ShowSliderData;
