import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import AddIcon from '../../assets/add.png'
import axios from 'axios';
import Header from './Header';
import SideBar from './SideBar';
import Select from 'react-select';
import '../ClientFolder/css/Home.css';
import { Redirect, Link } from 'react-router-dom';
import { Button, Card, Modal } from 'react-bootstrap';
import edit from '../ClientFolder/Images/pencil.svg';
import deleteImage from '../ClientFolder/Images/delete.svg';
import filters from './Filters';
import ReactPaginate from 'react-paginate';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SideBarMobile from './sidebar/Sidebar';
import ReactTable from '../../common/ReactTable';
import moreDetailsImage from '../../assets/moreDatails.png';
import editIcon from '../../assets/edit.png';
import viewIcon from '../../assets/eye.png';
import { connect } from 'react-redux';
import PurchaseModal from '../../common/PurchaseModal/PurchaseModal';
import { savePurchaseDetails } from '../../Services/Actions/CustomerDetailsAction';
import Moment from 'react-moment';
import currencyFormatter from 'currency-formatter';
//import 'moment-timezone';
import moment from 'moment';
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import companyLogo from '../../assets/Begave.jpg';
import ConfirmationModal from '../../common/ConfirmationModal';
import { TECHNICAL_ERROR_MESSAGE } from '../Customer/Constant/Constant';
import swal from 'sweetalert';

class EmployessTransactionDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      checkLoginStatus: true,
      show: false,
      offset: 0,
      purchaseModal:false,
      tableData: [],
      perPage: 10,
      currentPage: 0,
      data: [],
      f: { pCompanyName: [], productName: [], pCategory: [], pColor: [], pSize: [] },

      tableHeader: [
        "Sl. No",
        "Amount",
        "GST",
        "Received",
        "Dues",
        "Purchase Date",
        "Status",
        "View Details",
        "More Details"
      ],
      tableShowMore:[
        "S.No",
        "Item",
        "Category",
        "Type",
        "Size",
        "Quantity",
        "Rate",
        "Amount",
      ],
      searchData: "",
      redirectTransactionFlow: false,
      customerId: 0,
      itemList:[],
      totalAmount:0,
      gstAmount:0,
      gstButtonText:'Apply 5% GST',
      gstButtonStatus:true,
      amountReceived:0,
      dueAmount:0,
      showMoreDetailStatus:false,
      showMoreDetailsItemList:[],
      purchaseDetailsInfo:[],
      totalDetails:null,
      amountError:"",
      showPaymentModal:false,
      paymentList:[],
      purchaseInfoObj:null,
      billStatusModel:false,
      billMessage:"",
      secondaryButtonClickForBillStatus:false
    }

    let token = JSON.parse(sessionStorage.getItem("employessLoginDetails"));

    if (token == null || token == undefined || token == "") {

      this.state.checkLoginStatus = false;
    }



  }
  componentDidMount = () => {
    const {customerDetailsData} = this.props;
    const payload =  {
      customerId:customerDetailsData?.customerDetails?.customerId
    }
    axios.post(`${process.env.REACT_APP_API_URL_BACKEND_ENDPOINT}/customerTransactionDetail`,payload,
    {headers : {'x-access-token' : localStorage.getItem("accessToken")}})
      .then((response) => {

        if(response.data?.length > 0)
        {
          let tableDataObj = []
          for(let i=0;i<response.data?.length;i++)
          {
              let tempObj = response.data[i];
              tempObj['editFlag'] = false;
              tableDataObj.push(tempObj)
          }
          // let tableDataObj = response.data;
          // tableDataObj.map((element) => {
            
          //   return {
          //    // ...element,
          //     'editFlow':false
          //   }
            
          // });

          console.log('tableDataObj',tableDataObj)
          this.setState({ tableData: response.data,totalDetails: this.calculateTotals(response.data)});
        }
      }).catch(function (error) {

        alert(error);
      })

  }


  handleChange1 = (selectedOptions) => {

    let a = { ...this.state.f };
    a.pCompanyName = selectedOptions;

    let b = [];
    this.setState({ f: a, filterArray: [...b], temp1: [...b], temp: [...b], category: [...b] }, this.method);

  }



  handleChangeAll = (event) => {

    this.setState({ [event.target.name]: event.target.value });

  }

  calculateTotals = (purchaseItemList)=>
  {
    let totalAmount=0;
    let totalRecived=0;
    let totalDues = 0;
    let totalGST = 0;
    for(let i=0;i<purchaseItemList.length;i++)
    {
      totalAmount = totalAmount+ parseInt(purchaseItemList[i]?.Amount);
      totalRecived = totalRecived+ parseInt(purchaseItemList[i]?.receivedAmount);
      totalGST = totalGST+ parseInt(purchaseItemList[i]?.gstAmount);
    }
    totalDues = (totalAmount +totalGST)  - totalRecived;
    totalAmount  = totalAmount + totalGST;
    return {
      totalAmount,
      totalRecived,
      totalDues,
      totalGST
    };
  }

  // For pagination 
  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState({
      currentPage: selectedPage,
      offset: offset
    }, () => {
      this.loadMoreData()
    });
  };

  handlePurchaseModal=()=>
  {
    this.setState({purchaseModal:!this.state.purchaseModal,itemList:[],gstAmount:0,amountReceived:0})
  }

  

  handleDropdownChange=(selectedOption,index,inputType)=>
  {
    let itemObj = this.state.itemList;
    itemObj[index][`${inputType}`] = selectedOption.value;
    this.setState({itemList:itemObj})
  }
 
  handleAddItem=()=>
  {
    const newitemObj = {
      item:'',
      category:'',
      quantity:'',
      size:"",
      rate:'',
      amount:'',
      type:'',
    }
    let itemList = this.state.itemList
    itemList.push(newitemObj)
    this.setState({itemList:itemList})
  }

  handleChange=(event,index,inputType)=>
  {
    let itemObj = this.state.itemList;
    itemObj[index][`${inputType}`] = event.target.value;
    if(this.state.itemList[index].quantity!="" && this.state.itemList[index].rate!="")
    {
      itemObj[index]['amount'] = (parseInt(this.state.itemList[index].quantity)*(this.state.itemList[index].rate))
    }
    this.setState({itemList:itemObj,totalAmount:this.calculateTotalAmount(itemObj),dueAmount: this.calculateTotalDues(this.calculateTotalAmount(itemObj))})
  }

  calculateTotalAmount=(purchaseItemList)=>
  {
    let totalAmount=0;
    for(let i=0;i<purchaseItemList.length;i++)
    {
      totalAmount = totalAmount+ parseInt(purchaseItemList[i].amount);
    }
    return totalAmount;
  }

  calculateTotalDues = (totalAmount) =>
  {
    let totalDues = (parseInt(totalAmount) + parseInt(this.state.gstAmount) ) - parseInt(this.state.amountReceived);
    return totalDues;
  }

  handleRemove=(removedIndex)=>
  {
    let tempArr=[]
   for(let i=0;i<this.state.itemList.length;i++)
   {
    if(removedIndex!=i)
    {
      tempArr.push(this.state.itemList[i])
    }
   }
    this.setState({itemList:tempArr})
  }

  handlePurchaseList=()=>
  {
      const {customerDetailsData} = this.props;

      console.log('data',JSON.parse(sessionStorage.getItem('employessLoginDetails'))?.empId)
      if(this.state?.itemList?.length > 0 && this.state.totalAmount > 0)
      {
            const payload = {
              customerId:customerDetailsData?.customerDetails?.customerId,
              purchaseItemList:this.state.itemList,
              totalAmount:this.state.totalAmount,
              amountReceived:this.state.amountReceived,
              gstAmount:this.state.gstAmount,
              sellerID:  `employee_${JSON.parse(sessionStorage.getItem('employessLoginDetails'))?.empName}_${JSON.parse(sessionStorage.getItem('employessLoginDetails'))?.empId}`
          }

          axios.post(`${process.env.REACT_APP_API_URL_BACKEND_ENDPOINT}/addPurchaseItem`,payload,
          {headers : {'x-access-token' : localStorage.getItem("accessToken")}})
          .then((response)=>
          {
              this.getTransactionDetails();
              if(response.data.message=="success")
              {
                this.setState({purchaseModal:false,itemList:[],gstAmount:0,amountReceived:0})
                  swal({
                    title: "Confirmed",
                    text: 'Purchased successfully',
                    icon: "success",
                    button: "Close",
                });  
              }
              else
              {
                this.setState({purchaseModal:false,itemList:[],gstAmount:0,amountReceived:0})
                swal({
                  title: "Confirmed",
                  text: 'Purchase not done',
                  icon: "error",
                  button: "Close",
              });  
              }
          }).catch((error)=>
          {
              swal({
                title: "Confirmed",
                text: TECHNICAL_ERROR_MESSAGE,
                icon: "error",
                button: "Close",
            }); 
              this.setState({purchaseModal:false,itemList:[],gstAmount:0,amountReceived:0})
          })
      }
      else
      {

      }

    }

    handleApplyGst=()=>
    {
      let gstAmount=0;
      gstAmount=((5/100) * parseInt(this.state.totalAmount))
      this.setState({totalAmount:(this.state.totalAmount),
        gstAmount:gstAmount,gstButtonStatus:false,gstButtonText:'Remove GST',
      },()=>{

        this.setState({
          dueAmount:this.calculateTotalDues(this.state.totalAmount)
        })
      })
    }

    handleRemoveGst=()=>
    {
        this.setState({totalAmount:(this.state.totalAmount),
        gstAmount:0,gstButtonStatus:true,gstButtonText:'Apply 5% GST',
        },()=>{
          this.setState({
            dueAmount:this.calculateTotalDues(this.state.totalAmount)
          })
          //dueAmount:this.calculateTotalDues(this.state.totalAmount)
        })
    }

    handleChangeAmt=(event)=>
    {
        if(event?.target?.value == "" || event?.target?.value == 0)
        {
          this.setState({amountReceived:parseInt(event.target.value),dueAmount:((parseInt(this.state.totalAmount) + parseInt(this.state.gstAmount) ) -parseInt(event.target.value))})
        }
        else
        {
          this.setState({amountReceived:parseInt(event.target.value),dueAmount:((parseInt(this.state.totalAmount) + parseInt(this.state.gstAmount) ) -parseInt(event.target.value))})
        }
    }


    showMoreDetail=(purchaseDetails,callType="default")=>
    {
      const {customerDetailsData,savePurchaseDetails} = this.props;
      savePurchaseDetails(purchaseDetails)
      const payload = {
        customerId:customerDetailsData?.customerDetails?.customerId,
        purchaseId:purchaseDetails.purchaseId
      }
      
      this.showPaymentDetails(purchaseDetails,'Normal')
      axios.post(`${process.env.REACT_APP_API_URL_BACKEND_ENDPOINT}/showMoreDetail`,payload,
      {headers : {'x-access-token' : localStorage.getItem("accessToken")}}).
      then((response)=>
      {
        if(response.data.message == "success")
        {
          if(callType == "default")
          {
            this.setState({showMoreDetailStatus:true,showMoreDetailsItemList:response.data.itemList,
              purchaseDetailsInfo :this.calculateBillDetails(purchaseDetails) })
          }
          else
          {
            this.setState({showMoreDetailsItemList:response.data.itemList,
              purchaseDetailsInfo :purchaseDetails})
          }
          
        }
      }).catch((error)=>
      {
        console.log("err",error)
      })
    }


    cancelTransactionDetails = (index)=>
    {
        let tempObj = this.state.tableData;
        tempObj[index].editFlag = false;
        this.setState({
          tableData:tempObj,
          amountError:""
        })
    }


    editTransactionDetails = (index)=>
    {
        let filterArray = this.state.tableData.filter((item)=> item.editFlag == true);

        if(filterArray?.length > 0)
        {

        }
        else
        {
          let tempObj = this.state.tableData;
          tempObj[index].editFlag = true;
          this.setState({
            tableData:tempObj
          })
        }
    }

    amountHandleChange = (e,amount,receivedAmount)=>
    {
      if(parseInt(amount) < (parseInt(e.target.value) + parseInt(receivedAmount)))
      {
          this.setState({
            amountError:"Receive amount less then or equal to amount"
          })
      }
      else
      {
        this.setState({
          amountError:""
        })
      }
     
    }

    saveTransactionDetails = (index,details)=>
    {
        const inputValue = document.getElementById('editAmout').value;

        const newAmount = (parseInt(inputValue) + parseInt(details?.receivedAmount))
        const payload = {
            receivedAmount: newAmount,
            purchaseId:details?.purchaseId,
            addAmount:parseInt(inputValue)
        }

        if(parseInt(details?.Amount) >= (parseInt(inputValue) + parseInt(details?.receivedAmount)))
        {
            let tempObj = this.state.tableData;
            tempObj[index].editFlag = false;
            axios.post(`${process.env.REACT_APP_API_URL_BACKEND_ENDPOINT}/editTransactionDetails`,payload,
            {headers : {'x-access-token' : localStorage.getItem("accessToken")}})
            .then((resonse)=>{
                console.log(resonse.data)
                this.getTransactionDetails()
                this.setState({
                  tableData:tempObj
                }) 
            })
            .catch((error)=>{
    
            })
        }
        else
        {

        }
    }

    handleBack = ()=>
    {
      this.setState({
        showMoreDetailStatus: false
      })
    }

  showPaymentDetails = (purchaseObj,callType = "default")=>
  {
      const payload = {
        purchaseId:purchaseObj?.purchaseId
      }

      if(callType=="default")
      {
        this.showMoreDetail(purchaseObj,'payment')
      }
      axios.post(`${process.env.REACT_APP_API_URL_BACKEND_ENDPOINT}/showPaymentDetails`,payload,
      {headers : {'x-access-token' : localStorage.getItem("accessToken")}})
      .then((response)=>{
          console.log(response.data)

          if(response.data?.itemList?.length > 0)
          {
              if(callType == "default")
              {
                this.setState({paymentList:response.data?.itemList,showPaymentModal:true,purchaseInfoObj:purchaseObj});
              }
              else
              {
                this.setState({paymentList:response.data?.itemList,purchaseInfoObj:purchaseObj});
              }
              
          }
      })
      .catch((error)=>
      {
          
      })
  }

  handleCloseModal = ()=>
  {
      this.setState({showPaymentModal:false})
  }

  generateInvoice = (data)=>
  {
    const {purchaseDetailsInfo,totalDetails} = this.state;
    const {customerDetailsData,savePurchaseDetails} = this.props;
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape
    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    let cgst_percent = 2.5; 
    let sgst_percent = 2.5; 
    const d = new Date();
    
    const todayDate = d.getDate()+"/"+(d.getMonth() +1 )+"/"+d.getFullYear();

    doc.setFontSize(15);
    doc.addImage(companyLogo,40,8,150,35);
    doc.setFontSize(30);
    doc.text(250, 35, "Invoice");
    doc.setFontSize(14);
    doc.text(420, 35, `Date: ${todayDate}`);
    doc.line(20, 45, 580, 45);

    // Company Details
    doc.setFontSize(14);
    doc.text("From:", 40, 65);
    doc.setFontSize(12);
    doc.text(`BeGave Textile`, 40, 78);
    doc.text(`Mobile: 8235859732`, 40, 91);
    doc.text("Address: Road no:23,Rajeev Nagar ", 40, 104);
    doc.text(`Patna,Bihar`, 40, 117);
    doc.text(`India (800024)`, 40, 130);

     
    // Customer details 
    doc.setFontSize(14);
    doc.text("Bill To:", 250, 65);
    doc.setFontSize(12);
    doc.text(`Shop name: ${customerDetailsData?.customerDetails?.shopName}`, 250, 78);
    doc.text(`Customer name: ${customerDetailsData?.customerDetails?.customerName}`, 250, 91);
    doc.text(`Customer mobile: ${customerDetailsData?.customerDetails?.customerMobile}`, 250, 104);
    doc.text(`Customer address: ${customerDetailsData?.customerDetails?.locality}, ${customerDetailsData?.customerDetails?.landmark}`, 250, 117);
    doc.text(`${customerDetailsData?.customerDetails?.city}, ${customerDetailsData?.customerDetails?.state}`, 250, 130);
    doc.text(`${customerDetailsData?.customerDetails?.country}, ${customerDetailsData?.customerDetails?.pincode}`, 250, 143);

    doc.setFontSize(14)
    doc.text("Purchases details",40,165)
    doc.line(20, 173, 580, 173);
    
    const headers = [["S.no", "Amount", "GST", "Received", "Dues","Bill Status", "Purchase Date	"]];

    let tableData = data.map((user, index) => (
        [index + 1, (parseInt(user.Amount) + parseInt(user.gstAmount)) , user.gstAmount, user.receivedAmount, ((parseInt(user.Amount) +  parseInt(user.gstAmount))- parseInt(user.receivedAmount)),user.billStatus,moment(user.transRegDate).format('LL')]
    ))

    let content = {
        startY: 185,
        theme: 'grid',
        head: headers,
        body: tableData
    };
    doc.autoTable(content);

    let finalY = doc.lastAutoTable.finalY;
    let pageCount = doc.internal.getNumberOfPages();

    if ((finalY + 230) < doc.getPageHeight()) {
        doc.setPage(pageCount)
        doc.setFontSize(12);
        doc.setLineWidth(1);
        doc.line(20, finalY + 20, 580, finalY + 20);
        doc.text(`No Of Bills: ${data?.length}`, 350, finalY + 40);
        doc.text(`Total Amount: ${totalDetails?.totalAmount - totalDetails?.totalGST
        }`, 350, finalY + 60);

        doc.setLineWidth(1);
        doc.text(`CGST - 2.5% : ${(parseInt(totalDetails?.totalGST)/2)}` , 350 , finalY + 80)
        doc.text(`SGST - 2.5% : ${(parseInt(totalDetails?.totalGST)/2)}` , 350 , finalY + 100)
        doc.text(`Grand Total: ${totalDetails?.totalAmount}`,350, finalY + 120);
        doc.text(`Received Amount: ${totalDetails?.totalRecived}`, 350, finalY + 140);
        doc.text(`Total Dues: ${totalDetails?.totalDues}`, 350, finalY + 160);
 
        doc.line(20, finalY + 170, 580, finalY + 170);
        doc.text("Authorized Signatory", 430, finalY + 185);
        doc.text("BeGave Textile", 445, finalY + 205);
        doc.addImage(companyLogo, 450, finalY + 220, 70, 20);
        doc.text("Thank You", 450, finalY + 250);

    }
    else {
        doc.addPage();
        doc.setFontSize(12);
        doc.setLineWidth(1);
        doc.line(20, finalY + 20, 580, finalY + 20);
        doc.text(`No Of Bills: ${data?.length}`, 350, finalY + 40);
        doc.text(`Total Amount: ${totalDetails?.totalAmount}`, 350, finalY + 60);

        doc.setLineWidth(1);
        doc.text(`CGST - 2.5% : ${(parseInt(totalDetails?.totalGST)/2)}` , 350 , finalY + 80)
        doc.text(`SGST - 2.5% : ${(parseInt(totalDetails?.totalGST)/2)}` , 350 , finalY + 100)
        doc.text(`Grand Total: ${totalDetails?.totalAmount}`,350, finalY + 120);
        doc.text(`Received Amount: ${totalDetails?.totalRecived}`, 350, finalY + 140);
        doc.text(`Total Dues: ${totalDetails?.totalDues}`, 350, finalY + 160);
 
        doc.line(20, finalY + 170, 580, finalY + 170);
        doc.text("Authorized Signatory", 430, finalY + 190);
        doc.addImage(companyLogo, 450, finalY + 200, 70, 20);
        doc.text("Thank You", 450, finalY + 230);

    }


    doc.save(`${customerDetailsData?.customerDetails?.shopName}_${customerDetailsData?.customerDetails?.customerName}.pdf`)

  }

  downloadPurchaseDetailsWithPayment = (data="")=>
  {
      const {purchaseDetailsInfo,totalDetails} = this.state;
      const {customerDetailsData,savePurchaseDetails} = this.props;
      const unit = "pt";
      const size = "A4"; // Use A1, A2, A3 or A4
      const orientation = "portrait"; // portrait or landscape
      const marginLeft = 40;
      const doc = new jsPDF(orientation, unit, size);

      let cgst_percent = 2.5; 
      let sgst_percent = 2.5; 
      const d = new Date();
      
      const todayDate = d.getDate()+"/"+(d.getMonth() +1 )+"/"+d.getFullYear();

      doc.setFontSize(15);
      doc.addImage(companyLogo,40,8,150,35);
      doc.setFontSize(30);
      doc.text(250, 35, "Invoice");
      doc.setFontSize(14);
      doc.text(420, 35, `Date: ${todayDate}`);
      doc.line(20, 45, 580, 45);

      // Company Details
      doc.setFontSize(14);
      doc.text("From:", 40, 65);
      doc.setFontSize(12);
      doc.text(`BeGave Textile`, 40, 78);
      doc.text(`Mobile: 8235859732`, 40, 91);
      doc.text("Address: Road no:23,Rajeev Nagar ", 40, 104);
      doc.text(`Patna,Bihar`, 40, 117);
      doc.text(`India (800024)`, 40, 130);

      
      // Customer details 
      doc.setFontSize(14);
      doc.text(`Bill To:`, 250, 65);
      doc.setFontSize(12);
      doc.text(`Shop name: ${customerDetailsData?.customerDetails?.shopName}`, 250, 78);
      doc.text(`Customer name: ${customerDetailsData?.customerDetails?.customerName}`, 250, 91);
      doc.text(`Customer mobile: ${customerDetailsData?.customerDetails?.customerMobile}`, 250, 104);
      doc.text(`Customer address: ${customerDetailsData?.customerDetails?.locality}, ${customerDetailsData?.customerDetails?.landmark}`, 250, 117);
      doc.text(`${customerDetailsData?.customerDetails?.city}, ${customerDetailsData?.customerDetails?.state}`, 250, 130);
      doc.text(`${customerDetailsData?.customerDetails?.country}, ${customerDetailsData?.customerDetails?.pincode}`, 250, 143);

      doc.setFontSize(14)
      doc.text(`Bill Date: ${moment(this.state.purchaseInfoObj?.transRegDate).format('LL')}`,40,165)
      doc.text(`Items:`,40,182)
      doc.line(20, 187, 580, 187);
      
      const headers = [["S.no", "Item", "Category","Size", "Type", "Quantity","Rate", "Amount"]];

      let tableData = this.state?.showMoreDetailsItemList?.map((user, index) => (
          [index + 1, user.Item, user.Category ,user.Size, user.Type, user.Quantity, user.Rate,user.Amount]
      ))

      let content = {
          startY: 200,
          theme: 'grid',
          head: headers,
          headStyles: { fillColor: [116, 167, 247],halign : 'left'},
          body: tableData
      };
      doc.autoTable(content);

      let finalY = doc.lastAutoTable.finalY;

      
      if((finalY + 300 ) < doc.getPageHeight())
      {
        doc.line(20, (finalY+30), 580, (finalY+30));
        doc.text('Payment details',40,(finalY + 60))
        const paymentHeaders = [["S.no", "Received amount", "Payment date"]];

        let paymentTableData = this.state?.paymentList?.map((user, index) => (
            [index + 1, user.receivedAmount, moment(user.paymentDate).format('LL')]
        ))
  
        let paymentContent = {
            startY: (finalY + 70),
            theme: 'grid',
            headStyles: { fillColor: [116, 167, 247],halign : 'left'},
            head: paymentHeaders,
            body: paymentTableData
        };
        doc.autoTable(paymentContent);

        let paymentFinalY = doc.lastAutoTable.finalY;

        if((paymentFinalY + 300 ) < doc.getPageHeight())
        {
            const {purchaseInfoObj} = this.state;
            let pageCount = doc.internal.getNumberOfPages();
            doc.setPage(pageCount)
            doc.setFontSize(12);
            doc.setLineWidth(1);
            doc.line(20, paymentFinalY + 20, 580, paymentFinalY + 20);
            doc.text(`Total Amount: ${purchaseInfoObj?.Amount
            }`, 350, paymentFinalY + 40);

            doc.setLineWidth(1);
            doc.text(`CGST - 2.5% : ${(parseInt(purchaseInfoObj?.gstAmount)/2)}` , 350 , paymentFinalY + 60)
            doc.text(`SGST - 2.5% : ${(parseInt(purchaseInfoObj?.gstAmount)/2)}` , 350 , paymentFinalY + 80)
            doc.text(`Grand Total: ${parseInt(purchaseInfoObj?.Amount + purchaseInfoObj?.gstAmount)}`,350, paymentFinalY + 100);
            doc.text(`Received Amount: ${purchaseInfoObj?.receivedAmount}`,350, paymentFinalY + 120)
            doc.text(`Total Dues: ${(parseInt(purchaseInfoObj?.Amount) + parseInt(purchaseInfoObj?.gstAmount) ) - (parseInt(purchaseInfoObj?.receivedAmount))}`, 350, paymentFinalY + 140);
    
            doc.line(20, paymentFinalY + 170, 580, paymentFinalY + 170);
            doc.text("Authorized Signatory", 430, paymentFinalY + 185);
            doc.text("BeGave Textile",450,paymentFinalY + 205)
            doc.addImage(companyLogo, 450, paymentFinalY + 215, 70, 20);
            doc.text("Thank You", 450, paymentFinalY + 250);
          }
      }

      let pageCount = doc.internal.getNumberOfPages();

      if ((finalY + 230) < doc.getPageHeight()) {
          // doc.setPage(pageCount)
          // doc.setFontSize(14);
          // doc.setLineWidth(1);
          // doc.line(20, finalY + 20, 580, finalY + 20);
          // doc.text(`Total Amount: ${totalDetails?.totalAmount - totalDetails?.totalGST
          // }`, 350, finalY + 60);

          // doc.setLineWidth(1);
          // doc.text(`CGST - 2.5% : ${(parseInt(totalDetails?.totalGST)/2)}` , 350 , finalY + 80)
          // doc.text(`SGST - 2.5% : ${(parseInt(totalDetails?.totalGST)/2)}` , 350 , finalY + 100)
          // doc.text(`Grand Total: ${totalDetails?.totalAmount}`,350, finalY + 120);

          // doc.text(`Total Dues: ${totalDetails?.totalDues}`, 350, finalY + 140);
  
          // doc.line(20, finalY + 170, 580, finalY + 170);
          // doc.text("Authorized Signatory", 430, finalY + 180);
          // doc.addImage(companyLogo, 450, finalY + 200, 70, 20);
          // doc.text("Thank You", 450, finalY + 230);

      }
      else {
          // doc.addPage();
          // doc.setFontSize(10);
          // doc.setLineWidth(1);
          // doc.line(20, finalY + 20, 580, finalY + 20);
          // doc.text(`Total Amount: ${totalDetails?.totalAmount}`, 350, finalY + 60);

          // doc.setLineWidth(1);
          // doc.text(`CGST - 2.5% : ${(parseInt(totalDetails?.totalGST)/2)}` , 350 , finalY + 80)
          // doc.text(`SGST - 2.5% : ${(parseInt(totalDetails?.totalGST)/2)}` , 350 , finalY + 100)
          // doc.text(`Grand Total: ${totalDetails?.totalAmount}`,350, finalY + 120);

          // doc.text(`Total Dues: ${totalDetails?.totalDues}`, 350, finalY + 140);
  
          // doc.line(20, finalY + 170, 580, finalY + 170);
          // doc.text("Authorized Signatory", 430, finalY + 185);
          // doc.addImage(companyLogo, 450, finalY + 200, 70, 20);
          // doc.text("Thank You", 450, finalY + 230);

      }


      doc.save(`${customerDetailsData?.customerDetails?.shopName}_${customerDetailsData?.customerDetails?.customerName}.pdf`)

  } 

  handleBillStatus = (details)=>
  {
      const purchaseDetails = this.calculateBillDetails(details);
      if(purchaseDetails?.totalAmount > purchaseDetails?.totalRecived)
      {
        this.setState({billStatusModel:!this.state.billStatusModel,
          purchaseInfoObj:details,
          billMessage:`You can't close this bill because bill amount ${purchaseDetails?.totalAmount } greater then received amount ${purchaseDetails?.totalRecived}.`,
          secondaryButtonClickForBillStatus:false})
      }
      else
      {
        this.setState({billStatusModel:!this.state.billStatusModel,purchaseInfoObj:details,
          billMessage:"If you want to close this bill, Then press yes button.",secondaryButtonClickForBillStatus:true})
      }
      
  }


  calculateBillDetails = (details)=>
  {
    //console.log('Hello World',details)
    let totalAmount=(parseInt(details?.Amount) + parseInt(details?.gstAmount))
    let totalRecived=parseInt(details?.receivedAmount);
    let totalDues = ((parseInt(details?.Amount) + parseInt(details?.gstAmount)) - parseInt(details?.receivedAmount));
    let totalGST = parseInt(details?.gstAmount)

    console.log('totalDues',totalDues)
    return {
      totalAmount,
      totalRecived,
      totalDues,
      totalGST
    };
  }

  billCloseRequest = ()=>
  {
      const {customerDetailsData,savePurchaseDetails} = this.props;
      const payload = {
        purchaseId:this.state.purchaseInfoObj?.purchaseId,
        customerID:customerDetailsData?.customerDetails?.customerId
      }
      axios.post(`${process.env.REACT_APP_API_URL_BACKEND_ENDPOINT}/billStatus`,payload,
      {headers : {'x-access-token' : localStorage.getItem("accessToken")}})
      .then((response)=>{
          console.log(response.data)

          if(response?.data?.message == "updateSuccess")
          {
              this.getTransactionDetails();
              this.setState({billStatusModel:false})
          }
          else
          {

          }
          
      })
      .catch((error)=>
      {
          
      })
  }

  getTransactionDetails = ()=>
  {
    const {customerDetailsData} = this.props;
    const payload =  {
      customerId:customerDetailsData?.customerDetails?.customerId
    }
    axios.post(`${process.env.REACT_APP_API_URL_BACKEND_ENDPOINT}/customerTransactionDetail`,payload,
    {headers : {'x-access-token' : localStorage.getItem("accessToken")}})
      .then((response) => {

        if(response.data?.length > 0)
        {
          let tableDataObj = []
          for(let i=0;i<response.data?.length;i++)
          {
              let tempObj = response.data[i];
              tempObj['editFlag'] = false;
              tableDataObj.push(tempObj)
          }
          // let tableDataObj = response.data;
          // tableDataObj.map((element) => {
            
          //   return {
          //    // ...element,
          //     'editFlow':false
          //   }
            
          // });

          this.setState({ tableData: response.data,totalDetails: this.calculateTotals(response.data)});
        }
      }).catch(function (error) {

        alert(error);
      })
  }
  
  render() {

    const {purchaseDetailsInfo,totalDetails,billStatusModel} = this.state;
    if (this.state.checkLoginStatus === false) {
      return <Redirect to="/employeeLogin" />
    }

    if (this.state.redirectTransactionFlow == true) {
      return <Redirect to={`/TransactionDetails/${this.state.customerId}`} />
    }

    let tableBodyData = []
    if (this.state.tableData.length > 0) {
      tableBodyData = this.state.tableData?.filter((val) => {
        if (this.state.searchData == "") {
          return val
        } else if (val.customerName.toLowerCase().includes(this.state.searchData.toLowerCase())) {
          return val
        } else if (val.customerMobile.toLowerCase().includes(this.state.searchData.toLowerCase())) {
          return val
        } else if (val.shopName.toLowerCase().includes(this.state.searchData.toLowerCase())) {
          return val
        }


      }).map((user, index) => {

        return <tr key={index}>
          <td>{index + 1}</td>
          <td>{(parseInt(user.Amount) + parseInt(user.gstAmount))}</td>
          <td>{user.gstAmount}</td>
          <td>{(user.editFlag == true) ? <>
            {user.receivedAmount}
            &nbsp;<input type='number' onChange={(e)=>{this.amountHandleChange(e,user?.Amount,user?.receivedAmount)}} id='editAmout' placeholder='add amount' />
            {(this.state.amountError !="" ) && <p style={{color:"red",fontSize:"80%"}}>{this.state.amountError}</p>}
          </> : <>{user.receivedAmount}</>}</td>
          <td>{((parseInt(user.Amount) +  parseInt(user.gstAmount))- parseInt(user.receivedAmount))}</td>
         
          <td>{moment(user.transRegDate).format('LL')}</td>
          <td>{(user.billStatus == "Open") ? <p style={{color:"red",fontWeight:"bold"}}>{user.billStatus}</p> : <p style={{color:"green",fontWeight:"bold"}}>{user.billStatus}</p>}</td>
          <td><img src={viewIcon} style={{height:"20px",width:"20px",cursor:"pointer"}} onClick={()=>{this.showPaymentDetails(user)}} /></td>
          {/* <td>
          <img src={editIcon} onClick={()=>{this.editTransactionDetails(index)}} style={{height:"20px",width:"20px",cursor:"pointer"}} />
          {(user.editFlag == true && this.state.amountError =="") && <button className='btn btn-primary' onClick={()=>{this.saveTransactionDetails(index,user)}}>Save</button> }
          {(user.editFlag == true) && <> &nbsp;<button className='btn btn-primary' onClick={()=>{this.cancelTransactionDetails(index)}}>Cancel</button></> }
          </td> */}
          <td><img src={moreDetailsImage} onClick={()=>{this.showMoreDetail(user)}} style={{height:"20px",width:"20px",cursor:"pointer"}} /></td>
        </tr>
      })
    }
//For show more details
let tableShowMoreData = []
if (this.state.showMoreDetailsItemList?.length > 0) {
  tableShowMoreData=this.state.showMoreDetailsItemList.map((user, index) => {
    return <tr key={index}>
      <td>{index + 1}</td>
      <td>{user.Item}</td>
      <td>{user.Category}</td>
      <td>{user.Type}</td>
      <td>{user.Quantity}</td>
      <td>{user.Size}</td>
      <td>{user.Rate}</td>
      <td>{user.Amount}</td>
    </tr>
  })
}

    const { selectedOption } = this.state;
    const {customerDetails} = this.props?.customerDetailsData;
   
    return (

      <div>

        <div className="headerBarTop">

          <Header />
        </div>

        <div className="mobileViewSidebar">
          <SideBarMobile />
        </div>
        {this.state.purchaseModal && 
        <PurchaseModal 
        show={this.state.purchaseModal}
        itemList={this.state.itemList}
        handleAddItem={this.handleAddItem}
        handlePurchaseModal={this.handlePurchaseModal}
        handleChange={this.handleChange}
        handleDropdownChange={this.handleDropdownChange}
        handleRemove={this.handleRemove}
        handlePurchaseList={this.handlePurchaseList}
        totalAmount={this.state.totalAmount}
        handleApplyGst={this.handleApplyGst}
        handleRemoveGst={this.handleRemoveGst}
        gstAmount={this.state.gstAmount}
        gstButtonText={this.state.gstButtonText}
        gstButtonStatus={this.state.gstButtonStatus}
        handleChangeAmt={this.handleChangeAmt}
        dueAmount={this.state.dueAmount}
        />
        }

        {(this.state?.billStatusModel == true) && 
          <ConfirmationModal 
              handlePrimaryClick={this.handleBillStatus}
              handleSecondaryClick={this.billCloseRequest}
              header={"Bill Status"}
              message={this.state.billMessage}
              primaryButtonText={"Cancel"}
              secondaryButtonText={(this.state.secondaryButtonClickForBillStatus == true) ? "Close bill":""}

          />
        }
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-2 col-md-12 col-sm-12 sideBARColor">

              <SideBar />

            </div>

            <div className="col-xl-10 col-md-12 col-sm-12  mainBar">
              <div className='container-fluid'>

              <div className='row'>

             


              {(this.state.showMoreDetailStatus == false) && 

                <div className='col-xl-4 col-lg-4 col-md-2 col-sm-2'>
                <h2 style={{textAlign:"left"}}>Transaction Details <span><img src={AddIcon} style={{height:'6%',width:"6%",cursor:"pointer"}} onClick={this.handlePurchaseModal}/></span></h2>    
                </div>
                
              }

              <div className='col-xl-4 col-lg-4 col-md-6 col-sm-6'>
                      <p style={{textAlign:"left",marginTop:"0%",marginBottom:"0%"}}>{customerDetails?.customerName},{customerDetails?.shopName}</p>
                      <p style={{textAlign:"left",marginTop:"0%",marginBottom:"0%"}}>{customerDetails?.customerMobile},{customerDetails?.locality},{customerDetails?.landmark},{customerDetails?.city}</p>
                </div>

              </div>
                <div className='row'>
                <div className='col-xl-12'>
              
                <div className="tableDiv inMobileViewButton " style={{marginTop:'10px'}}>
                  {this.state.showMoreDetailStatus ? (<>
                    <ReactTable 
                    tableHeader={this.state.tableShowMore} 
                    tableBody={tableShowMoreData}
                    />
                  </> ):(
                    <ReactTable 
                     tableHeader={this.state.tableHeader} 
                     tableBody={tableBodyData}
                     />
                    
                  )}  
                 
                    </div>

               
               
                </div>
                    

                </div> 
              </div>

              {(this.state.showMoreDetailStatus == true) ? (<>
                  <div className='row' style={{marginTop:"4%",backgroundColor:"white",height:"10%"}}>
                      <div className='col-xl-2 col-xl-2 col-md-2 col-sm-2 mt-3'>

                          <button className='applyAndSaveButton' onClick={this.handleBack}>Back</button>
                      </div>

                      <div className='col-xl-2 col-xl-2 col-md-2 col-sm-2 mt-2'>
                          <p>Total amount : <span>{currencyFormatter.format(purchaseDetailsInfo?.totalAmount, {
                                      locale: 'en-IN',
                                  })}</span></p>
                      </div>

                      <div className='col-xl-2 col-xl-2 col-md-2 col-sm-2 mt-2'>
                          <p>Amount received : <span>{currencyFormatter.format(purchaseDetailsInfo?.totalRecived, {
                                                                locale: 'en-IN',
                                                            })}</span></p>
                      </div>

                      <div className='col-xl-2 col-xl-2 col-md-2 col-sm-2 mt-2'>
                          <p>Dues amount : <span>{currencyFormatter.format(parseInt(purchaseDetailsInfo?.totalDues), {
                                                                locale: 'en-IN',
                                                            })}</span></p>
                      </div>

                      <div className='col-xl-2 col-xl-2 col-md-2 col-sm-2 mt-2'>
                          <p>GST amount : <span>{currencyFormatter.format(purchaseDetailsInfo?.totalGST, {
                                                                locale: 'en-IN',
                                                            })}
                                                           </span></p>
                      </div>

                      <div className='col-xl-2 col-xl-2 col-md-2 col-sm-2 mt-3'>
                          <button className='applyAndSaveButton' onClick={this.downloadPurchaseDetailsWithPayment}>Print Invoice</button>
                      </div>
                    </div>
              </>) : (
                <div>
                <div className='row' style={{marginTop:"4%",backgroundColor:"white",height:"10%"}}>
                      <div className='col-xl-2 col-xl-2 col-md-2 col-sm-2 mt-3'>
                        <button className='applyAndSaveButton'><Link to="/employeeTotalCustomer" style={{textDecoration:"none",color:"white"}}>Back</Link></button>
                      </div>

                      <div className='col-xl-2 col-xl-2 col-md-2 col-sm-2 mt-2'>
                          <p>Total amount : <span>{currencyFormatter.format(totalDetails?.totalAmount, {
                                                                locale: 'en-IN',
                                                            })}
                                                            </span></p>
                      </div>

                      <div className='col-xl-2 col-xl-2 col-md-2 col-sm-2 mt-2'>
                          <p>Amount received : <span>{currencyFormatter.format(totalDetails?.totalRecived, {
                                                                locale: 'en-IN',
                                                            })}
                                                          </span></p>
                      </div>

                      <div className='col-xl-2 col-xl-2 col-md-2 col-sm-2 mt-2'>
                          <p>Dues amount : <span>{currencyFormatter.format(totalDetails?.totalDues, {
                                                                locale: 'en-IN',
                                                            })}
                                                            </span></p>
                      </div>

                      <div className='col-xl-2 col-xl-2 col-md-2 col-sm-2 mt-2'>
                          <p>GST amount : <span>{currencyFormatter.format(totalDetails?.totalGST, {
                                                                locale: 'en-IN',
                                                            })}
                                                            </span></p>
                      </div>

                      <div className='col-xl-2 col-xl-2 col-md-2 col-sm-2 mt-3'>
                          <button className='applyAndSaveButton' onClick={()=>{this.generateInvoice(this.state.tableData)}}>Print Invoice</button>
                      </div>
                    </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <Modal size="md" show={this.state.showPaymentModal} >
            <Modal.Header >
                <Modal.Title>Payment details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12 col-md-12 col-sm-12">
                          {this.state.paymentList?.length > 0 && <>
                            <table className='table'>
                              <thead>
                                  <tr>
                                    <td>S.no</td>
                                    <td>Received amount</td>
                                    <td>Payment Date</td>
                                  </tr>
                              </thead>
                              <tbody>
                            {this.state.paymentList?.map((item,index)=>(
                              <tr>
                                <td>{index+1}</td>
                                <td>{item.receivedAmount}</td>
                                <td>{moment(item.paymentDate).format('LL')}</td>
                              </tr>

                            ))}
                              </tbody>
                            </table>
                          </>}
                         
                        </div>
                    </div>

                    <div className='row'>
                          <div className='col-xl-4 col-md-4 col-sm-4'>
                              <p style={{width:"100%"}}>Total: {this.state.purchaseInfoObj?.Amount}</p>
                          </div>
                          <div className='col-xl-4 col-md-4 col-sm-4'>
                              <p style={{width:"100%"}}>Received: {this.state.purchaseInfoObj?.receivedAmount}</p>
                          </div>

                          <div className='col-xl-4 col-md-4 col-sm-4'>
                              <p style={{width:"100%"}}>Dues: {(parseInt(this.state.purchaseInfoObj?.Amount) + parseInt(this.state.purchaseInfoObj?.gstAmount) ) - (parseInt(this.state.purchaseInfoObj?.receivedAmount))}</p>
                          </div>

                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button style={{backgroundColor:'#F39C12',borderColor:'#F39C12'}} onClick={this.handleCloseModal}>
                   Cancel
                </Button>
                <Button onClick={this.downloadPurchaseDetailsWithPayment} style={{backgroundColor:'#F39C12',borderColor:'#F39C12'}}>
                   Print Details
                </Button>
            </Modal.Footer>
        </Modal>
        <ToastContainer />
      </div>
    )
  }
}


const mapStateToProps = state => {
  return {
    customerDetailsData: state.customerDetailsReducer
  }
}

const mapDispatchToProps = {
      savePurchaseDetails:savePurchaseDetails
}
export default connect(mapStateToProps, mapDispatchToProps)(EmployessTransactionDetails);