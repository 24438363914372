import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import swal from 'sweetalert';
import { TECHNICAL_ERROR_MESSAGE } from '../Customer/Constant/Constant';
import axios from 'axios';
import Header from './Header';
import SideBar from './SideBar';
import './css/Home.css';
import ReactPaginate from 'react-paginate';
import { Redirect, Link } from 'react-router-dom';
import filters from './Filters';
import moreDatails from './Images/moreDatails.png';
import check from './Images/check.png';
import WSppiner from '../../common/WSppiner';
import SideBarMobile from './sidebar/Sidebar';
import close from './Images/close.png';
import { getDateFormat } from '../sharedModuls/Utils/Utils';
import { ToastContainer, toast } from 'react-toastify';
import { Button, Card, Modal } from 'react-bootstrap';
class OrderStatus extends Component {
    constructor(props) {
        super(props);

        this.state = {
            checkLoginStatus: true,
            data: [],
            data2: [],
            offset: 0,
            tableData: [],
            orgtableData: [],
            perPage: 10,
            currentPage: 0,
            tableShowVar: false,
            isLoading: false,
            Deliveryshow: false,
            DeliveryBoysDetails: [],
            orderID: 0
        }

        let token = sessionStorage.getItem("emailID");

        if (token == null || token == undefined || token == "") {

            this.state.checkLoginStatus = false;
        }
    }



    // For pagination 

    handlePageClick = (e) => {
        const selectedPage = e.selected;
        const offset = selectedPage * this.state.perPage;

        this.setState({
            currentPage: selectedPage,
            offset: offset
        }, () => {
            this.loadMoreData()
        });

    };

    loadMoreData() {
        const data = this.state.orgtableData;

        const slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
        this.setState({
            pageCount: Math.ceil(data.length / this.state.perPage),
            tableData: slice
        })

    }



    method = () => {
        let p = [...this.state.data];
        var tdata = this.state.data;
        var slice = tdata.slice(this.state.offset, this.state.offset + this.state.perPage)
        this.setState({
            pageCount: Math.ceil(tdata.length / this.state.perPage),
            orgtableData: tdata,
            tableData: slice
        });
    }

    componentDidMount() {

        if (this.state.checkLoginStatus == true) {

            this.setState({ isLoading: true });
            this.getDeliveryBoyDetails();
            axios.post(`${process.env.REACT_APP_API_URL_BACKEND_CALL}/getAllOrderStatus`, []
                , { headers: { 'x-access-token': localStorage.getItem("accessToken") } })
                .then(response => {
                    this.setState({ isLoading: false });
                    console.log(response.data)

                    if (response.data.length > 0) {
                        this.setState({ tableShowVar: true });
                        this.setState({ data: response.data }, this.method);

                    }
                    else {
                        this.setState({ tableShowVar: false });
                    }

                })
                .catch(error => {
                    this.setState({ isLoading: false });
                    swal({
                        title: "Oh snap! You got an error!!",
                        text: TECHNICAL_ERROR_MESSAGE,
                        icon: "error",
                        button: "Close",
                    });
                    console.log(error)
                })
        }

    }
    getDeliveryBoyDetails = () => {
        //this.handleCloseDeliveryModel();
        this.setState({ isLoading: true });
        axios.post(`${process.env.REACT_APP_API_URL_BACKEND_CALL}/getDeliveryBoyDetails`, []
            , { headers: { 'x-access-token': localStorage.getItem("accessToken") } })
            .then(response => {
                this.setState({ isLoading: false });
                console.log(response.data);

                this.setState({ DeliveryBoysDetails: response.data });

            })
            .catch(error => {
                this.setState({ isLoading: false });
                swal({
                    title: "Oh snap! You got an error!!",
                    text: TECHNICAL_ERROR_MESSAGE,
                    icon: "error",
                    button: "Close",
                });
                console.log(error)
            })
    }
    handleCloseDeliveryModel = () => {

        this.setState({ Deliveryshow: !this.state.Deliveryshow })
    }
    UpdateAssignDeliveryboy=(deliveryboyid,orderid)=>{
        this.handleCloseDeliveryModel();
        this.setState({orderID:orderid});
            
    }
    AssignDeliveryboy = (deliveryboyid) => {
        this.handleCloseDeliveryModel();
        
        this.setState({ isLoading: true });
        axios.post(`${process.env.REACT_APP_API_URL_BACKEND_CALL}/UpdateAssignDeliveryboy`, { deliveryboyid: deliveryboyid, orderID: this.state.orderID }
            , { headers: { 'x-access-token': localStorage.getItem("accessToken") } })
            .then(response => {
                this.setState({ isLoading: false });
                console.log(response.data)
                if (response.data.affectedRows == 1) {
                    // document.getElementById("assigndeliveryboy").disabled = true;
                    // document.getElementById("assigndeliveryboy").style.backgroundColor = 'grey';
                    toast.success("Assigned !!!", {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });

                }
            })
            .catch(error => {
                this.setState({ isLoading: false });
                swal({
                    title: "Oh snap! You got an error!!",
                    text: TECHNICAL_ERROR_MESSAGE,
                    icon: "error",
                    button: "Close",
                });
                console.log(error)
            })
    }


    render() {
        if (this.state.checkLoginStatus === false) {
            return <Redirect to="/userLogin" />
        }


        return (

            <div>

                {this.state.isLoading && <WSppiner isLoading={this.state.isLoading} />}
                <div className="headerBarTop">

                    <Header />
                </div>

                <div className="mobileViewSidebar">
                    <SideBarMobile />
                </div>

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-2 col-md-12 col-sm-12 sideBARColor">

                            <SideBar />

                        </div>




                        <div className="col-xl-10 col-md-12 col-sm-12  mainBar">


                            <div className="container-fluid">
                                <div className="row">

                                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">

                                        <h5 style={{ marginTop: "2%" }}>New Orders </h5>
                                        <div className="tableDiv">

                                            {this.state.tableShowVar ? (



                                                <table className="table">
                                                    <thead className="tableHead">
                                                        <tr>
                                                            <th>S.no</th>
                                                            <th>Deliveryboy</th>
                                                            <th>OrderId</th>
                                                            <th>DeliveryStatus</th>
                                                            <th>Order Date</th>
                                                            <th>Assign</th>


                                                        </tr>
                                                    </thead>

                                                    <tbody>

                                                        {
                                                            this.state.tableData.map((user, index) => (

                                                                <tr key={index}>
                                                                    <td>{index + 1}</td>
                                                                    <td>{user.delname}</td>
                                                                    <td>{user.orderid} </td>
                                                                    <td>{user.deliverystatus== 0?
                                                                     'Not Delivered':'Delivered'}</td>
                                                                    <td>{getDateFormat(user.regdate)}</td>
                                                                    <button className="mt-2 OrderButtionsInvoice" style={{ color: "white", outline: "none" }} onClick={() => this.UpdateAssignDeliveryboy(user.deliveryboyid,user.orderid)}>Assign</button>




                                                                </tr>

                                                            ))
                                                        }

                                                    </tbody>

                                                </table>

                                            ) : (


                                                <div>
                                                    <h6>Data Not Found</h6>
                                                </div>
                                            )}


                                        </div>

                                        <ReactPaginate
                                            previousLabel={"prev"}
                                            nextLabel={"next"}
                                            breakLabel={"..."}
                                            breakClassName={"break-me"}
                                            pageCount={this.state.pageCount}
                                            marginPagesDisplayed={2}
                                            pageRangeDisplayed={5}
                                            onPageChange={this.handlePageClick}
                                            containerClassName={"pagination"}
                                            subContainerClassName={"pages pagination"}
                                            activeClassName={"active"} />

                                    </div>


                                </div>


                            </div>

                        </div>


                    </div>
                </div>

                <ToastContainer />
                <Modal size="lg" show={this.state.Deliveryshow} onHide={this.handleCloseDeliveryModel}>
                    <Modal.Header closeButton>
                        <Modal.Title> Assign Delivery Boy </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <table className="table">
                            <thead className="tableHead">
                                <tr>
                                    <th>S.no</th>
                                    <th>Name</th>
                                    <th>Mobile</th>
                                    <th>Address</th>
                                    <th>Assign</th>
                                </tr>
                            </thead>

                            <tbody className="">

                                {
                                    this.state.DeliveryBoysDetails.map((user, index) => (

                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{user.dName}</td>
                                            <td>{user.dmobile}</td>
                                            <td>{user.daddress + " " + user.dpincode}</td>
                                            <button className="mt-2 OrderButtionsInvoice" style={{ color: "white", outline: "none" }} onClick={() => this.AssignDeliveryboy(user.delbId)}>Assign</button>
                                        </tr>

                                    ))
                                }

                            </tbody>

                        </table>
                        {/* <Select
                                    isSearchable
                                    value={selectedOption}
                                    onChange={e => { this.setState({ DeliverybodyAssigned: e.value }) }}
                                    options={this.state.Deliveryboys}
                                    placeholder="Delivery Boy"
                                /> */}
                    </Modal.Body>
                    <Modal.Footer>


                        <button className="modelCancelButton" variant="primary" onClick={this.handleCloseDeliveryModel}>
                            Cancel
                        </button>

                    </Modal.Footer>
                </Modal>

            </div>
        )
    }
}



export default OrderStatus;