import React, { Component } from "react";
import "../Customer/css/HomeCustomer.css";
import HeaderNavBar from "../Customer/Header";
import SideBar from "../Customer/sidebar/Sidebar";
import FooterBar from "../Customer/FooterBar";
import axios from "axios";
import { Carousel } from "react-bootstrap";
import crousel1 from "./Images/Asset 1-100.jpg";
import crousel2 from "./Images/Artboard 1.jpg";
import crousel3 from "./Images/Artboard 2.jpg";
import { Link } from "react-router-dom";
import image1 from "../Customer/Images/Image1.png";
import image2 from "../Customer/Images/Image2.png";
import image3 from "../Customer/Images/Image3.png";
import image4 from "../Customer/Images/Image4.png";
import image5 from "../Customer/Images/Image5.png";
import image6 from "../Customer/Images/Image6.png";
import image7 from "../Customer/Images/Image7.png";
import image8 from "../Customer/Images/Image8.png";
import CarouselItem from "./CarouselItem";
import { Redirect } from "react-router-dom";
import OurServices from "./OurServices";
import ProductSlider from "./ProductSlider";
import NewArrivals from "./NewArrivals";
var CryptoJS = require("crypto-js");

class HomeCustomer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchItem: "",
      searchPass: false,
      productDetails: [],
      newArrivals: [],
    };
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  componentDidMount() {
    this.getProductSliderInformation();
    this.getNewArrivalsInformation();
  }

  getProductSliderInformation = async () => {
    console.log("getting called");
    axios
      .get(
        `${process.env.REACT_APP_API_URL_BACKEND_CALL}/getProductSliderInformation`
      )
      .then((response) => {
        console.log("this is resposne for productsliderinformation")
        console.log(response.data.productDetails)
        this.setState({ productDetails: response.data.productDetails });
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  getNewArrivalsInformation = async () => {
    console.log("getting called");
    axios
      .get(
        `${process.env.REACT_APP_API_URL_BACKEND_CALL}/getNewArrivalsInformation`
      )
      .then((response) => {
        console.log("this is new arrivals data")
        console.log(response.data.newarrivals)
        this.setState({ newArrivals: response.data.newarrivals });
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ searchPass: true });
  };

  render() {
    if (this.state.searchPass === true) {
      // Encrypt
      var ciphertext = CryptoJS.AES.encrypt(this.state.searchItem, 'customerCard@@##12345!!@@23').toString();

      return <Redirect to={`/ProductCategory/${this.state.searchItem}`} />
      // return (
      //   <Redirect
      //     to={{
      //       pathname: "/showShopProduct",
      //       propetries: {
      //         productInfomation: this.state.userData,
      //         productTableName: this.state.pTable,
      //       },
      //     }}
      //   />
      // );
    }

    return (
      <div className="homeCustomerPage">
        <div className="setHeaderPositionFixed">
          <HeaderNavBar />
          <SideBar />
        </div>

        <div className="row showCardDiv pt-5 ">
          <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12">

            <div className="serachBarDiv mt-2">
              <form onSubmit={this.handleSubmit}>

                <div class="input-group mb-4 border rounded-pill p-1">
                  <input type="search" placeholder="Location or shop name" aria-describedby="button-addon1" class="form-control bg-none border-0" name="searchItem" value={this.state.searchItem} onChange={this.handleChange} required />
                  <div class="input-group-prepend border-0">
                    <button id="button-addon1" type="submit" class="btn btn-link text-primary"><i class="fa fa-search"></i></button>
                  </div>

                </div>
              </form>
            </div>

          </div>
          <div className="col-xl-2 col-lg-1 col-md-3 col-sm-3 imageALLL">

            <Link to="/ProductCategory/Men">
              <img src={image1} className="imageBarAll" />
            </Link>
          </div>

          <div className="col-xl-2 col-lg-1 col-md-3 col-sm-3 imageALLL">

            <Link to="/ProductCategory/Formal">
              <img src={image2} className="imageBarAll" />
            </Link>
          </div>

          <div className="col-xl-2 col-lg-1 col-md-3 col-sm-3 imageALLL">

            <Link to="/ProductCategory/Plain">
              <img src={image3} className="imageBarAll" />
            </Link>
          </div>

          <div className="col-xl-2 col-lg-1 col-md-3 col-sm-3 imageALLL">

            <Link to="/ProductCategory/Women">
              <img src={image4} className="imageBarAll" />
            </Link>
          </div>

        </div>


        <CarouselItem />
        <Carousel>
          <Carousel.Item>
            <img
              className="d-block w-100 silderImage"
              src={crousel1}
              alt="First slide"
            />
            <Carousel.Caption>
              {/* <h1 className="h1Silder">Reimagine Workplace with COVID-19</h1>
            <h3 className="h3Silder">High performance workforce working from Anywhere (Home, office, or remote locations) beating the market trends.</h3> */}
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100 silderImage"
              src={crousel2}
              alt="Third slide"
            />

            <Carousel.Caption>
              {/* <h1 className="h1Silder">Reimagine Workplace with COVID-19</h1>
            <h3 className="h3Silder">High performance workforce working from Anywhere (Home, office, or remote locations) beating the market trends.</h3> */}
            </Carousel.Caption>
          </Carousel.Item>

          <Carousel.Item>
            <img
              className="d-block w-100 silderImage"
              src={crousel3}
              alt="Third slide"
            />

            <Carousel.Caption>
              {/* <h1 className="h1Silder">Reimagine Workplace with COVID-19</h1>
            <h3 className="h3Silder">High performance workforce working from Anywhere (Home, office, or remote locations) beating the market trends.</h3> */}
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>

        <ProductSlider productDetails={this.state.productDetails} />
        <NewArrivals newArrivals={this.state.newArrivals} />
        <div className="row">
          <div className="col-xl-12">
            <h3
              style={{
                textAlign: "left",
                margin: "1%",
                fontSize: "120%",
                marginTop: "2%",
              }}
            >
              Our Services
            </h3>
          </div>
        </div>
        <OurServices />

        <FooterBar />
      </div>
    );
  }
}

export default HomeCustomer;
