import React, { useEffect, useReducer, useRef, useState } from "react";
import axios from "axios";
import swal from "sweetalert";
import { TECHNICAL_ERROR_MESSAGE } from "./Constant/Constant";
import { Redirect, Link, useHistory } from "react-router-dom";
import logo from "../ClientFolder/Images/rainbow.png";
import success from "../ClientFolder/Images/check.svg";
import wrong from "../ClientFolder/Images/close.svg";
import capcha from "../ClientFolder/Images/captcha.png";
import refressCaptch from "../ClientFolder/Images/refresh.png";

import WSppiner from "../../common/WSppiner";
import SideBar from "./sidebar/Sidebar";
import FooterBar from "./FooterBar";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Button, Card, Modal } from "react-bootstrap";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Header from "./Header";
import "./css/userLogin.css";
import { encryptData, decryptData, getCaptcha } from "../sharedModuls/Utils/Utils";
const crypto = require("crypto-js");

const ClientLogin = (props) => {
  // const [email ,setEmail] = useState("");
  // const [password , setPassword] = useState("");
  // console.log("test", props);
  const email = useRef(null);
  const password = useRef(null);
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const captchaCode = useRef(null);
  const [showCaptchCode, setShowCaptchCode] = useState("");

  const [show, setShow] = useState(false);

  const [clientVerify, setClientVerify] = useState(false);
  const submitForm = (e) => {
    e.preventDefault();
    let key = "password";

    if (captchaCode.current.value === showCaptchCode) {
      if (
        email.current.value != null &&
        email.current.value != "" &&
        password.current.value != null &&
        password.current.value != ""
      ) {
        setIsLoading(true);
        let emailEnc = encryptData(email.current.value, key);
        let passwordEnc = encryptData(password.current.value, key);

        axios
          .post(`${process.env.REACT_APP_API_URL_BACKEND_CALL}/customerLogin`, {
            email: emailEnc,
            password: passwordEnc,
          })
          .then((response) => {
            let data = [];
            if (response.data.loginStatus === "loginSuccessfully") {
              data = decryptData(response.data.userInformation, key);
              data = JSON.parse(data.toString());
              setIsLoading(false);
              sessionStorage.setItem("customerID", data[0].cid);
              sessionStorage.setItem("customerEmail", data[0].cEmail);
              sessionStorage.setItem("customerName", data[0].cName);
              sessionStorage.setItem("customerMobile", data[0].cMobile);
              localStorage.setItem("accessToken", response.data.token);
              if (
                JSON.parse(sessionStorage.getItem("productDataForBoyNow")) !=
                  null &&
                JSON.parse(sessionStorage.getItem("productDataForBoyNow")) !=
                  undefined &&
                JSON.parse(sessionStorage.getItem("productDataForBoyNow")) != ""
              ) {
                history.push(
                  `${
                    JSON.parse(sessionStorage.getItem("productDataForBoyNow"))
                      .redirectPage
                  }`
                );
              } else {
                console.log("else1", props);
                if (
                  JSON.parse(
                    sessionStorage.getItem("productDataForAddtoCart")
                  ) != null &&
                  JSON.parse(
                    sessionStorage.getItem("productDataForAddtoCart")
                  ) != undefined &&
                  JSON.parse(
                    sessionStorage.getItem("productDataForAddtoCart")
                  ) != ""
                ) {
                  console.log(
                    "else2",
                    JSON.parse(
                      sessionStorage.getItem("productDataForAddtoCart")
                    )
                  );
                  if (
                    JSON.parse(
                      sessionStorage.getItem("productDataForAddtoCart")
                    ).hash === "ProductInfo"
                  ) {
                    history.push("/ProductInfo", {
                      productInfomation: JSON.parse(
                        sessionStorage.getItem("productDataForAddtoCart")
                      ).productInfomation,
                      productTableName: JSON.parse(
                        sessionStorage.getItem("productDataForAddtoCart")
                      ).productTable,
                      //flag :props.history.location.propetries.flag
                    });
                  } else {
                    console.log("else5", props);
                    history.push("/showShopProduct", {
                      productInfomation: JSON.parse(
                        sessionStorage.getItem("productDataForAddtoCart")
                      ).productInfomation,
                      productTableName: JSON.parse(
                        sessionStorage.getItem("productDataForAddtoCart")
                      ).productTable,
                    });
                  }
                } else {
                  console.log("elsehgjfg", data);
                  setIsLoading(false);
                  localStorage.setItem("accessToken", response.data.token);
                  history.push("/CustomerProfile");
                }
              }
            } else {
              setIsLoading(false);
              document.getElementById("loginerror").style.display = "block";
              document.getElementById("loginerror").style.color = "red";
              console.log("failed");

              // toast.error('🦄 Email or Password Invalid !!', {
              //   position: "top-right",
              //   autoClose: 3000,
              //   hideProgressBar: false,
              //   closeOnClick: true,
              //   pauseOnHover: true,
              //   draggable: true,
              //   progress: undefined,
              // });
              // NotificationManager.error('Email or Password Invalid !!', 'Error Message !', 3000);
            }
          })
          .catch((error) => {
            setIsLoading(false);
            swal({
              title: "Oh snap! You got an error!!",
              text: TECHNICAL_ERROR_MESSAGE,
              icon: "error",
              button: "Close",
            });
            console.log(error);
          });
      } else {
        setIsLoading(false);
        toast.error("🦄 Email or Password Invalid !!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        //  NotificationManager.error('Email or Password Invalid !!', 'Error Message !', 2000);
      }
    } else {
      setIsLoading(false);
      toast.error("🦄 Please Enter Valid Captcha Code !!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      //  NotificationManager.error('Email or Password Invalid !!', 'Error Message !', 2000);
    }
  };

  const handleModalPasss = () => {
    setShow(!show);
  };

  const handleClientVerify = () => {
    setClientVerify(!clientVerify);
  };

  useEffect(() => {
    document.getElementById("loginerror").style.display = "none";
    // sessionStorage.clear();
    // localStorage.clear();

    // let chars = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXTZabcdefghiklmnopqrstuvwxyz";
    // var string_length = 7;
    // var randomstring = "";

    // for (var i = 0; i < string_length; i++) {
    //   var rnum = Math.floor(Math.random() * chars.length);
    //   randomstring += chars.substring(rnum, rnum + 1);
    // }

    let randomstring = getCaptcha();
      console.log("this is change password useeffect")
      console.log("this is randomstring " , randomstring)
    setShowCaptchCode(randomstring);
  }, []);

  const handleCaptcha = () => {
    // let chars = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXTZabcdefghiklmnopqrstuvwxyz";
    // var string_length = 7;
    // var randomstring = "";

    // for (var i = 0; i < string_length; i++) {
    //   var rnum = Math.floor(Math.random() * chars.length);
    //   randomstring += chars.substring(rnum, rnum + 1);
    // }

    let randomstring = getCaptcha();
      console.log("this is change password useeffect")
      console.log("this is randomstring " , randomstring)
    setShowCaptchCode(randomstring);
  };

  return (
    <div className="regFormColor">
      {isLoading && <WSppiner isLoading={isLoading} />}

      <Header />
      <SideBar />

      <div className="loginHeaderDivO pt-4">
        <div className="row">
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
            <img src={logo} className="rainbowImage" />
          </div>
        </div>
      </div>

      <div className="login card">
        <h1>Customer Login</h1>
        <form onSubmit={submitForm}>
          <p id="loginerror">Invalid Email / Password</p>
          <input
            type="text"
            className="form-control col-sm-12"
            ref={email}
            placeholder="Enter email or mobile"
            id="email"
            required
          />
          <br />
          <input
            type="password"
            className="form-control col-sm-12"
            ref={password}
            placeholder="Password"
            id="password"
            required
          />
          <br />

          <div className="row">
            <div className="col-xl-5 col-lg-5 col-md-12 col-sm-12">
              {/* <img src={capcha} className="captchaImageChangePassword" />{" "}

              <span className="catchaCodeChangePassword">{showCaptchCode}</span> */}
              <center>
                {" "}
                <div
                  className="customerLoginCaptchaCodeDiv"
                  style={{ backgroundImage: `url(${capcha})` }}
                >
                  {showCaptchCode}
                </div>
              </center>
              <br />
            </div>

            <div className="col-xl-2 col-lg-2 col-md-2 col-sm-12 mt-2 ">
              <center>
                {" "}
                <img
                  onClick={handleCaptcha}
                  className="reFressCaptchaChangePassword"
                  src="https://img.icons8.com/external-kmg-design-detailed-outline-kmg-design/64/000000/external-refresh-arrow-kmg-design-detailed-outline-kmg-design.png"
                />
              </center>{" "}
              {/* <img src={refressCaptch} className="reFressCaptcha" onClick={handleCaptcha} /> */}
            </div>

            <div className="col-xl-5 col-lg-5 col-md-12 col-sm-12 mt-12">
              <input
                type="text"
                name="captchaCode"
                ref={captchaCode}
                required
                placeholder="Enter CAPTCHA "
                className="form-control col-sm-12"
                autoComplete="off"
              />
            </div>
          </div>

          <div className="row mt-2">
            <div className="col-xl-6 col-md-12 col-sm-12">
              <div className="registrationDivMobileSet">
                <Link
                  to="/CustomerRegistration"
                  style={{ textDecoration: "none" }}
                  className="registrationLinkButton"
                >
                  New Registration
                </Link>
              </div>

              <div className="loginDivMobileSet ">
                {/* <p className=""><input type="submit" name="commit" value="Logindss" className="" /></p> */}
                <p className="">
                  <input
                    type="submit"
                    name="commit"
                    value="Login"
                    className="LoginButtonNewButton"
                  />
                </p>
              </div>
            </div>

            <div className="col-xl-6 col-md-12 col-sm-12">
              <div className="loginDivMobileSetTwo">
                <p className="">
                  <input
                    type="submit"
                    name="commit"
                    value="Login"
                    className="LoginButtonNewButton"
                  />
                </p>
                {/* <p className=""><input type="submit" name="commit" value="Login" className="LoginButtonNew" /></p> */}
              </div>

              <div className="registrationDivMobileSetTwo">
                <p className="registButton">
                  <Link
                    to="/CustomerRegistration"
                    style={{ textDecoration: "none" }}
                    className="registrationLinkButton"
                  >
                    New Registration
                  </Link>
                </p>
              </div>
            </div>
          </div>

          <div className="login-help">
            <p style={{ color: "black" }}>
              Forgot your password?{" "}
              <Link to="/ForgotCustomerPassword">
                <span className="forgotPass">Click here to reset it.</span>
              </Link>
            </p>
          </div>
        </form>
        <button
          className="supportButton"
          style={{ marginBottom: "3px" }}
          onClick={handleModalPasss}
        >
          <FontAwesomeIcon icon={faPhone} />
          &nbsp;&nbsp;&nbsp;&nbsp;Support
        </button>
      </div>

      <FooterBar />
      <ToastContainer />

      <Modal size="md" show={clientVerify} onHide={handleClientVerify}>
        <Modal.Header closeButton>
          <Modal.Title> </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            <div className="row">
              <div className="col-xl-12 col-md-12 col-sm-12">
                <h5>
                  Please check your email to verify your email address before
                  login.
                </h5>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            style={{ backgroundColor: "#F39C12", borderColor: "#F39C12" }}
            onClick={handleClientVerify}
          >
            OK
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal size="md" show={show} onHide={handleModalPasss}>
        <Modal.Header closeButton>
          <Modal.Title> Support </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            <div className="row">
              <div className="col-xl-12 col-md-12 col-sm-12">
                <div>
                  <p>Rainbow Cart Support</p>
                  <p>rainbocart12345@gmail.com</p>
                  <p>+91 7209589149</p>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            style={{ backgroundColor: "#F39C12", borderColor: "#F39C12" }}
            onClick={handleModalPasss}
          >
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ClientLogin;
