import React, { Component } from 'react';
import logo from '../ClientFolder/Images/rainbow.png';
import Select from 'react-select';
import axios from 'axios';
import swal from 'sweetalert';
import { TECHNICAL_ERROR_MESSAGE } from '../Customer/Constant/Constant';
import WSppiner from '../../common/WSppiner';
import { Redirect, Link } from 'react-router-dom';
import Header from './Header';
import SideBar from '../Admin/SideBar';
import SideBarMobile from './sidebar/Sidebar';
import ReactTable from '../../common/ReactTable';
import moment from 'moment';
import {calculateTotals, convertNumberToMonth, createChartOption, currencyFormatterFunction} from '../../Utils/Utils';
import ChartComponent from '../Charts/ChartComponent';
import { lineChartOption } from './ChartOption';


const Role = [

    { name: "Role", value: "Role1", label: "Role1" },
    { name: "Role", value: "Role2", label: "Role2" },
    { name: "Role", value: "Role3", label: "Role3" }
]

const daysFilter = [
    { name: "daysFilter", value: "7 DAY", label: "Last one week" },
    { name: "daysFilter", value: "15 DAY", label: "Last 15 days" },
    { name: "daysFilter", value: "1 MONTH", label: "Last one month" },
    { name: "daysFilter", value: "3 MONTH", label: "Last three month" },
    { name: "daysFilter", value: "6 MONTH", label: "Last six month" },
    { name: "daysFilter", value: "9 MONTH", label: "Last nine month" },
    { name: "daysFilter", value: "1 YEAR", label: "Last one year" },
    { name: "daysFilter", value: "2 YEAR", label: "Last two year" },
    { name: "daysFilter", value: "3 YEAR", label: "Last three year" }
]

export default class Transactions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            transactionText:"Total transaction",
            tableHeader: [
                "Sl. No",
                "Shop",
                "Amount",
                "GST",
                "Received",
                "Dues",
                "Status",
                "Purchase Date",
                "Sell by"
              ],
              tableData:[],
              searchData: "",
              startDate:"",
              endDate:"",
              totalAmount:0,
              totalGStAmount:0,
              totalDuesAmount:0,
              totalRecivedAmount:0,
              loading:false,
              chartOption:{
                chart: {
                  width: 900,
                  type: 'pie',
                },
                colors: ['#3498DB', '#F8C471', '#808B96'],
                labels: ['Shirt', 'Shorts', 'Trouser'],
                fill: {
                    colors: ['#3498DB', '#F8C471', '#808B96']
                  }
                ,
                legend: { 
                    show:true,
                    position: 'top',
                    markers: {
                        width: 12,
                        height: 12,
                        strokeWidth: 0,
                        strokeColor: '#fff',
                        fillColors: ['#3498DB', '#F8C471', '#808B96'],
                        radius: 12,
                        customHTML: undefined,
                        onClick: undefined,
                        offsetX: 0,
                        offsetY: 0
                    },
                },
                tooltip: {
                    fillSeriesColor: true,
                }

              },
            seriesData:[],
            seriesDataForOverallSellQuantity :[],
            chartOptionForOverallSellQuantity :[],
            chartOptionFormostFiveCustomes:[],
            mostFiveCustomes:[]
        }
    }
    componentDidMount(){

      this.setState({
        loading:true
    })
       this.getTransactionDetails(false);
       this.getItemListReports();
       this.getTotalReportOfCustomer();
       // document.getElementById("currentEmailID").style.display = "none";
    }

    getTransactionValues = (itemListReport,key) =>
    {
        return itemListReport?.filter((objValue)=> objValue.item == key)[0]?.sellAmount;
    }

    getTotalReportOfCustomer = () =>
    {
      axios.post(`${process.env.REACT_APP_API_URL_BACKEND_ENDPOINT}/getTotalReportOfCustomer`,{},
      {headers : {'x-access-token' : localStorage.getItem("accessToken")}})
      .then((response)=>{

          if(response.data?.customerSellReportList?.length > 0)
          {
              const listReportData = response.data?.customerSellReportList;
              let xAxisValue = [];
              let chartValue = [];
              for(let i=0;i<listReportData?.length;i++)
              {
                  chartValue.push(listReportData[i].noofQun)
                  xAxisValue.push(`${listReportData[i].customerName}-${listReportData[i].shopName}`)
              }
              this.setState({
                 // mostFiveCustomes:chartValue,
                  chartOptionFormostFiveCustomes: createChartOption(lineChartOption,chartValue,xAxisValue,'Top five best customers',100,false),
                  mostFiveCustomes: [
                    {
                      name: "No of Sell Quantity - 2023",
                      data: chartValue
                    }
                  ],
                  loading:false
                  
              })
          }
      })
      .catch((error)=>{

          swal({
            title: "Confirmed",
            text: TECHNICAL_ERROR_MESSAGE,
            icon: "error",
            button: "Close",
        }); 
        this.setState({
            loading:false
        })
      })
    }


    getItemListReports = () =>
    {
      axios.post(`${process.env.REACT_APP_API_URL_BACKEND_ENDPOINT}/getItemListReport`,{},
      {headers : {'x-access-token' : localStorage.getItem("accessToken")}})
      .then((response)=>{

          if(response.data?.itemListReport?.length > 0)
          {
              const listReportData = response.data?.itemListReport;
              const sellQuantityListMonthwise = response.data?.sellQuantityListMonthwise;
              let monthValue = [];
              let chartValue = [];
              for(let i=0;i<response.data?.sellQuantityListMonthwise?.length;i++)
              {
                  const monthName = convertNumberToMonth(parseInt(sellQuantityListMonthwise[i].monthName));
                  monthValue.push(monthName)
                  chartValue.push(sellQuantityListMonthwise[i].sellQuantity)
              }
              this.setState({
                  seriesData:[this.getTransactionValues(listReportData,'Shirt'),this.getTransactionValues(listReportData,'Shorts'),this.getTransactionValues(listReportData,'Trouser')],
                  chartOptionForOverallSellQuantity: createChartOption(lineChartOption,chartValue,monthValue,'No of Quantity sell on every month',100),
                  seriesDataForOverallSellQuantity: [
                    {
                      name: "No of Sell Quantity - Last 1 year",
                      data: chartValue
                    }
                  ],
                  loading:false
                  
              })
          }
      })
      .catch((error)=>{

          swal({
            title: "Confirmed",
            text: TECHNICAL_ERROR_MESSAGE,
            icon: "error",
            button: "Close",
        }); 
        this.setState({
            loading:false
        })
      })
    }

    getTransactionDetails = (filterApply,filterValue = []) =>
    {
        let payload = {}
        if(filterApply == true && filterValue?.length > 0)
        {
           payload = {
            filterApply:filterApply,
            intervalValue: parseInt(filterValue[0]),
            intervalType:filterValue[1]
          }
        }
        else
        {
          payload = {
            filterApply:filterApply
          }
        }
       
        axios.post(`${process.env.REACT_APP_API_URL_BACKEND_ENDPOINT}/totalTransactions`,payload,
        {headers : {'x-access-token' : localStorage.getItem("accessToken")}})
        .then((response)=>{

            if(response.data?.itemList?.length > 0)
            {
                const totalInfo = calculateTotals(response.data?.itemList);
                this.setState({
                    transactionText: filterApply ? this.state.transactionText:"Total transaction",
                    tableData:response.data?.itemList,
                    ...totalInfo,
                    loading:false
                    
                })
            }
        })
        .catch((error)=>{

            swal({
              title: "Confirmed",
              text: TECHNICAL_ERROR_MESSAGE,
              icon: "error",
              button: "Close",
          }); 
          this.setState({
              loading:false
          })
        })
    }

    getTransactionDetailsBetweenTwoDate = (filterApply,filterValue = []) =>
    {
        const payload = {
            startDate:this.state.startDate,
            endDate:this.state.endDate
          }
        axios.post(`${process.env.REACT_APP_API_URL_BACKEND_ENDPOINT}/totalTransactionsBetweenTwoDates`,payload,
        {headers : {'x-access-token' : localStorage.getItem("accessToken")}})
        .then((response)=>{
                if(response.data?.itemList?.length > 0)
                {
                  const totalInfo = calculateTotals(response.data?.itemList);
                  this.setState({
                      
                      tableData:response.data?.itemList,
                      ...totalInfo
                      
                  })
                }
        })
        .catch((error)=>{

        })
    }


    handleChangesDate = (event)=>
    {
        const splitDate = event.value?.split(" ")
        this.getTransactionDetails(true,splitDate);
        this.setState({
          transactionText:event.label,

        })
    }
    
    handleStartDate = (event)=>
    {
        this.setState({startDate:event.target.value})
    }

    handleEndDate = (event)=>
    {
        this.setState({endDate:event.target.value})
    }



    render() {
        if (this.state.checkStatus === true) {
            return <Redirect to="/" />
        }

        let tableBodyData = []
        if (this.state.tableData?.length > 0) {
          tableBodyData = this.state.tableData?.filter((val) => {
            if (this.state.searchData == "") {
              return val
            } else if (val.shopName.toLowerCase().includes(this.state.searchData.toLowerCase())) {
              return val
            } 
          }).map((user, index) => {
    
            return <tr key={index}>
              <td>{index + 1}</td>
              <td>{user.shopName}</td>
              <td>{(parseInt(user.Amount) + parseInt(user.gstAmount))}</td>
              <td>{user.gstAmount}</td>
              <td>{(user.editFlag == true) ? <>
                {user.receivedAmount}
                &nbsp;<input type='number' onChange={(e)=>{this.amountHandleChange(e,user?.Amount,user?.receivedAmount)}} id='editAmout' placeholder='add amount' />
                {(this.state.amountError !="" ) && <p style={{color:"red",fontSize:"80%"}}>{this.state.amountError}</p>}
              </> : <>{user.receivedAmount}</>}</td>
              <td>{((parseInt(user.Amount) +  parseInt(user.gstAmount))- parseInt(user.receivedAmount))}</td>
             
              <td>{(user.billStatus == "Open") ? <p style={{color:"red",fontWeight:"bold"}}>{user.billStatus}</p> : <p style={{color:"green",fontWeight:"bold"}}>{user.billStatus}</p>}</td>
              <td>{moment(user.transRegDate).format('LL')}</td>
              <td>{user?.sellerEmpId?.substring(user?.sellerEmpId?.indexOf('_') + 1, user?.sellerEmpId?.lastIndexOf('_'))}</td>
              {/* <td><img src={viewIcon} style={{height:"20px",width:"20px",cursor:"pointer"}} onClick={()=>{this.showPaymentDetails(user)}} /></td> */}
              {/* <td>
              <img src={editIcon} onClick={()=>{this.editTransactionDetails(index)}} style={{height:"20px",width:"20px",cursor:"pointer"}} />
              {(user.editFlag == true && this.state.amountError =="") && <button className='btn btn-primary' onClick={()=>{this.saveTransactionDetails(index,user)}}>Save</button> }
              {(user.editFlag == true) && <> &nbsp;<button className='btn btn-primary' onClick={()=>{this.cancelTransactionDetails(index)}}>Cancel</button></> }
              </td>
              <td><img src={moreDetailsImage} onClick={()=>{this.showMoreDetail(user)}} style={{height:"20px",width:"20px",cursor:"pointer"}} /></td> */}
            </tr>
          })
        }

        const { transactionText,selectedOption,chartOption,seriesData,
          chartOptionForOverallSellQuantity,seriesDataForOverallSellQuantity,chartOptionFormostFiveCustomes,mostFiveCustomes } = this.state;

        return (
            
            <div>

            {this.state.loading && 
                
                <WSppiner isLoading={this.state.loading}/>
                
                
                }

                 <div className="headerBarTop">

                <Header />
                </div>

                <div className="mobileViewSidebar">
                <SideBarMobile />
                </div>

                <div className="container-fluid">
                <div className="row">
                <div className="col-xl-2 col-md-12 col-sm-12 sideBARColor">

                <SideBar />

                </div> 

              <div className="col-xl-10 col-md-12 col-sm-12  mainBar">

                    <div className='row'>
                        <div className='col-xl-2'>
                            <p style={{textAlign:"left",paddingTop:"10%"}} >{transactionText}</p>
                        </div>

                        <div className='col-xl-2'>
                            <input className='input_search' type="text" value={this.state.searchData} onChange={(e)=>this.setState({searchData:e.target.value})} placeholder='Search shop name'/>
                        </div>
                        <div className='col-xl-2' style={{paddingTop:"0.5%"}}>
                           <Select  isSearchable   value={selectedOption}  options={daysFilter} onChange={this.handleChangesDate} placeholder="Select dates"  style={{paddingTop:"10%"}}/>
                        </div>
                        <div className='col-xl-4'>
                            <p style={{paddingTop:"3%",textAlign:"left"}}>Start: <input type='date' style={{width:'110px' }} onChange={this.handleStartDate} /> End : <input type='date' style={{width:'110px' }} onChange={this.handleEndDate} /></p>
                        </div>

                        <div className='col-xl-2'>
              
                        <div className="button-container">
                            <button className="buttonClass" onClick={this.getTransactionDetailsBetweenTwoDate}>Apply</button>
                            <button className="buttonClass" onClick={()=>{this.getTransactionDetails(false)}}>Reset</button>
                        </div>
                        </div>

                    </div>

                    <div className='row'>
                        <div className='col-xl-4 col-lg-4 col-md-12 col-sm-12'>
                          <div className='transactionChartDesign'>
                              {seriesData?.length > 0 && 
                                <ChartComponent  
                                    options={chartOption}
                                    series={seriesData}
                                    chartType={'pie'}
                                    chartHeight={(window.innerWidth < 400)? 320:270}
                              />
                              }
                              
                          </div>
                        </div>


                        <div className='col-xl-8 col-lg-8 col-md-12 col-sm-12'>
                          <div className='transactionChartDesign'>
                              {seriesDataForOverallSellQuantity?.length > 0 && 
                                <ChartComponent  
                                    options={chartOptionForOverallSellQuantity}
                                    series={seriesDataForOverallSellQuantity}
                                    chartType={'bar'}
                                    chartHeight={(window.innerWidth < 400)? 320:270}
                              />
                              
                              }
                             
                          </div>
                        </div>


                    </div>

                    {/* Second row graph */}
                    <div className='row mt-3'>
                        {/* <div className='col-xl-4 col-lg-4 col-md-12 col-sm-12'>
                          <div className='transactionChartDesign'>
                          {seriesData?.length > 0 && 
                                <ChartComponent  
                                    options={chartOption}
                                    series={seriesData}
                                    chartType={'pie'}
                                    chartHeight={(window.innerWidth < 400)? 320:270}
                              />
                              }
                              
                          </div>
                        </div> */}


                        <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12'>
                          <div className='transactionChartDesign'>
                          {mostFiveCustomes?.length > 0 && 
                                <ChartComponent  
                                    options={chartOptionFormostFiveCustomes}
                                    series={mostFiveCustomes}
                                    chartType={'bar'}
                                    chartHeight={(window.innerWidth < 400)? 320:270}
                              />
                              }
                             
                          </div>
                        </div>


                    </div>

                    <div className="tableDiv inMobileViewButton " style={{marginTop:'10px'}}>
                        <ReactTable 
                        tableHeader={this.state.tableHeader} 
                        tableBody={tableBodyData}
                        />

                    </div>

                    <div className='row mt-3'>
                        <div className='col-xl-3'>
                            <p><b>Total amunt:{currencyFormatterFunction(this.state.totalAmount)}</b></p>
                        </div>

                        <div className='col-xl-3'>
                            <p><b>Total GST:{currencyFormatterFunction(this.state.totalGStAmount)}</b></p>
                        </div>

                        <div className='col-xl-3'>
                            <p><b>Total recived:{currencyFormatterFunction(this.state.totalRecivedAmount)}</b></p>
                        </div>

                        <div className='col-xl-3'>
                            <p><b>Total dues:{currencyFormatterFunction(this.state.totalDuesAmount)}</b></p>
                        </div>

                    </div>
                   
              </div>
              </div>
              </div>
                
            </div>
         

        )
    }
}
