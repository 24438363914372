import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import Header from './Header';
import SideBar from './SideBar';
import Select from 'react-select';
import '../ClientFolder/css/Home.css';
import {Redirect,Link} from 'react-router-dom';
import {Button, Card,Modal} from 'react-bootstrap';
import edit from '../ClientFolder/Images/pencil.svg';
import deleteImage from '../ClientFolder/Images/delete.svg';
import filters from './Filters';
import ReactPaginate from 'react-paginate';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SideBarMobile from './sidebar/Sidebar';
import ReactTable from '../../common/ReactTable';
import moreDetailsImage from '../../assets/moreDatails.png';
import { saveCustomerDetails } from '../../Services/Actions/CustomerDetailsAction';
import { connect } from 'react-redux';
import currencyFormatter from 'currency-formatter';
import editIcon from '../../assets/edit.png';
import swal from 'sweetalert';
import { TECHNICAL_ERROR_MESSAGE } from '../Customer/Constant/Constant';
import WSppiner from '../../common/WSppiner';

class TotalEmployees extends Component
{
    constructor(props)
    {
        super(props);

        this.state = {
          checkLoginStatus : true, 
          show : false,
          offset: 0,
          tableData: [],
          perPage: 10,
          currentPage: 0,
          data : [],        
          f:{pCompanyName:[],productName:[],pCategory:[],pColor:[],pSize:[]},
        
          tableHeader:[
          "Sl. No",
          "Name",
          "Mobile",
          "Alt mobile",
          "Home Mobile",
          "Aadhar number",
          "Role",
          "Locality",
          "Landmark",
          "City",
          "Pincode"
          ],
          searchData:"",
          redirectTransactionFlow : false,
          customerName:"",
          customerEmail:"",
          customerMobile:"",
          customerAltMobile:"",
          customerShopName:"",
          customerID:"",
          loading:false
        }

        let token  = JSON.parse(sessionStorage.getItem("loginDetails"));

        if(token ==  null || token == undefined || token == "")
        {
            
            this.state.checkLoginStatus = false;
        }

      
    
    }


	  componentDidMount =()=>
    {
        this.setState({
          loading:true
      })
        this.getTotalEmployeesInfo();
    }

    getTotalEmployeesInfo = ()=>
    {
      axios.post(`${process.env.REACT_APP_API_URL_BACKEND_ENDPOINT}/totalEmployeesInfo`,{},
      {headers : {'x-access-token' : localStorage.getItem("accessToken")}})
      .then((response)=>{
          this.setState({tableData : response.data?.employeesList,loading:false});
  
      }).catch(function (error){

          swal({
            title: "Confirmed",
            text: TECHNICAL_ERROR_MESSAGE,
            icon: "error",
            button: "Close",
        }); 
        this.setState({
            loading:false
        })
      })

    }

    handleChange1= (selectedOptions) => {

      let a = {...this.state.f};
      a.pCompanyName = selectedOptions;

      let b = [];
      this.setState({ f:a, filterArray:[...b], temp1:[...b], temp:[...b],category:[...b]},this.method);
    
    }



    handleChangeAll = (event) =>{

      this.setState({[event.target.name ] : event.target.value});

  }
  
           // For pagination 
    handlePageClick = (e) => {
      const selectedPage = e.selected;
      const offset = selectedPage * this.state.perPage;

      this.setState({
          currentPage: selectedPage,
          offset: offset
      }, () => {
          this.loadMoreData()
      });    
    };    

    redirectToTransaction = (customerDetails) =>
    {
        const {saveCustomerDetails} = this.props;
        saveCustomerDetails(customerDetails)
        sessionStorage.setItem("customerDetails",JSON.stringify(customerDetails))
        this.setState({redirectTransactionFlow:true,customerId:this.state.customerId})
    }

    editCustomerDetails = (details = "") =>
    {
        console.log('details',details)
        if(details?.customerId !="")
        {
          this.setState({show:!this.state.show,
            customerId:details?.customerId,
            customerName:details?.customerName,
            customerEmail:details?.customerEmail,
            customerMobile:details?.customerMobile,
            customerAltMobile:details?.customerAltMobile,
            customerShopName:details?.shopName,
            customerName:details?.customerName})
        }
        else
        {
          this.setState({show:!this.state.show})
        }
    }

    submitModal = () =>
    {

        const payload = {
          customerId : this.state.customerId,
          customerName : this.state.customerName,
          customerEmail :this.state.customerEmail,
          customerMobile : this.state.customerMobile,
          customerAltMobile : this.state.customerAltMobile,
          customerShopName : this.state.customerShopName,
        }
        axios.post(`${process.env.REACT_APP_API_URL_BACKEND_ENDPOINT}/editCustomerDetails`,payload,
        {headers : {'x-access-token' : localStorage.getItem("accessToken")}})
        .then((response)=>{

          
          if(response.data?.message == "updateSuccess")
          {
            this.setState({show:!this.state.show,
              customerId:"",
              customerName:"",
              customerEmail:"",
              customerMobile:"",
              customerShopName:"",
              customerName:""
            })
          }
          else
          {
            this.setState({show:!this.state.show,
              customerId:"",
              customerName:"",
              customerEmail:"",
              customerMobile:"",
              customerShopName:"",
              customerName:""
            })
          }
        }).catch(function (error){

            alert(error);
        })
    }

     render(){

      if(this.state.checkLoginStatus ===  false)
      {
          return <Redirect  to="/adminLogin" />
      }

      if(this.state.redirectTransactionFlow == true)
      {
          return <Redirect to="/TransactionDetails" />
      }

      let tableBodyData=[]
      if(this.state.tableData.length>0)
      {
         tableBodyData =  this.state.tableData?.filter((val)=>{
          if(this.state.searchData == "")
          {
            return val
          } else if (val.empName.toLowerCase().includes(this.state.searchData.toLowerCase()))
          {
            return val
          } else if (val.empMobile.toLowerCase().includes(this.state.searchData.toLowerCase()))
          {
            return val
          } else if (val.empAltpMobile.toLowerCase().includes(this.state.searchData.toLowerCase()))
          {
            return val
          }
          else if (val.houseMobile.toLowerCase().includes(this.state.searchData.toLowerCase()))
          {
            return val
          }
          else if (val.employessRole.toLowerCase().includes(this.state.searchData.toLowerCase()))
          {
            return val
          }
          else if (val.landmark.toLowerCase().includes(this.state.searchData.toLowerCase()))
          {
            return val
          }
          else if (val.locality.toLowerCase().includes(this.state.searchData.toLowerCase()))
          {
            return val
          }
          else if (val.city.toLowerCase().includes(this.state.searchData.toLowerCase()))
          {
            return val
          }
          
        }).map((user,index)=>{
  
         return <tr key={index}>
          <td>{index + 1}</td>
          <td>{user.empName}</td>
          <td>{user.empMobile}</td>
          <td>{user.empAltpMobile}</td>
          <td>{user.houseMobile}</td>
          <td>{user.aadharNumber}</td>
          <td>{user.employessRole}</td>
          <td>{user.locality}</td>
          <td>{user.landmark}</td>
          <td>{user.city}</td>
          <td>{user.pincode}</td>
          {/* <td><img src={editIcon} onClick={()=>{this.editCustomerDetails(user)}} style={{height:"20px",width:"20px",cursor:"pointer"}} /></td> */}
          {/* <td><img src={moreDetailsImage} onClick={()=>{this.redirectToTransaction(user)}} style={{height:"20px",width:"20px",cursor:"pointer"}} /></td> */}
          </tr>
        })  
      }

      const { selectedOption } = this.state;


        return(

            <div>

              {this.state.loading && 
                
                <WSppiner isLoading={this.state.loading}/>
                
                
                }

              <div className="headerBarTop">

              <Header/>
              </div>

              <div className="mobileViewSidebar">
                <SideBarMobile />
              </div>

              <div className="container-fluid">
              <div className="row">
              <div className="col-xl-2 col-md-12 col-sm-12 sideBARColor">

              <SideBar />

              </div> 

              <div className="col-xl-10 col-md-12 col-sm-12  mainBar">
              <h2 style={{marginTop: "10px"}}>Total Employees</h2>

              <div className="row  filterBarOK">              
                  <div className="col-xl-2 col-md-12 col-sm-12 inMobileViewButton">
                   <input className='input_search' type="text" value={this.state.searchData} onChange={(e)=>this.setState({searchData:e.target.value})} placeholder='Search Customer'/>

                  </div>

                  <div className="col-xl-2 col-md-12 col-sm-12 inMobileViewButton">

                     {/* <Select isSearchable isMulti value={selectedOption} onChange={this.handleChange2}  options={this.state.productFilter} placeholder="Mobile" /> */}

                  </div>  
                 </div>               
              <div className="tableDiv inMobileViewButton ">

              <ReactTable 
                tableHeader={this.state.tableHeader} 
                tableBody={tableBodyData}
                />
        </div>     

              <div className='row'>
                <div className='col-xl-2 mt-3'>
                 <button className='applyAndSaveButton'><Link to='/adminHome' style={{textDecoration:"none",color:"white"}}>Back</Link></button>
                </div>

              </div>
              </div>
              </div>
              </div>   

                      {/*  Edit Start Model */}

        <div id="exampleModal">
          <Modal
            size="md"
            show={this.state.show}
            onHide={() => this.editCustomerDetails()}
          >
            <Modal.Header closeButton>
              <Modal.Title>Edit Customer Details </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="container">
                <div className="row">
                  <div className="col-md-6">
                    <label>Name</label>
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Name"
                        name="customerName"
                        value={this.state.customerName}
                        onChange={this.handleChangeAll}
                      />
                    
                  </div>

                  <div className="col-md-6">
                    <label>Email</label>
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Email"
                        name="customerEmail"
                        value={this.state.customerEmail}
                        onChange={this.handleChangeAll}
                      />
                  </div>
                </div>

                <div className="row updateModel">

                  <div className="col-md-6">
                    <label>Mobile</label>
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Mobile"
                        name="customerMobile"
                        value={this.state.customerMobile}
                        onChange={this.handleChangeAll}
                      />
                  </div>

                  <div className="col-md-6">
                    <label>Alt mobile</label>
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Alt Mobile"
                        name="customerAltMobile"
                        value={this.state.customerAltMobile}
                        onChange={this.handleChangeAll}
                      />
                   
                  </div>
                </div>

                <div className="row updateModel">

                  <div className="col-md-6">
                    <div className="marginClass">
                      <label>Shop name</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Shop Name"
                        name="customerShopName"
                        value={this.state.customerShopName}
                        onChange={this.handleChangeAll}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
                <Button style={{backgroundColor:'#F39C12',borderColor:'#F39C12'}} onClick={this.editCustomerDetails}>
                   Cancel
                </Button>
                <Button onClick={this.submitModal} style={{backgroundColor:'#F39C12',borderColor:'#F39C12'}}>
                   Save
                </Button>
            </Modal.Footer>

          </Modal>
        </div>
        {/*  End Edit Model */}
   <ToastContainer />   
            </div>
        )
    }
}

const mapStateToProps = state =>
{
    return {
      customerDetails:state.customerDetailsReducer
    }
}
const mapDispatchToProps = {
  saveCustomerDetails:saveCustomerDetails
}
export default  connect(mapStateToProps,mapDispatchToProps)(TotalEmployees);