import React, {Component} from 'react';
import axios from 'axios';
import Header from './Header';
import SideBar from './SideBar';
import './../Customer/css/HomeCustomer.css';
import SideBarMobile from './sidebar/Sidebar';
import {Redirect,Link} from 'react-router-dom';
import WSppiner from '../../common/WSppiner';
import currencyFormatter from 'currency-formatter';
import ChartComponent from '../Charts/ChartComponent';
import { lineChartOption } from './ChartOption';
import swal from 'sweetalert';
import { TECHNICAL_ERROR_MESSAGE } from '../Customer/Constant/Constant';
import { convertNumberToMonth, createChartOption } from '../../Utils/Utils';
export class adminHome extends Component {


    constructor(props)
    {
        super(props);

        this.state = {
            totalCustomer:0,
            totalCredit:0,
            checkLoginStatus : true, 
            loading:false,
            chartOption:{
                chart: {
                  width: 900,
                  type: 'pie',
                },
                colors: ['#0B6623', '#ffa500', '#800000'],
                labels: ['Received', 'GST', 'Credit'],
                fill: {
                    colors: ['#0B6623', '#ffa500', '#800000']
                  }
                ,
                legend: { 
                    show:true,
                    position: 'top',
                    markers: {
                        width: 12,
                        height: 12,
                        strokeWidth: 0,
                        strokeColor: '#fff',
                        fillColors: ['#0B6623', '#ffa500', '#800000'],
                        radius: 12,
                        customHTML: undefined,
                        onClick: undefined,
                        offsetX: 0,
                        offsetY: 0
                    },
                },
                tooltip: {
                    fillSeriesColor: true,
                }

              },
            seriesData:[],
            lineChartData:[],
            lineChartOptionForSellCount:[],
            lineChartDataForSellAmount:[],
            lineChartOptionForSellAmount:[],
            lineChartDataForRecivedAmount:[],
            lineChartOptionReceivedAmount:[],
            lineChartDataForDuesAmount:[],
            lineChartOptionDuesAmount:[],
            paymentDetailsMonthWiseAmount:[],
            paymentDetailsChartOptionMonthWiseAmount:[]

        }

        let token  = JSON.parse(sessionStorage.getItem("loginDetails"));

        if(token ==  null || token == undefined || token == "")
        {
            
            this.state.checkLoginStatus = false;
        }
    }

    componentDidMount()
    {
        this.setState({
            loading:true
        })
        this.totalCustomer()
    }

    totalCustomer = ()=>{

        axios.post(`${process.env.REACT_APP_API_URL_BACKEND_ENDPOINT}/totalCustomer`,{},
        {headers : {'x-access-token' : localStorage.getItem("accessToken")}})
        .then(response=>{
            let lineChartData = [];
            let lineChartMonthInfo = [];
            let lineChartDataForSellAmount=[];
            let lineChartDataForRecivedAmount=[];
            let lineChartDataForDuesAmount=[];
            let paymentDetailsMonthWise=[];
            for(let i=0;i<response.data?.monthDataList?.length;i++)
            {
                const monthName = convertNumberToMonth(parseInt(response.data?.monthDataList[i]?.monthName));
                lineChartMonthInfo.push(monthName);
                lineChartData.push(response.data?.monthDataList[i]?.countInfo)
                lineChartDataForSellAmount.push(response.data?.monthDataList[i]?.totalSellAmount)
                lineChartDataForRecivedAmount.push(response.data?.monthDataList[i]?.totalRecivedAcount)
                lineChartDataForDuesAmount.push(response.data?.monthDataList[i]?.totalDuesAcount)
                paymentDetailsMonthWise.push(response.data?.paymentDetailsMonthWise[i]?.receivedAmount);
            }

            this.setState({
            loading:false,
            totalCustomer:response.data.totalCustomer,
            totalCredit:response.data.totalCredit,
            seriesData:[response.data.totalReceived,response.data.totalGst,response.data.totalCredit],
            lineChartData:[
                {
                  name: "No of Sell - Last 1 year",
                  data: lineChartData
                }],
             lineChartOptionForSellCount:createChartOption(lineChartOption,lineChartData,lineChartMonthInfo,'No of selles on every month',3),
             lineChartDataForSellAmount:[
                {
                    name: "Sell amount - Last 1 year",
                    data: lineChartDataForSellAmount
                }],
                lineChartOptionForSellAmount :createChartOption(lineChartOption,lineChartDataForSellAmount,lineChartMonthInfo,'Sell on every month',1000),
                lineChartDataForRecivedAmount:[
                    {
                        name: "Received amount - Last 1 year",
                        data: lineChartDataForRecivedAmount
                    }
                ],
                lineChartOptionReceivedAmount:createChartOption(lineChartOption,lineChartDataForRecivedAmount,lineChartMonthInfo,'Payment received on sell (month)',1000),
                lineChartDataForDuesAmount:[
                    {
                        name: "Dues amount - Last 1 year",
                        data: lineChartDataForDuesAmount
                    }
                ],
                lineChartOptionDuesAmount:createChartOption(lineChartOption,lineChartDataForDuesAmount,lineChartMonthInfo,'Payment dues on sell (month)',1000),

                paymentDetailsMonthWiseAmount:[
                    {
                        name: "Payment amount - Last 1 year",
                        data: paymentDetailsMonthWise
                    }
                ],
                paymentDetailsChartOptionMonthWiseAmount:createChartOption(lineChartOption,paymentDetailsMonthWise,lineChartMonthInfo,'Payment received on months',1000),
                })
                
       })
        .catch(error=>{

            swal({
                title: "Confirmed",
                text: TECHNICAL_ERROR_MESSAGE,
                icon: "error",
                button: "Close",
            }); 
            this.setState({
                loading:false
            })

        })
    }

    
   
    render(){

        if(this.state.checkLoginStatus ===  false)
        {
            return <Redirect  to="/adminlogin" />
        }

        const {chartOption,seriesData,lineChartData,lineChartDataForSellAmount,
            lineChartDataForRecivedAmount,lineChartOptionReceivedAmount,lineChartOptionForSellAmount,
            lineChartDataForDuesAmount,lineChartOptionDuesAmount,lineChartOptionForSellCount,
            paymentDetailsMonthWiseAmount,paymentDetailsChartOptionMonthWiseAmount} = this.state;

        return(

            <div>
                {this.state.loading && 
                
                <WSppiner isLoading={this.state.loading}/>
                
                
                }

                <div className="headerBarTop">

                <Header />
                </div>

                <div className="mobileViewSidebar">
                <SideBarMobile />
                </div>

                <div className="container-fluid">
                <div className="row">
                <div className="col-xl-2 col-md-12 col-sm-12 sideBARColor">

                <SideBar />

                </div> 

              <div className="col-xl-10 col-md-12 col-sm-12  mainBar">


                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-3 col-md-12 col-sm-12">

                            <div className="allDiveHere">

                            <Link to="/TotalCustomer"  style={{textDecoration : "none" , color : "orange" ,fontSize : "30px",marginTop : "5px"}}>Total Customer</Link>

                            <h3>{this.state.totalCustomer}</h3>
                            </div>
                       

                        </div>

                        <div className="col-xl-3 col-md-12 col-sm-12">

                            <div className="allDiveHere">

                            <Link  style={{textDecoration : "none" , color : "orange" ,fontSize : "30px",marginTop : "5px"}}>Total Credit</Link>

                            <h3>{currencyFormatter.format(this.state.totalCredit, {
                                                                locale: 'en-IN',
                                                            })   }</h3>
                            </div>


                        </div>
                    </div>


                    <div className='row'>
                        <div className='col-xl-4 col-lg-4 col-md-12 col-sm-12'>

                        {(lineChartData?.length > 0) && 
                            <div className='chartDesign'>
                            <ChartComponent 
                                    options={chartOption}
                                    series={seriesData}
                                    chartType={'pie'}
                                    chartHeight={(window.innerWidth < 400)? 320:270}
                                />
                            </div>}
                               
                        </div>

                        <div className='col-xl-4 col-lg-4 col-md-12 col-sm-12'>

                        {(lineChartData?.length > 0) && 
                            <div className='chartDesign'>
                            <ChartComponent 
                                    options={lineChartOptionForSellAmount}
                                    series={lineChartDataForSellAmount}
                                    chartType={'area'}
                                    chartHeight={(window.innerWidth < 400)? 300:250}
                                />
                            </div>}
                          
                               
                        </div>

                        <div className='col-xl-4 col-lg-4 col-md-12 col-sm-12'>

                        {(lineChartData?.length > 0) && 
                            <div className='chartDesign'>
                            <ChartComponent 
                                    options={lineChartOptionForSellCount}
                                    series={lineChartData}
                                    chartType={'bar'}
                                    chartHeight={(window.innerWidth < 400)? 300:250}
                                />
                            </div>}
                               
                        </div>

                    </div>


                    {/* Second row for chart */}

                    <div className='row'>
                        <div className='col-xl-4 col-lg-4 col-md-12 col-sm-12'>

                        {(lineChartData?.length > 0) && 
                            <div className='chartDesign'>
                            <ChartComponent 
                                    options={lineChartOptionReceivedAmount}
                                    series={lineChartDataForRecivedAmount}
                                    chartType={'area'}
                                    chartHeight={(window.innerWidth < 400)? 300:250}
                                />
                            </div>}
                               
                        </div>

                        <div className='col-xl-4 col-lg-4 col-md-12 col-sm-12'>

                        {(lineChartData?.length > 0) && 
                            <div className='chartDesign'>
                            <ChartComponent 
                                    options={lineChartOptionDuesAmount}
                                    series={lineChartDataForDuesAmount}
                                    chartType={'area'}
                                    chartHeight={(window.innerWidth < 400)? 300:250}
                                />
                            </div>}
                          
                               
                        </div>

                        <div className='col-xl-4 col-lg-4 col-md-12 col-sm-12'>

                        {(lineChartData?.length > 0) && 
                            <div className='chartDesign'>
                            <ChartComponent 
                                    options={paymentDetailsChartOptionMonthWiseAmount}
                                    series={paymentDetailsMonthWiseAmount}
                                    chartType={'area'}
                                    chartHeight={(window.innerWidth < 400)? 300:250}
                                />
                            </div>}
                               
                        </div>

                    </div>

                </div>

              </div>


              </div>
              </div>



            </div>
        )
    }
}



export default adminHome;
