import { Carousel } from "react-bootstrap"
import crousel1 from './Images/crousel1.jpg';
import crousel2 from './Images/crousel2.jpg';
import crousel3 from './Images/crousel3.jpg';


const CarouselItem = (props)=>
{
   // console.log(props);
    return (
        <div>
            
        </div>
    )
}

export default CarouselItem