import * as ActionTypes from '../Constants/ServicesConstant';


const initialState = {
    adminDetails :null,
    employeeDetails:null
}

function LoginReducer(state = initialState,action)
{
    switch(action.type)
    {
        case ActionTypes.ADMIN_DETAILS:{
            return{
                ...state,
                adminDetails:action.data
            }
        }

        case ActionTypes.EMPLOYESS_DETAILS:{
            return{
                ...state,
                employeeDetails:action.data
            }
        }
        default:
            return state;
    }
}


export default LoginReducer;