import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import RegisterForm from './moduls/ClientFolder/RegisterForm';
import UserLogin from './moduls/ClientFolder/UserLogin';
import ShopDetailsStepperForm from './moduls/ClientFolder/ShopDetailsStepperForm';
import ProductCategory from './moduls/HomePage/ProductCategory';
import Home from './moduls/ClientFolder/Home';
import AddColor from './moduls/ClientFolder/AddColors';
import AddSize from './moduls/ClientFolder/AddSize';
import AddCompany from './moduls/ClientFolder/AddCompany';
import AddNewProduct from './moduls/ClientFolder/NewProduct';
import AddProduct from './moduls/ClientFolder/AddProduct';
import ShowAllProduct from './moduls/ClientFolder/ShowAllProduct';
import Profile from './moduls/ClientFolder/Profile';
import NewOrder from './moduls/ClientFolder/NewOder';
import GenrateInvoice from './moduls/ClientFolder/GenrateInvoice';
import MyOrders from './moduls/Customer/MyOrders';
import OrderMoreDetails from './moduls/Customer/OrderMoreDetails';
import CustomerProfile from './moduls/Customer/CustomerProfile';
import SellerForgotPassword from './moduls/ClientFolder/ForgotPassword';
import SellerChangePassword from './moduls/ClientFolder/ChangePassword';
import OrderHistory from './moduls/ClientFolder/OrderHistory';
import ReturnOrderInfo from './moduls/ClientFolder/ReturnOrderInfo';
import HistoryMoreDetail from './moduls/ClientFolder/HistoryMoreDetail';
import ReturnMoreDetail from './moduls/ClientFolder/ReturnMoreDetails';
import AllCustomerInfo from './moduls/ClientFolder/AllCustomerInfo';
import ProductInfo from './moduls/Customer/ProductInfo';
import OrderStatus from './moduls/ClientFolder/OrderStatus';
import ShowProductSliderInfo from './moduls/Customer/ShowProductSliderInfo';
/// Home Page 
import HomeCustomer from './moduls/HomePage/HomeCustomer';
import ContactUS from './moduls/HomePage/ContactUS.js';
import AboutUSComponent from './moduls/HomePage/AboutUS.js';
import PrivacyPolicy from './moduls/HomePage/PrivacyPolicy.js';
import CancellationPolicy from './moduls/HomePage/CancellationPolicy';
/// Customer 
import SearchShop from './moduls/Customer/SearchShop';
import ShowShopProduct from './moduls/Customer/ShowShopProduct';
import CustomerRegistration from './moduls/Customer/RegisterForm';
import CustomerLogin from './moduls/Customer/UserLogin';
import CustomerCart from './moduls/Customer/CustomerCart';
import CustomerWishlist from './moduls/Customer/CustomerWishlist';
import CheckOut from './moduls/Customer/CheckOut';
import NewOrderDetails from './moduls/ClientFolder/NewOrderDetails';
import ShowInvoice from './moduls/ClientFolder/ShowInvoice';
import RozePay from './moduls/Customer/RozePay';
import Logout from './moduls/Customer/Logout';
import ReturnPolicy from './moduls/Customer/ReturnPolicy';
import TermCondition from './moduls/Customer/TermCondition';
import VerifyCustomerEmail from './moduls/Customer/VerifyEmail';
import ForgotCustomerPassword from './moduls/Customer/ForgotPassword';
import CustomerChnagePassword from './moduls/Customer/ChangePassword';
import BuyNowCheckOut from './moduls/Customer/BuyNowCheckOut';
import OrderConfirmed from './moduls/Customer/OrderConfirmed'
// Delivery Boy 
import DeliveryProfile from './moduls/Deliveryboy/DeliveryProfile';
import MoreOrderDetails from './moduls/Deliveryboy/MoreOrderDetails';
import HistoryMoreOrderDetails from './moduls/Deliveryboy/HistoryMoreOrderDetails';
import DeliveryBoyRegistration from './moduls/Deliveryboy/RegisterForm';
import DelivertBoyLogin from './moduls/Deliveryboy/UserLogin';
import Deliveryordermoredetails from './moduls/Deliveryboy/ordermoredetails';
import DeliveryHistoryOrderDetails from './moduls/Deliveryboy/HistoryOrderDetails';
import DeliveryBoyHome from './moduls/Deliveryboy/DeliveryBoyHome';
import DeliveryBoyForgetPassword from './moduls/Deliveryboy/ForgotPassword';
import DeliveryBoyChangePassword from './moduls/Deliveryboy/ChangePassword';
//Admin 
import AdminLogin from './moduls/Admin/Adminlogin';
import adminHome from './moduls/Admin/adminHome';
import TotalCustomer from './moduls/Admin/TotalCustomer';
import SellerDetails from './moduls/Admin/SellerDetails';
import TotalDeliveryBoy from './moduls/Admin/TotalDeliveryBoy';
import AdminProfile from './moduls/Admin/AdminProfile';
import AdminRegistration from './moduls/Admin/AdminRegistration';
import TransactionDetails from './moduls/Admin/TransactionDetails';

// this route adds information to product slider
import AddToProductSlider from './moduls/ClientFolder/AddToProductSlider';
import ShowSliderData from './moduls/ClientFolder/ShowSliderData';
//supportAdmin
import SupportAdminLogin from './moduls/SupportAdmin/UserLogin';
//import ShopDetailsStepperForm from './moduls/SupportAdmin/ShopDetailsStepperForm';
//import ProductCategory from './moduls/HomePage/ProductCategory';
import SupportAdminHome from './moduls/SupportAdmin/Home';
import SupportAdminAddColor from './moduls/SupportAdmin/AddColors';
import SupportAdminAddSize from './moduls/SupportAdmin/AddSize';
import SupportAdminAddCompany from './moduls/SupportAdmin/AddCompany';
import SupportAdminAddNewProduct from './moduls/SupportAdmin/NewProduct';
import SupportAdminAddProduct from './moduls/SupportAdmin/AddProduct';
import ShowSupportAdminAllProduct from './moduls/SupportAdmin/ShowAllProduct';
import SupportAdminProfile from './moduls/SupportAdmin/Profile';
import SupportAdminNewOrder from './moduls/SupportAdmin/NewOder';
import SupportAdminGenrateInvoice from './moduls/SupportAdmin/GenrateInvoice';
import SupportAdminMyOrders from './moduls/Customer/MyOrders';
import SupportAdminOrderMoreDetails from './moduls/Customer/OrderMoreDetails';
import SupportAdminCustomerProfile from './moduls/Customer/CustomerProfile';
import SupportAdminForgotPassword from './moduls/SupportAdmin/ForgotPassword';
import SupportAdminChangePassword from './moduls/SupportAdmin/ChangePassword';
import SupportAdminOrderHistory from './moduls/SupportAdmin/OrderHistory';
import SupportAdminReturnOrderInfo from './moduls/SupportAdmin/ReturnOrderInfo';
import SupportAdminHistoryMoreDetail from './moduls/SupportAdmin/HistoryMoreDetail';
import SupportAdminReturnMoreDetail from './moduls/SupportAdmin/ReturnMoreDetails';
import SupportAdminAllCustomerInfo from './moduls/SupportAdmin/AllCustomerInfo';
//import ProductInfo from './moduls/Customer/ProductInfo';
//import ShowProductSliderInfo from './moduls/Customer/ShowProductSliderInfo';
import SupportAdminAddToProductSlider from './moduls/SupportAdmin/AddToProductSlider';
import SupportAdminShowSliderData from './moduls/SupportAdmin/ShowSliderData';

// Employees 
import EmployeeLogin from './moduls/Employess/EmployeeLogin.js';
import EmployeeHome from './moduls/Employess/EmployeeHome.js';
import CreateRequest from './moduls/Employess/CreateRequest';
import EmployessTotalCustomer from './moduls/Employess/TotalCustomer';
import EmployessTransaction from './moduls/Employess/TransactionDetails';
import TotalTransactions from './moduls/Admin/Transactions';
import CreateEmployess from './moduls/Admin/CreateEmployess';
import TotalEmployess from './moduls/Admin/TotalEmployess';

import AdminStock from './moduls/Admin/AdminStock';


function App() {
  return (
    <BrowserRouter>
      <div className="App">

        <Switch>
        
          <Route path="/" exact component={EmployeeLogin} ></Route>
         
          <Route path="/Logout" exact component={Logout} />

          <Route path="/AdminLogin" exact component={AdminLogin} />
          <Route path="/adminHome" exact component={adminHome} />
          <Route path="/TotalCustomer" exact component={TotalCustomer} />
          <Route path="/TransactionDetails" exact component={TransactionDetails} />

          <Route path="/ProductStock" exact component={AdminStock} />
         

          <Route path="/AdminProfile" exact component={AdminProfile} />
          <Route path="/createCustomer" exact component={AdminRegistration} />
          <Route path="/SupportAdminLogin" exact component={SupportAdminLogin} />
          <Route path="/SupportAdminHome" exact component={SupportAdminHome} />
          <Route path="/SupportAdminProfile" exact component={SupportAdminProfile} />
          <Route path="/ShowSupportAdminAllProduct" exact component={ShowSupportAdminAllProduct} />

          {/* Employee Route */}

          <Route path="/employeeLogin" exact component={EmployeeLogin} />
          <Route path="/employeeHome" exact component={EmployeeHome} />
          <Route path="/employeeTotalCustomer" exact component={EmployessTotalCustomer} />
          <Route path="/employeeTransaction" exact component={EmployessTransaction} />
          <Route path="/createRequest" exact component={CreateRequest} />


          <Route path="/totalTransaction" exact component={TotalTransactions} />
          

          <Route path="/createEmployess" exact component={CreateEmployess} />
          <Route path="/viewEmployees" exact component={TotalEmployess} />
        </Switch>
      </div>
    </BrowserRouter>
  );
}

export default App;
