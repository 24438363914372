
// correct .. edit here



import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import swal from 'sweetalert';
import { TECHNICAL_ERROR_MESSAGE } from '../Customer/Constant/Constant';
import axios from 'axios';
import Header from '../ClientFolder/Header';
import SideBar from './SideBar';
import '../ClientFolder/css/Home.css';
import WSppiner from '../../common/WSppiner';
import SideBarMobile from './sidebar/Sidebar';
import { Redirect, Link } from 'react-router-dom';
import ConfirmationModal from '../../common/ConfirmationModal';
class DeliveryBoyHome extends Component {
    constructor(props) {
        super(props);

        this.state = {

            totalNewOder: 0,
            checkLoginStatus: true,
            returnOrder: 0,
            totalOrder: 0,
            totalCustomer: 0,
            show: false,
            redirect: false
        }

        let token = sessionStorage.getItem("deliveryBoyEmail");

        if (token == null || token == undefined || token == "") {

            this.state.checkLoginStatus = false;
        }
    }
    componentDidMount() {

        if (this.state.checkLoginStatus == true) {
            let ID = sessionStorage.getItem("deliveryBoyId");
            
            this.setState({ isLoading: true });
            axios.post(`${process.env.REACT_APP_API_URL_BACKEND_CALL}/getDeliveryOrders`, { deliveryBoyId: ID }
                , { headers: { 'x-access-token': localStorage.getItem("accessToken") } })
                .then(response => {
                    this.setState({ isLoading: false });

                    if (response.data != []) {
                        this.setState({ totalNewOder: response.data.length });
                    }
                    else{
                        this.setState({ totalNewOder: 0 });
                    }


                })
                .catch(error => {
                    this.setState({ isLoading: false });
                    swal({
                        title: "Oh snap! You got an error!!",
                        text: TECHNICAL_ERROR_MESSAGE,
                        icon: "error",
                        button: "Close",
                    });

                })
                axios.post(`${process.env.REACT_APP_API_URL_BACKEND_CALL}/getDeliveryOrdersHistory`, { deliveryBoyId: ID }
                , { headers: { 'x-access-token': localStorage.getItem("accessToken") } })
                .then(response => {
                    this.setState({ isLoading: false });

                    if (response.data != []) {
                        this.setState({ totalOrder: response.data.length });
                    }
                    else{
                        this.setState({ totalOrder: 0 });
                    }


                })
                .catch(error => {
                    this.setState({ isLoading: false });
                    swal({
                        title: "Oh snap! You got an error!!",
                        text: TECHNICAL_ERROR_MESSAGE,
                        icon: "error",
                        button: "Close",
                    });

                })
        }
    }

    render() {

        if (this.state.checkLoginStatus === false) {
            return <Redirect to="/DeliveryBoyLogin" />
        }



        return (

            <div>
                {this.state.isLoading && <WSppiner isLoading={this.state.isLoading} />}
                <div className="headerBarTop">
                    {this.state.show &&
                        <ConfirmationModal handleProceedButton={this.redirectToForm} header={' Enter The Shop Details'} message={'You need to submit shop details to continue...'} />}
                    <Header />
                </div>
                <div className="mobileViewSidebar">
                    <SideBarMobile />
                </div>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-2 col-md-12 col-sm-12 sideBARColor">
                            <SideBar />
                        </div>
                        <div className="col-xl-10 col-md-12 col-sm-12  mainBar">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-xl-6 col-md-12 col-sm-12">
                                        <div className="allDiveHere">
                                            <Link to="/Deliveryordermoredetails" style={{ textDecoration: "none", color: "orange", fontSize: "30px", marginTop: "5px" }}>New Delivery</Link>
                                            <h3>{this.state.totalNewOder}</h3>
                                        </div>
                                    </div>
                                    <div className="col-xl-6 col-md-12 col-sm-12">
                                        <div className="allDiveHere">
                                            <Link to="/DeliveryHistoryOrderDetails" style={{ textDecoration: "none", color: "orange", fontSize: "30px", marginTop: "5px" }}>Delivery History</Link>
                                            <h3>{this.state.totalOrder}</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}



export default DeliveryBoyHome;