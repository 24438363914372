import react, { Component } from "react";
import './css/Header.css';
import companyLogo from './Images/rainbow.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell} from '@fortawesome/free-solid-svg-icons';

import { Nav, NavDropdown, Navbar } from "react-bootstrap";
import "./css/Header.css";
import rainbowImage from "../ClientFolder/Images/rainbow.png";
import { Link, Redirect } from "react-router-dom";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loggdIn: false,
    };
  }

  componentDidMount = () => {
    let token = sessionStorage.getItem("customerEmail");
    // console.log(token);
    if (token != null) {
      // console.log("call done")
      this.setState({ loggdIn: true });
    }
  };

  logoutFun = () => {
    this.setState({ loggdIn: false });

    sessionStorage.clear();
    localStorage.clear();
    return <Redirect to="/" />;
  };

 

  render() {
    // if (this.state.loggdIn === false) {

    //     return <Redirect to="/" />
    // }

    return(
      <div className="container-fluid">
      <div className="row">
        <div className="col-xl-2 col-md-12 col-sm-12 topSide">

          <div className="row">

            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
          
              <img src={companyLogo} className="LogoImage" />


            </div>            
          </div>

          
        </div>

        <div className="col-xl-10 col-md-12 col-sm-12 topBar">

          <div className="row">
            <div className="col-xl-8 col-md-12 col-sm-12">

           

            </div>

            <div className="col-xl-1 col-lg-1 col-md-12 col-sm-12">
           
            </div>

          </div>
          
        </div>
      </div>
    </div>
  )
  }
}

export default Header;
