import * as ActionTyes from '../Constants/ServicesConstant';

const initialState = {
    customerDetails:null
}



function customerDetailsReducer(state = initialState,action)
{
    switch(action.type)
    {
        case ActionTyes.CUSTOMER_DETAILS:{
            return{
                ...state,
                customerDetails:action.data
            }
        }

        case ActionTyes.SAVE_PURCHASE_DETAILS:{
            return{
                ...state,
                purchaseDetails:action.data
            }
        }
        default:
           return state
    }
}

export default customerDetailsReducer;