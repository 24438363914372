import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import AddIcon from '../../assets/add.png'
import axios from 'axios';
import Header from './Header';
import SideBar from './SideBar';
import Select from 'react-select';
import '../ClientFolder/css/Home.css';
import { Redirect, Link } from 'react-router-dom';
import { Button, Card, Modal } from 'react-bootstrap';
import edit from '../ClientFolder/Images/pencil.svg';
import deleteImage from '../ClientFolder/Images/delete.svg';
import filters from './Filters';
import ReactPaginate from 'react-paginate';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SideBarMobile from './sidebar/Sidebar';
import ReactTable from '../../common/ReactTable';
import moreDetailsImage from '../../assets/moreDatails.png';
import editIcon from '../../assets/edit.png';
import viewIcon from '../../assets/eye.png';
import { connect } from 'react-redux';
import PurchaseModal from '../../common/PurchaseModal/PurchaseModal';
import { savePurchaseDetails } from '../../Services/Actions/CustomerDetailsAction';
import Moment from 'react-moment';
import currencyFormatter from 'currency-formatter';
//import 'moment-timezone';
import moment from 'moment';
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import companyLogo from '../../assets/Begave.jpg';
import ConfirmationModal from '../../common/ConfirmationModal';
import swal from 'sweetalert';
import { TECHNICAL_ERROR_MESSAGE } from '../Customer/Constant/Constant';
import deleteIcon from '../../assets/trash.png';
import WSppiner from '../../common/WSppiner';
import { billStatusFilter } from '../../common/CommonConstant/CommonConstant';
import { createChartOption } from '../../Utils/Utils';
import ChartComponent from '../Charts/ChartComponent';
import transactionHistory from '../../assets/money.png';

class TransactionDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      checkLoginStatus: true,
      show: false,
      offset: 0,
      purchaseModal:false,
      tableData: [],
      perPage: 10,
      currentPage: 0,
      data: [],
      f: { pCompanyName: [], productName: [], pCategory: [], pColor: [], pSize: [] },

      tableHeader: [
        "Sl. No",
        "Bill-No",
        "Amount",
        "GST",
        "Received",
        "Dues",
        "Purchase Date",
        "Status",
        "View Details",
        "Edit",
        "Delete",
        "More Details"
      ],
      tableShowMore:[
        "S.No",
        "Item",
        "Category",
        "Type",
        "Size",
        "Quantity",
        "Rate",
        "Amount",
      ],
      searchData: "",
      redirectTransactionFlow: false,
      customerId: 0,
      itemList:[],
      totalAmount:0,
      gstAmount:0,
      gstButtonText:'Apply 5% GST',
      gstButtonStatus:true,
      amountReceived:0,
      dueAmount:0,
      showMoreDetailStatus:false,
      showMoreDetailsItemList:[],
      purchaseDetailsInfo:[],
      totalDetails:null,
      amountError:"",
      showPaymentModal:false,
      paymentList:[],
      purchaseInfoObj:null,
      billStatusModel:false,
      billMessage:"",
      secondaryButtonClickForBillStatus:false,
      deleteConfirmationModel:false,
      deletedPurchaseData:null,
      loading:false,
      defaultData:[],
      stockDetails:[],
      itemListForDelete:[],
      chartOption:{
        chart: {
        //  width: 400,
          type: 'pie',
        },
        colors: ['#0B6623', '#800000'],
        labels: ['Received', 'Credit'],
        fill: {
            colors: ['#0B6623', '#800000']
          }
        ,
        grid: {
         
          padding: {
              top: 0,
              right: 0,
              bottom: 0,
              left: 0
          },  
      }
        ,
        legend: { 
            show:true,
            position: 'left',
            markers: {
                width: 5,
                height: 5,
                strokeWidth: 0,
                strokeColor: '#fff',
                fillColors: ['#0B6623', '#800000'],
                //radius: 12,
                customHTML: undefined,
                onClick: undefined,
                offsetX: 0,
                offsetY: 0,
            },
        },
        tooltip: {
            fillSeriesColor: true,
        },
        parentHeightOffset:0
      },
      seriesData:[],
      showAllPaymentTransactionHistoryModal:false,
      allPaymentHistoryList:[]
    }

    let token = JSON.parse(sessionStorage.getItem("loginDetails"));

    if (token == null || token == undefined || token == "") {

      this.state.checkLoginStatus = false;
    }

  }


  componentDidMount(){
    this.getTransactionDetails();
    this.getStockDetails();
  }


  handleChange1 = (selectedOptions) => {

    let a = { ...this.state.f };
    a.pCompanyName = selectedOptions;

    let b = [];
    this.setState({ f: a, filterArray: [...b], temp1: [...b], temp: [...b], category: [...b] }, this.method);

  }



  handleChangeAll = (event) => {

    this.setState({ [event.target.name]: event.target.value });

  }

  calculateTotals = (purchaseItemList)=>
  {
    let totalAmount=0;
    let totalRecived=0;
    let totalDues = 0;
    let totalGST = 0;
    for(let i=0;i<purchaseItemList.length;i++)
    {
      totalAmount = totalAmount+ parseInt(purchaseItemList[i]?.Amount);
      totalRecived = totalRecived+ parseInt(purchaseItemList[i]?.receivedAmount);
      totalGST = totalGST+ parseInt(purchaseItemList[i]?.gstAmount);
    }
    totalDues = (totalAmount +totalGST)  - totalRecived;
    totalAmount  = totalAmount + totalGST;
    return {
      totalAmount,
      totalRecived,
      totalDues,
      totalGST
    };
  }

  deleteData = (userID) => {
    let formData = new FormData();
    formData.append("proID", userID);

    axios.post('https://rainbowbackendapi.dev-gnnxtech.com/deleteProduct', formData)
      .then((response) => {


        if (response.data === "deleteDone") {

          toast.success("Product deleted !!!", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });

          setTimeout(function () {


          }, 3000)

          this.componentDidMount();

        }


      }).catch(function (error) {

        alert(error);
      })

  }

  // For pagination 
  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState({
      currentPage: selectedPage,
      offset: offset
    }, () => {
      this.loadMoreData()
    });
  };

  handlePurchaseModal=()=>
  {
    this.setState({purchaseModal:!this.state.purchaseModal,itemList:[],gstAmount:0,amountReceived:0})
  }

  

  handleDropdownChange=(selectedOption,index,inputType)=>
  {
    let itemObj = this.state.itemList;
    itemObj[index][`${inputType}`] = selectedOption.value;
    this.setState({itemList:itemObj})
  }
 
  handleAddItem=()=>
  {
    const newitemObj = {
      item:'',
      category:'',
      quantity:'',
      size:"",
      rate:'',
      amount:'',
      type:'',
    }
    let itemList = this.state.itemList
    itemList.push(newitemObj)
    this.setState({itemList:itemList})
  }

  handleChange=(event,index,inputType)=>
  {
    let itemObj = this.state.itemList;
    itemObj[index][`${inputType}`] = event.target.value;
    if(this.state.itemList[index].quantity!="" && this.state.itemList[index].rate!="")
    {
      itemObj[index]['amount'] = (parseInt(this.state.itemList[index].quantity)*(this.state.itemList[index].rate))
    }
    this.setState({itemList:itemObj,totalAmount:this.calculateTotalAmount(itemObj),dueAmount: this.calculateTotalDues(this.calculateTotalAmount(itemObj))})
  }

  calculateTotalAmount=(purchaseItemList)=>
  {
    let totalAmount=0;
    for(let i=0;i<purchaseItemList.length;i++)
    {
      totalAmount = totalAmount+ parseInt(purchaseItemList[i].amount);
    }
    return totalAmount;
  }

  calculateTotalDues = (totalAmount) =>
  {
    let totalDues = (parseInt(totalAmount) + parseInt(this.state.gstAmount) ) - parseInt(this.state.amountReceived);
    return totalDues;
  }

  handleRemove=(removedIndex)=>
  {
    let tempArr=[]
   for(let i=0;i<this.state.itemList.length;i++)
   {
    if(removedIndex!=i)
    {
      tempArr.push(this.state.itemList[i])
    }
   }
    this.setState({itemList:tempArr})
  }


  calculateTotalOfEachCategory = (data,stockInfo,action) =>
  {
      let total = 0;
      for(let i=0;i<data?.length;i++)
      {
        total = total + parseInt(data[i].quantity)
      }

      if(action == 'add')
      {
        return {
          psid:stockInfo.psid,
          total:(parseInt(stockInfo.Quantity) - total)
        };
      }
      else
      {
        return {
          psid:stockInfo.psid,
          total:(parseInt(stockInfo.Quantity) + total)
        };
      }
  }


  calculateUpdatedStock = (itemList,stockList,action="add")=>
  {
      let filterRecors=[];
      for(let i=0;i<stockList?.length;i++)
      {
           const temData = itemList?.filter((data)=>(data.item == stockList[i].item && data.category == stockList[i].Category && data.type == stockList[i].Type));
           if(temData?.length > 0)
           {
              const resultObj = this.calculateTotalOfEachCategory(temData,stockList[i],action);
              filterRecors.push(resultObj)
           }
      }
      return filterRecors;
  }

  handlePurchaseList=()=>
  {
      const {customerDetailsData} = this.props;

      if(this.state?.itemList?.length > 0 && this.state.totalAmount > 0)
      {
            const stockDetails = this.calculateUpdatedStock(this.state?.itemList,this.state.stockDetails,'add');
            const payload = {
              customerId:customerDetailsData?.customerDetails?.customerId,
              purchaseItemList:this.state.itemList,
              totalAmount:this.state.totalAmount,
              amountReceived:this.state.amountReceived,
              gstAmount:this.state.gstAmount,
              sellerID:  `admin_${JSON.parse(sessionStorage.getItem('loginDetails'))?.adminName}_${JSON.parse(sessionStorage.getItem('loginDetails'))?.adminId}`,
              stockDetails:stockDetails
          }

          axios.post(`${process.env.REACT_APP_API_URL_BACKEND_ENDPOINT}/addPurchaseItem`,payload,
          {headers : {'x-access-token' : localStorage.getItem("accessToken")}})
          .then((response)=>
          {
              this.getTransactionDetails();
              this.getStockDetails();
              if(response.data.message=="success")
              {
                this.setState({purchaseModal:false,itemList:[],gstAmount:0,amountReceived:0})
                  swal({
                    title: "Confirmed",
                    text: 'Purchased successfully',
                    icon: "success",
                    button: "Close",
                });  
              }
              else
              {
                this.setState({purchaseModal:false,itemList:[],gstAmount:0,amountReceived:0})
                swal({
                  title: "Confirmed",
                  text: 'Purchase not done',
                  icon: "error",
                  button: "Close",
              });  
              }
          }).catch((error)=>
          {
              swal({
                title: "Confirmed",
                text: TECHNICAL_ERROR_MESSAGE,
                icon: "error",
                button: "Close",
            }); 
              this.setState({purchaseModal:false,itemList:[],gstAmount:0,amountReceived:0})
          })
      }
      else
      {

      }

    }

    handleApplyGst=()=>
    {
      let gstAmount=0;
      gstAmount=((5/100) * parseInt(this.state.totalAmount))
      this.setState({totalAmount:(this.state.totalAmount),
        gstAmount:gstAmount,gstButtonStatus:false,gstButtonText:'Remove GST',
      },()=>{

        this.setState({
          dueAmount:this.calculateTotalDues(this.state.totalAmount)
        })
      })
    }

    handleRemoveGst=()=>
    {
        this.setState({totalAmount:(this.state.totalAmount),
        gstAmount:0,gstButtonStatus:true,gstButtonText:'Apply 5% GST',
        },()=>{
          this.setState({
            dueAmount:this.calculateTotalDues(this.state.totalAmount)
          })
          //dueAmount:this.calculateTotalDues(this.state.totalAmount)
        })
    }

    handleChangeAmt=(event)=>
    {
        if(event?.target?.value == "" || event?.target?.value == 0)
        {
          this.setState({amountReceived:parseInt(event.target.value),dueAmount:((parseInt(this.state.totalAmount) + parseInt(this.state.gstAmount) ) -parseInt(event.target.value))})
        }
        else
        {
          this.setState({amountReceived:parseInt(event.target.value),dueAmount:((parseInt(this.state.totalAmount) + parseInt(this.state.gstAmount) ) -parseInt(event.target.value))})
        }
    }


    showMoreDetail=(purchaseDetails,callType="default")=>
    {
      const {customerDetailsData,savePurchaseDetails} = this.props;
      savePurchaseDetails(purchaseDetails)
      const payload = {
        customerId:customerDetailsData?.customerDetails?.customerId,
        purchaseId:purchaseDetails.purchaseId
      }
      
      this.showPaymentDetails(purchaseDetails,'Normal')
      axios.post(`${process.env.REACT_APP_API_URL_BACKEND_ENDPOINT}/showMoreDetail`,payload,
      {headers : {'x-access-token' : localStorage.getItem("accessToken")}}).
      then((response)=>
      {
        if(response.data.message == "success")
        {
          if(callType == "default")
          {
            this.setState({showMoreDetailStatus:true,showMoreDetailsItemList:response.data.itemList,
              purchaseDetailsInfo :this.calculateBillDetails(purchaseDetails) })
          }
          else
          {
            this.setState({showMoreDetailsItemList:response.data.itemList,
              purchaseDetailsInfo :purchaseDetails})
          }
          
        }
      }).catch((error)=>
      {
        console.log("err",error)
      })
    }


    showProductDetailsForDelete=()=>
    {
      const {customerDetailsData,savePurchaseDetails} = this.props;
      const purchaseDetails = this.state.deletedPurchaseData;
      savePurchaseDetails(purchaseDetails)
      const payload = {
        customerId:customerDetailsData?.customerDetails?.customerId,
        purchaseId:purchaseDetails.purchaseId
      }
      
      axios.post(`${process.env.REACT_APP_API_URL_BACKEND_ENDPOINT}/showMoreDetail`,payload,
      {headers : {'x-access-token' : localStorage.getItem("accessToken")}}).
      then((response)=>
      {
        if(response.data.message == "success")
        {
            this.setState({
              itemListForDelete:response.data.itemList
            },()=>{
                const stockDetails = this.calculateUpdatedStock(this.state?.itemListForDelete,this.state.stockDetails,'minus');
                this.deletePaymentDetails(stockDetails);
            })
        }
      }).catch((error)=>
      {
        console.log("err",error)
      })
    }



    cancelTransactionDetails = (index)=>
    {
        let tempObj = this.state.tableData;
        tempObj[index].editFlag = false;
        this.setState({
          tableData:tempObj,
          amountError:""
        })
    }


    editTransactionDetails = (index)=>
    {
        let filterArray = this.state.tableData.filter((item)=> item.editFlag == true);

        if(filterArray?.length > 0)
        {

        }
        else
        {
          let tempObj = this.state.tableData;
          tempObj[index].editFlag = true;
          this.setState({
            tableData:tempObj
          })
        }
    }

    amountHandleChange = (e,amount,receivedAmount)=>
    {
      if(parseInt(amount) < (parseInt(e.target.value) + parseInt(receivedAmount)))
      {
          this.setState({
            amountError:"Receive amount less then or equal to amount"
          })
      }
      else
      {
        this.setState({
          amountError:""
        })
      }
     
    }

    saveTransactionDetails = (index,details)=>
    {
        const inputValue = document.getElementById('editAmout').value;

        const newAmount = (parseInt(inputValue) + parseInt(details?.receivedAmount))
        const payload = {
            receivedAmount: newAmount,
            purchaseId:details?.purchaseId,
            addAmount:parseInt(inputValue)
        }

        if(parseInt(details?.Amount) >= (parseInt(inputValue) + parseInt(details?.receivedAmount)))
        {
            let tempObj = this.state.tableData;
            tempObj[index].editFlag = false;
            axios.post(`${process.env.REACT_APP_API_URL_BACKEND_ENDPOINT}/editTransactionDetails`,payload,
            {headers : {'x-access-token' : localStorage.getItem("accessToken")}})
            .then((resonse)=>{
                this.getTransactionDetails()
                this.setState({
                  tableData:tempObj
                }) 
            })
            .catch((error)=>{
    
            })
        }
        else
        {

        }
    }

    handleBack = ()=>
    {
      this.setState({
        showMoreDetailStatus: false
      })
    }

  showPaymentDetails = (purchaseObj,callType = "default")=>
  {
      const payload = {
        purchaseId:purchaseObj?.purchaseId
      }

      if(callType=="default")
      {
        this.showMoreDetail(purchaseObj,'payment')
      }
      axios.post(`${process.env.REACT_APP_API_URL_BACKEND_ENDPOINT}/showPaymentDetails`,payload,
      {headers : {'x-access-token' : localStorage.getItem("accessToken")}})
      .then((response)=>{

          if(response.data?.itemList?.length > 0)
          {
              if(callType == "default")
              {
                this.setState({paymentList:response.data?.itemList,showPaymentModal:true,purchaseInfoObj:purchaseObj});
              }
              else
              {
                this.setState({paymentList:response.data?.itemList,purchaseInfoObj:purchaseObj});
              }
              
          }
      })
      .catch((error)=>
      {
          
      })
  }

  handleCloseModal = ()=>
  {
      this.setState({showPaymentModal:false})
  }

  generateInvoice = (data)=>
  {
    const {purchaseDetailsInfo,totalDetails} = this.state;
    const {customerDetailsData,savePurchaseDetails} = this.props;
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape
    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    let cgst_percent = 2.5; 
    let sgst_percent = 2.5; 
    const d = new Date();
    
    const todayDate = d.getDate()+"/"+(d.getMonth() +1 )+"/"+d.getFullYear();

    doc.setFontSize(15);
    doc.addImage(companyLogo,40,8,150,35);
    doc.setFontSize(30);
    doc.text(250, 35, "Invoice");
    doc.setFontSize(14);
    doc.text(420, 35, `Date: ${todayDate}`);
    doc.line(20, 45, 580, 45);

    // Company Details
    doc.setFontSize(14);
    doc.text("From:", 40, 65);
    doc.setFontSize(12);
    doc.text(`BeGave Textile`, 40, 78);
    doc.text(`Mobile: 8235859732`, 40, 91);
    doc.text("Address: Road no:23,Rajeev Nagar ", 40, 104);
    doc.text(`Patna,Bihar`, 40, 117);
    doc.text(`India (800024)`, 40, 130);

     
    // Customer details 
    doc.setFontSize(14);
    doc.text("Bill To:", 250, 65);
    doc.setFontSize(12);
    doc.text(`Shop name: ${customerDetailsData?.customerDetails?.shopName}`, 250, 78);
    doc.text(`Customer name: ${customerDetailsData?.customerDetails?.customerName}`, 250, 91);
    doc.text(`Customer mobile: ${customerDetailsData?.customerDetails?.customerMobile}`, 250, 104);
    doc.text(`Customer address: ${customerDetailsData?.customerDetails?.locality}, ${customerDetailsData?.customerDetails?.landmark}`, 250, 117);
    doc.text(`${customerDetailsData?.customerDetails?.city}, ${customerDetailsData?.customerDetails?.state}`, 250, 130);
    doc.text(`${customerDetailsData?.customerDetails?.country}, ${customerDetailsData?.customerDetails?.pincode}`, 250, 143);

    doc.setFontSize(14)
    doc.text("Purchases details",40,165)
    doc.line(20, 173, 580, 173);
    
    const headers = [["S.no","Bill-No","Amount", "GST", "Received", "Dues","Bill Status", "Purchase Date	"]];

    let tableData = data.map((user, index) => (
        [index + 1,`00${user.purchaseId}`,(parseInt(user.Amount) + parseInt(user.gstAmount)) , user.gstAmount, user.receivedAmount, ((parseInt(user.Amount) +  parseInt(user.gstAmount))- parseInt(user.receivedAmount)),user.billStatus,moment(user.transRegDate).format('LL')]
    ))

    let content = {
        startY: 185,
        theme: 'grid',
        head: headers,
        body: tableData
    };
    doc.autoTable(content);

    let finalY = doc.lastAutoTable.finalY;
    let pageCount = doc.internal.getNumberOfPages();

    if ((finalY + 230) < doc.getPageHeight()) {
        doc.setPage(pageCount)
        doc.setFontSize(12);
        doc.setLineWidth(1);
        doc.line(20, finalY + 20, 580, finalY + 20);
        doc.text(`No Of Bills: ${data?.length}`, 350, finalY + 40);
        doc.text(`Total Amount: ${totalDetails?.totalAmount - totalDetails?.totalGST
        }`, 350, finalY + 60);

        doc.setLineWidth(1);
        doc.text(`CGST - 2.5% : ${(parseInt(totalDetails?.totalGST)/2)}` , 350 , finalY + 80)
        doc.text(`SGST - 2.5% : ${(parseInt(totalDetails?.totalGST)/2)}` , 350 , finalY + 100)
        doc.text(`Grand Total: ${totalDetails?.totalAmount}`,350, finalY + 120);
        doc.text(`Received Amount: ${totalDetails?.totalRecived}`, 350, finalY + 140);
        doc.text(`Total Dues: ${totalDetails?.totalDues}`, 350, finalY + 160);
 
        doc.line(20, finalY + 170, 580, finalY + 170);
        doc.text("Authorized Signatory", 430, finalY + 185);
        doc.text("BeGave Textile", 445, finalY + 205);
        doc.addImage(companyLogo, 450, finalY + 220, 70, 20);
        doc.text("Thank You", 450, finalY + 250);

    }
    else {
        doc.addPage();
        doc.setFontSize(12);
        doc.setLineWidth(1);
        doc.line(20, finalY + 20, 580, finalY + 20);
        doc.text(`No Of Bills: ${data?.length}`, 350, finalY + 40);
        doc.text(`Total Amount: ${totalDetails?.totalAmount}`, 350, finalY + 60);

        doc.setLineWidth(1);
        doc.text(`CGST - 2.5% : ${(parseInt(totalDetails?.totalGST)/2)}` , 350 , finalY + 80)
        doc.text(`SGST - 2.5% : ${(parseInt(totalDetails?.totalGST)/2)}` , 350 , finalY + 100)
        doc.text(`Grand Total: ${totalDetails?.totalAmount}`,350, finalY + 120);
        doc.text(`Received Amount: ${totalDetails?.totalRecived}`, 350, finalY + 140);
        doc.text(`Total Dues: ${totalDetails?.totalDues}`, 350, finalY + 160);
 
        doc.line(20, finalY + 170, 580, finalY + 170);
        doc.text("Authorized Signatory", 430, finalY + 190);
        doc.addImage(companyLogo, 450, finalY + 200, 70, 20);
        doc.text("Thank You", 450, finalY + 230);

    }


    doc.save(`${customerDetailsData?.customerDetails?.shopName}_${customerDetailsData?.customerDetails?.customerName}.pdf`)

  }

  downloadPurchaseDetailsWithPayment = (data="")=>
  {
      const {purchaseDetailsInfo,totalDetails,purchaseInfoObj} = this.state;
      const {customerDetailsData,savePurchaseDetails} = this.props;
      const unit = "pt";
      const size = "A4"; // Use A1, A2, A3 or A4
      const orientation = "portrait"; // portrait or landscape
      const marginLeft = 40;
      const doc = new jsPDF(orientation, unit, size);

      let cgst_percent = 2.5; 
      let sgst_percent = 2.5; 
      const d = new Date();
      
      const todayDate = d.getDate()+"/"+(d.getMonth() +1 )+"/"+d.getFullYear();

      doc.setFontSize(15);
      doc.addImage(companyLogo,40,8,150,35);
      doc.setFontSize(14);
      doc.text(250, 35,`Invoice-No:00${purchaseInfoObj.purchaseId}`);
      doc.setFontSize(14);
      doc.text(420, 35, `Date: ${todayDate}`);
      doc.line(20, 45, 580, 45);

      // Company Details
      doc.setFontSize(14);
      doc.text("From:", 40, 65);
      doc.setFontSize(12);
      doc.text(`BeGave Textile`, 40, 78);
      doc.text(`Mobile: 8235859732`, 40, 91);
      doc.text("Address: Road no:23,Rajeev Nagar ", 40, 104);
      doc.text(`Patna,Bihar`, 40, 117);
      doc.text(`India (800024)`, 40, 130);

      
      // Customer details 
      doc.setFontSize(14);
      doc.text(`Bill To:`, 250, 65);
      doc.setFontSize(12);
      doc.text(`Shop name: ${customerDetailsData?.customerDetails?.shopName}`, 250, 78);
      doc.text(`Customer name: ${customerDetailsData?.customerDetails?.customerName}`, 250, 91);
      doc.text(`Customer mobile: ${customerDetailsData?.customerDetails?.customerMobile}`, 250, 104);
      doc.text(`Customer address: ${customerDetailsData?.customerDetails?.locality}, ${customerDetailsData?.customerDetails?.landmark}`, 250, 117);
      doc.text(`${customerDetailsData?.customerDetails?.city}, ${customerDetailsData?.customerDetails?.state}`, 250, 130);
      doc.text(`${customerDetailsData?.customerDetails?.country}, ${customerDetailsData?.customerDetails?.pincode}`, 250, 143);

      doc.setFontSize(14)
      doc.text(`Bill Date: ${moment(this.state.purchaseInfoObj?.transRegDate).format('LL')}`,40,165)
      doc.text(`Items:`,40,182)
      doc.line(20, 187, 580, 187);
      
      const headers = [["S.no", "Item", "Category","Size", "Type", "Quantity","Rate", "Amount"]];

      let tableData = this.state?.showMoreDetailsItemList?.map((user, index) => (
          [index + 1, user.item, user.category ,user.Size, user.type, user.quantity, user.Rate,user.Amount]
      ))

      let content = {
          startY: 200,
          theme: 'grid',
          head: headers,
          headStyles: { fillColor: [116, 167, 247],halign : 'left'},
          body: tableData
      };
      doc.autoTable(content);

      let finalY = doc.lastAutoTable.finalY;

      
      if((finalY + 300 ) < doc.getPageHeight())
      {
        doc.line(20, (finalY+30), 580, (finalY+30));
        doc.text('Payment details',40,(finalY + 60))
        const paymentHeaders = [["S.no", "Received amount", "Payment date"]];

        let paymentTableData = this.state?.paymentList?.map((user, index) => (
            [index + 1, user.receivedAmount, moment(user.paymentDate).format('LL')]
        ))
  
        let paymentContent = {
            startY: (finalY + 70),
            theme: 'grid',
            headStyles: { fillColor: [116, 167, 247],halign : 'left'},
            head: paymentHeaders,
            body: paymentTableData
        };
        doc.autoTable(paymentContent);

        let paymentFinalY = doc.lastAutoTable.finalY;

        if((paymentFinalY + 300 ) < doc.getPageHeight())
        {
            const {purchaseInfoObj} = this.state;
            let pageCount = doc.internal.getNumberOfPages();
            doc.setPage(pageCount)
            doc.setFontSize(12);
            doc.setLineWidth(1);
            doc.line(20, paymentFinalY + 20, 580, paymentFinalY + 20);
            doc.text(`Total Amount: ${purchaseInfoObj?.Amount
            }`, 350, paymentFinalY + 40);
    
            doc.setLineWidth(1);
            doc.text(`CGST - 2.5% : ${(parseInt(purchaseInfoObj?.gstAmount)/2)}` , 350 , paymentFinalY + 60)
            doc.text(`SGST - 2.5% : ${(parseInt(purchaseInfoObj?.gstAmount)/2)}` , 350 , paymentFinalY + 80)
            doc.text(`Grand Total: ${(parseInt(purchaseInfoObj?.Amount) + parseInt(purchaseInfoObj?.gstAmount))}`,350, paymentFinalY + 100);
            doc.text(`Received Amount: ${purchaseInfoObj?.receivedAmount}`,350, paymentFinalY + 120)
            doc.text(`Total Dues: ${(parseInt(purchaseInfoObj?.Amount) + parseInt(purchaseInfoObj?.gstAmount) ) - (parseInt(purchaseInfoObj?.receivedAmount))}`, 350, paymentFinalY + 140);
    
            doc.line(20, paymentFinalY + 170, 580, paymentFinalY + 170);
            doc.text("Authorized Signatory", 430, paymentFinalY + 185);
            doc.text("BeGave Textile",450,paymentFinalY + 205)
            doc.addImage(companyLogo, 450, paymentFinalY + 215, 70, 20);
            doc.text("Thank You", 450, paymentFinalY + 250);
          }
      }

      let pageCount = doc.internal.getNumberOfPages();

      doc.text('Note: Goods ones sold not be refundable, Only Exchange within three days from sold date.',20,830)

      if ((finalY + 230) < doc.getPageHeight()) {
          // doc.setPage(pageCount)
          // doc.setFontSize(14);
          // doc.setLineWidth(1);
          // doc.line(20, finalY + 20, 580, finalY + 20);
          // doc.text(`Total Amount: ${totalDetails?.totalAmount - totalDetails?.totalGST
          // }`, 350, finalY + 60);

          // doc.setLineWidth(1);
          // doc.text(`CGST - 2.5% : ${(parseInt(totalDetails?.totalGST)/2)}` , 350 , finalY + 80)
          // doc.text(`SGST - 2.5% : ${(parseInt(totalDetails?.totalGST)/2)}` , 350 , finalY + 100)
          // doc.text(`Grand Total: ${totalDetails?.totalAmount}`,350, finalY + 120);

          // doc.text(`Total Dues: ${totalDetails?.totalDues}`, 350, finalY + 140);
  
          // doc.line(20, finalY + 170, 580, finalY + 170);
          // doc.text("Authorized Signatory", 430, finalY + 180);
          // doc.addImage(companyLogo, 450, finalY + 200, 70, 20);
          // doc.text("Thank You", 450, finalY + 230);

      }
      else {
          // doc.addPage();
          // doc.setFontSize(10);
          // doc.setLineWidth(1);
          // doc.line(20, finalY + 20, 580, finalY + 20);
          // doc.text(`Total Amount: ${totalDetails?.totalAmount}`, 350, finalY + 60);

          // doc.setLineWidth(1);
          // doc.text(`CGST - 2.5% : ${(parseInt(totalDetails?.totalGST)/2)}` , 350 , finalY + 80)
          // doc.text(`SGST - 2.5% : ${(parseInt(totalDetails?.totalGST)/2)}` , 350 , finalY + 100)
          // doc.text(`Grand Total: ${totalDetails?.totalAmount}`,350, finalY + 120);

          // doc.text(`Total Dues: ${totalDetails?.totalDues}`, 350, finalY + 140);
  
          // doc.line(20, finalY + 170, 580, finalY + 170);
          // doc.text("Authorized Signatory", 430, finalY + 185);
          // doc.addImage(companyLogo, 450, finalY + 200, 70, 20);
          // doc.text("Thank You", 450, finalY + 230);

      }


      doc.save(`${customerDetailsData?.customerDetails?.shopName}_${customerDetailsData?.customerDetails?.customerName}.pdf`)

  } 

  handleBillStatus = (details)=>
  {
      const purchaseDetails = this.calculateBillDetails(details);
      if(purchaseDetails?.totalAmount > purchaseDetails?.totalRecived)
      {
        this.setState({billStatusModel:!this.state.billStatusModel,
          purchaseInfoObj:details,
          billMessage:`You can't close this bill because bill amount ${purchaseDetails?.totalAmount } greater then received amount ${purchaseDetails?.totalRecived}.`,
          secondaryButtonClickForBillStatus:false})
      }
      else
      {
        this.setState({billStatusModel:!this.state.billStatusModel,purchaseInfoObj:details,
          billMessage:"If you want to close this bill, Then press yes button.",secondaryButtonClickForBillStatus:true})
      }
      
  }

  handleDeleteConfirmationModel = (details="")=>
  {
      this.setState({
        deleteConfirmationModel:!this.state.deleteConfirmationModel,
        deletedPurchaseData:details
      })
  }


  calculateBillDetails = (details)=>
  {
    let totalAmount=(parseInt(details?.Amount) + parseInt(details?.gstAmount))
    let totalRecived=parseInt(details?.receivedAmount);
    let totalDues = ((parseInt(details?.Amount) + parseInt(details?.gstAmount)) - parseInt(details?.receivedAmount));
    let totalGST = parseInt(details?.gstAmount)

    return {
      totalAmount,
      totalRecived,
      totalDues,
      totalGST
    };
  }

  billCloseRequest = ()=>
  {
      const {customerDetailsData,savePurchaseDetails} = this.props;
      const payload = {
        purchaseId:this.state.purchaseInfoObj?.purchaseId,
        customerID:customerDetailsData?.customerDetails?.customerId
      }
      axios.post(`${process.env.REACT_APP_API_URL_BACKEND_ENDPOINT}/billStatus`,payload,
      {headers : {'x-access-token' : localStorage.getItem("accessToken")}})
      .then((response)=>{

          if(response?.data?.message == "updateSuccess")
          {
              this.getTransactionDetails();
              this.setState({billStatusModel:false})
          }
          else
          {
            this.setState({billStatusModel:false})
          }
          
      })
      .catch((error)=>
      {
        this.setState({billStatusModel:false})
      })
  }

  getTransactionDetails = (filterStatus = '')=>
  {
    const {customerDetailsData} = this.props;
    const payload =  {
      customerId:customerDetailsData?.customerDetails?.customerId
    }
    
    this.setState({loading:true})
    axios.post(`${process.env.REACT_APP_API_URL_BACKEND_ENDPOINT}/customerTransactionDetail`,payload,
    {headers : {'x-access-token' : localStorage.getItem("accessToken")}})
      .then((response) => {

        if(response.data?.length > 0)
        {
          const tempData = this.applyBillStatusFilter('Open',response.data);
          this.setState({defaultData:response.data, tableData: tempData,totalDetails: this.calculateTotals(tempData),loading:false,
          seriesData: [this.calculateTotals(tempData)?.totalRecived,this.calculateTotals(tempData)?.totalDues]});
        }
        else
        {
          this.setState({loading:false,tableData:[],totalDetails:null});
        }
      }).catch(function (error) {

        alert(error);
        this.setState({loading:false})
      })
  }


  applyBillStatusFilter = (type,data)=>
  {
      if(data?.length > 0 && type !="All")
      {
          return data?.filter((item)=> item.billStatus == type)
      } 
      else if(type == "All")
      {
        return this.state.defaultData;
      }
      else
      {
         return data;
      }

  }

  deletePaymentDetails = (stockDetails)=>
  {
      const payload =  {
        purchaseId:this.state.deletedPurchaseData?.purchaseId,
        stockDetails:stockDetails
      }
      this.setState({loading:true})
      axios.post(`${process.env.REACT_APP_API_URL_BACKEND_ENDPOINT}/deletePaymentDetails`,payload,
      {headers : {'x-access-token' : localStorage.getItem("accessToken")}})
        .then((response) => {
  
          if(response.data.message == "success")
          {
            this.getTransactionDetails();
            this.getStockDetails();
            this.setState({ deleteConfirmationModel :false})
            swal({
              title: "Confirmed",
              text: 'Purchase removed successfully',
              icon: "success",
              button: "Close",
          }); 
          }
          else
          {
            this.setState({ deleteConfirmationModel :false,loading:false})
          }
        }).catch(function (error) {

          this.setState({ deleteConfirmationModel :false,loading:false})
            swal({
              title: "Confirmed",
              text: TECHNICAL_ERROR_MESSAGE,
              icon: "error",
              button: "Close",
          }); 
        })
  }

  handleBillStatusFilter = (event) =>
  {
    const tempData = this.applyBillStatusFilter(event.value,this.state.defaultData)
    this.setState({ tableData: tempData,totalDetails: this.calculateTotals(tempData),loading:false,
    seriesData: [this.calculateTotals(tempData)?.totalRecived,this.calculateTotals(tempData)?.totalDues]});
  } 
  
  getStockDetails = () =>
  {
      axios.post(`${process.env.REACT_APP_API_URL_BACKEND_ENDPOINT}/totalStockInfo`,{},
      {headers : {'x-access-token' : localStorage.getItem("accessToken")}})
      .then((response)=>{

          if(response.data?.stockList?.length > 0)
          {
              this.setState({
                 stockDetails:response.data?.stockList,
                 loading:false
              })
          }
      })
      .catch((error)=>{

          swal({
            title: "Confirmed",
            text: TECHNICAL_ERROR_MESSAGE,
            icon: "error",
            button: "Close",
        }); 
        this.setState({
            loading:false
        })
      })
  }

  getAllPaymentHistory = ()=>
  {
    const {customerDetailsData} = this.props;
    const payload =  {
      customerId:customerDetailsData?.customerDetails?.customerId
    }
    this.setState({loading:true})
    axios.post(`${process.env.REACT_APP_API_URL_BACKEND_ENDPOINT}/getAllPaymentTransactionHistory`,payload,
    {headers : {'x-access-token' : localStorage.getItem("accessToken")}})
    .then((response)=>{

        if(response.data?.paymentHistoryList?.length > 0)
        {
            this.setState({
              allPaymentHistoryList :response.data?.paymentHistoryList,
              showAllPaymentTransactionHistoryModal:true,
               loading:false
            })
        }
    })
    .catch((error)=>{

        swal({
          title: "Confirmed",
          text: TECHNICAL_ERROR_MESSAGE,
          icon: "error",
          button: "Close",
      }); 
      this.setState({
          loading:false
      })
    })
  }

  downloadPaymentHistory = () =>
  {
      const {customerDetailsData} = this.props;
      const unit = "pt";
      const size = "A4"; // Use A1, A2, A3 or A4
      const orientation = "portrait"; // portrait or landscape
      const doc = new jsPDF(orientation, unit, size);
      const d = new Date();
      
      const todayDate = d.getDate()+"/"+(d.getMonth() +1 )+"/"+d.getFullYear();

      doc.setFontSize(15);
      doc.addImage(companyLogo,40,8,150,35);
      doc.setFontSize(30);
      doc.text(250, 35, "Invoice");
      doc.setFontSize(14);
      doc.text(420, 35, `Date: ${todayDate}`);
      doc.line(20, 45, 580, 45);

      // Company Details
      doc.setFontSize(10);
      doc.text("From:", 40, 55);
      doc.setFontSize(10);
      doc.text(`BeGave Textile`, 40, 65);
      doc.text(`Mobile: 8235859732`, 40, 76);
      doc.text("Address: Road no:23,Rajeev Nagar ", 40, 85);
      doc.text(`Patna,Bihar`, 40, 93);
      doc.text(`India (800024)`, 40, 102);

      
      // Customer details 
      doc.setFontSize(10);
      doc.text(`Bill To:`, 250, 55);
      doc.setFontSize(10);
      doc.text(`Shop name: ${customerDetailsData?.customerDetails?.shopName}`, 250, 65);
      doc.text(`Customer name: ${customerDetailsData?.customerDetails?.customerName}`, 250, 76);
      doc.text(`Customer mobile: ${customerDetailsData?.customerDetails?.customerMobile}`, 250, 85);
      doc.text(`Customer address: ${customerDetailsData?.customerDetails?.locality}, ${customerDetailsData?.customerDetails?.landmark}`, 250, 93);
      doc.text(`${customerDetailsData?.customerDetails?.city}, ${customerDetailsData?.customerDetails?.state}`, 250, 102);
      doc.text(`${customerDetailsData?.customerDetails?.country}, ${customerDetailsData?.customerDetails?.pincode}`, 250, 112);

      doc.setFontSize(14)
      doc.text(`Records:`,40,118)
     // doc.line(20, 110, 580, 110);
      
      const headers = [["S.no","Bill-No","Received amount", "Payment date"]];

      let tableData = this.state?.allPaymentHistoryList?.map((user, index) => (
          [index + 1,`00${user.purchaseId}`,user.receivedAmount, moment(user.paymentDate).format('LL')]
      ))

      let content = {
          startY: 125,
          theme: 'grid',
          head: headers,
          headStyles: { fillColor: [116, 167, 247],halign : 'left'},
          body: tableData
      };
      doc.autoTable(content);

      doc.save(`${customerDetailsData?.customerDetails?.shopName}_${customerDetailsData?.customerDetails?.customerName}.pdf`);
  }

  render() {

    const {purchaseDetailsInfo,totalDetails,billStatusModel} = this.state;
    if (this.state.checkLoginStatus === false) {
      return <Redirect to="/adminLogin" />
    }

    if (this.state.redirectTransactionFlow == true) {
      return <Redirect to={`/TransactionDetails/${this.state.customerId}`} />
    }

    let tableBodyData = []
    if (this.state.tableData.length > 0) {
      tableBodyData = this.state.tableData?.filter((val) => {
        if (this.state.searchData == "") {
          return val
        } else if (val.customerName.toLowerCase().includes(this.state.searchData.toLowerCase())) {
          return val
        } else if (val.customerMobile.toLowerCase().includes(this.state.searchData.toLowerCase())) {
          return val
        } else if (val.shopName.toLowerCase().includes(this.state.searchData.toLowerCase())) {
          return val
        }


      }).map((user, index) => {

        return <tr key={index}>
          <td>{index + 1}</td>
          <td>{`00${user.purchaseId}`}</td>
          <td>{(parseInt(user.Amount) + parseInt(user.gstAmount))}</td>
          <td>{user.gstAmount}</td>
          <td>{(user.editFlag == true) ? <>
            {user.receivedAmount}
            &nbsp;<input type='number' onChange={(e)=>{this.amountHandleChange(e,user?.Amount,user?.receivedAmount)}} id='editAmout' placeholder='add amount' />
            {(this.state.amountError !="" ) && <p style={{color:"red",fontSize:"80%"}}>{this.state.amountError}</p>}
          </> : <>{user.receivedAmount}</>}</td>
          <td>{((parseInt(user.Amount) +  parseInt(user.gstAmount))- parseInt(user.receivedAmount))}</td>
         
          <td>{moment(user.transRegDate).format('LL')}</td>
          <td>{(user.billStatus == "Open") ? <button className='btn btn-danger' onClick={()=>{this.handleBillStatus(user)}}>{user.billStatus}</button> : <p style={{color:"green",fontWeight:"bold"}}>{user.billStatus}</p>}</td>
          <td><img src={viewIcon} style={{height:"20px",width:"20px",cursor:"pointer"}} onClick={()=>{this.showPaymentDetails(user)}} /></td>
          <td>
          <img src={editIcon} onClick={()=>{this.editTransactionDetails(index)}} style={{height:"20px",width:"20px",cursor:"pointer"}} />
          {(user.editFlag == true && this.state.amountError =="") && <button className='btn btn-primary' onClick={()=>{this.saveTransactionDetails(index,user)}}>Save</button> }
          {(user.editFlag == true) && <> &nbsp;<button className='btn btn-primary' onClick={()=>{this.cancelTransactionDetails(index)}}>Cancel</button></> }
          </td>
          <td><img src={deleteIcon}  onClick={()=>{this.handleDeleteConfirmationModel(user)}} style={{height:"20px",width:"20px",cursor:"pointer"}} /></td>
          <td><img src={moreDetailsImage} onClick={()=>{this.showMoreDetail(user)}} style={{height:"20px",width:"20px",cursor:"pointer"}} /></td>
        </tr>
      })
    }
//For show more details
let tableShowMoreData = []
if (this.state.showMoreDetailsItemList?.length > 0) {
  tableShowMoreData=this.state.showMoreDetailsItemList.map((user, index) => {
    return <tr key={index}>
      <td>{index + 1}</td>
      <td>{user.item}</td>
      <td>{user.category}</td>
      <td>{user.type}</td>
      <td>{user.Size}</td>
      <td>{user.quantity}</td>
      <td>{user.Rate}</td>
      <td>{user.Amount}</td>
    </tr>
  })
}

// For Preview Product List 

//For show more details
let tablePreviewProductList = []
if (this.state.itemList?.length > 0) {
  tablePreviewProductList=this.state.itemList.map((user, index) => {
    return <tr key={index}>
      <td>{index + 1}</td>
      <td>{user.item}</td>
      <td>{user.category}</td>
      <td>{user.type}</td>
      <td>{user.size}</td>
      <td>{user.quantity}</td>
      <td>{user.rate}</td>
      <td>{user.amount}</td>
    </tr>
  })
}



    const { selectedOption,chartOption,seriesData } = this.state;
    const {customerDetails} = this.props?.customerDetailsData;
   
    return (

      <div>

        <div className="headerBarTop">

          <Header />
        </div>

        <div className="mobileViewSidebar">
          <SideBarMobile />
        </div>
        {this.state.loading && 
          <WSppiner isLoading={this.state.loading}/>
          }

        {this.state.purchaseModal && 
        <PurchaseModal 
        show={this.state.purchaseModal}
        itemList={this.state.itemList}
        handleAddItem={this.handleAddItem}
        handlePurchaseModal={this.handlePurchaseModal}
        handleChange={this.handleChange}
        handleDropdownChange={this.handleDropdownChange}
        handleRemove={this.handleRemove}
        handlePurchaseList={this.handlePurchaseList}
        totalAmount={this.state.totalAmount}
        handleApplyGst={this.handleApplyGst}
        handleRemoveGst={this.handleRemoveGst}
        gstAmount={this.state.gstAmount}
        gstButtonText={this.state.gstButtonText}
        gstButtonStatus={this.state.gstButtonStatus}
        handleChangeAmt={this.handleChangeAmt}
        dueAmount={this.state.dueAmount}
        productPreviewTableHeader={this.state.tableShowMore}
        productPreviewTableBody={tablePreviewProductList}
        />
        }

        {(this.state?.billStatusModel == true) && 
          <ConfirmationModal 
              handlePrimaryClick={this.handleBillStatus}
              handleSecondaryClick={this.billCloseRequest}
              header={"Bill Status"}
              message={this.state.billMessage}
              primaryButtonText={"Cancel"}
              secondaryButtonText={(this.state.secondaryButtonClickForBillStatus == true) ? "Close bill":""}

          />
        }

        {(this.state?.deleteConfirmationModel == true) && 
          <ConfirmationModal 
              handlePrimaryClick={this.showProductDetailsForDelete}
              handleSecondaryClick={this.handleDeleteConfirmationModel}
              header={"Delete Confirmation"}
              message={"If you want to delete purchase transaction press submit button"}
              primaryButtonText={"Submit"}
              secondaryButtonText={"Cancel"}

          />
        }
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-2 col-md-12 col-sm-12 sideBARColor">

              <SideBar />

            </div>

            <div className="col-xl-10 col-md-12 col-sm-12  mainBar">
              <div className='container-fluid'>

              <div className='row'>

              {(this.state.showMoreDetailStatus == false) && 

                <div className='col-xl-4 col-lg-4 col-md-2 col-sm-2'>
                <h2 style={{textAlign:"left"}}>Transaction Details <span><img src={AddIcon} style={{height:'6%',width:"6%",cursor:"pointer"}} onClick={this.handlePurchaseModal}/></span></h2>    
                </div>
                
              }

              <div className='col-xl-3 col-lg-3 col-md-3 col-sm-3'>
                      <p style={{textAlign:"left",marginTop:"0%",marginBottom:"0%"}}>{customerDetails?.customerName},{customerDetails?.shopName}</p>
                      <p style={{textAlign:"left",marginTop:"0%",marginBottom:"0%"}}>{customerDetails?.customerMobile},{customerDetails?.locality},{customerDetails?.landmark},{customerDetails?.city}</p>
                </div>

              {(this.state.showMoreDetailStatus == false) && 

                <div className='col-xl-2 col-lg-2 col-md-2 col-sm-2'>
                <Select isSearchable   value={selectedOption} onChange={this.handleBillStatusFilter} options={billStatusFilter} placeholder="Bill Status" />
                </div>
                
              }

              {(this.state.showMoreDetailStatus == false) && 

              <div className='col-xl-2 col-lg-2 col-md-2 col-sm-2'>
                            <div style={{backgroundColor: 'white',borderRadius: "5%",boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"}}>
                            <ChartComponent 
                                    options={chartOption}
                                    series={seriesData}
                                    chartType={'pie'}
                                    chartHeight={(window.innerWidth < 400)? 100:100}
                                />
                            </div>
              </div>

              }

              {(this.state.showMoreDetailStatus == false) && 
                <div  className='col-xl-1 col-lg-1 col-md-1 col-sm-1'>
                <img src={transactionHistory} className='transactionHistoryIcon' onClick={this.getAllPaymentHistory}/>
                </div>
              }

              </div>
                <div className='row'>
                <div className='col-xl-12'>
              
                <div className="tableDiv inMobileViewButton " style={{marginTop:'10px'}}>
                  {this.state.showMoreDetailStatus ? (<>
                    <ReactTable 
                    tableHeader={this.state.tableShowMore} 
                    tableBody={tableShowMoreData}
                    />
                  </> ):(
                    <ReactTable 
                     tableHeader={this.state.tableHeader} 
                     tableBody={tableBodyData}
                     />
                    
                  )}  
                 
                    </div>
                </div>
                </div> 
              </div>

              {(this.state.showMoreDetailStatus == true) ? (<>
                  <div className='row' style={{marginTop:"4%",backgroundColor:"white",height:"10%"}}>
                      <div className='col-xl-2 col-xl-2 col-md-2 col-sm-2 mt-3'>

                          <button className='applyAndSaveButton' onClick={this.handleBack}>Back</button>
                      </div>

                      <div className='col-xl-2 col-xl-2 col-md-2 col-sm-2 mt-2'>
                          <p style={{textAlign:"left"}}>Total amount : <span>{currencyFormatter.format(purchaseDetailsInfo?.totalAmount, {
                                      locale: 'en-IN',
                                  })}</span></p>
                      </div>

                      <div className='col-xl-2 col-xl-2 col-md-2 col-sm-2 mt-2'>
                          <p style={{textAlign:"left"}}>Amount received : <span>{currencyFormatter.format(purchaseDetailsInfo?.totalRecived, {
                                                                locale: 'en-IN',
                                                            })}</span></p>
                      </div>

                      <div className='col-xl-2 col-xl-2 col-md-2 col-sm-2 mt-2'>
                          <p style={{textAlign:"left"}}>Dues amount : <span>{currencyFormatter.format(parseInt(purchaseDetailsInfo?.totalDues), {
                                                                locale: 'en-IN',
                                                            })}</span></p>
                      </div>

                      <div className='col-xl-2 col-xl-2 col-md-2 col-sm-2 mt-2'>
                          <p style={{textAlign:"left"}}>GST amount : <span>{currencyFormatter.format(purchaseDetailsInfo?.totalGST, {
                                                                locale: 'en-IN',
                                                            })}
                                                           </span></p>
                      </div>

                      <div className='col-xl-2 col-xl-2 col-md-2 col-sm-2 mt-3'>
                          <button className='applyAndSaveButton' onClick={this.downloadPurchaseDetailsWithPayment}>Print Invoice</button>
                      </div>
                    </div>
              </>) : (
                <div>
                <div className='row' style={{marginTop:"4%",backgroundColor:"white",height:"10%"}}>
                      <div className='col-xl-2 col-xl-2 col-md-2 col-sm-2 mt-3'>
                        <button className='applyAndSaveButton'><Link to="/TotalCustomer" style={{textDecoration:"none",color:"white"}}>Back</Link></button>
                      </div>

                      <div className='col-xl-2 col-xl-2 col-md-2 col-sm-2 mt-2'>
                          <p style={{textAlign:"left"}}>Total amount : <span>{currencyFormatter.format(totalDetails?.totalAmount, {
                                                                locale: 'en-IN',
                                                            })}
                                                            </span></p>
                      </div>

                      <div className='col-xl-2 col-xl-2 col-md-2 col-sm-2 mt-2'>
                          <p style={{textAlign:"left"}}>Amount received : <span>{currencyFormatter.format(totalDetails?.totalRecived, {
                                                                locale: 'en-IN',
                                                            })}
                                                          </span></p>
                      </div>

                      <div className='col-xl-2 col-xl-2 col-md-2 col-sm-2 mt-2'>
                          <p style={{textAlign:"left"}}>Dues amount : <span>{currencyFormatter.format(totalDetails?.totalDues, {
                                                                locale: 'en-IN',
                                                            })}
                                                            </span></p>
                      </div>

                      <div className='col-xl-2 col-xl-2 col-md-2 col-sm-2 mt-2'>
                          <p style={{textAlign:"left"}}>GST amount : <span>{currencyFormatter.format(totalDetails?.totalGST, {
                                                                locale: 'en-IN',
                                                            })}
                                                            </span></p>
                      </div>

                      <div className='col-xl-2 col-xl-2 col-md-2 col-sm-2 mt-3'>
                          <button  className='applyAndSaveButton' onClick={()=>{this.generateInvoice(this.state.tableData)}}>Print Invoice</button>   <img src={transactionHistory} className='transactionHistoryIconMobile' onClick={this.getAllPaymentHistory}/>
                      </div>
                    </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <Modal size="md" show={this.state.showPaymentModal} >
            <Modal.Header >
                <Modal.Title>Payment details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12 col-md-12 col-sm-12">
                          {this.state.paymentList?.length > 0 && <>
                            <table className='table'>
                              <thead>
                                  <tr>
                                    <td>S.no</td>
                                    <td>Received amount</td>
                                    <td>Payment Date</td>
                                  </tr>
                              </thead>
                              <tbody>
                            {this.state.paymentList?.map((item,index)=>(
                              <tr>
                                <td>{index+1}</td>
                                <td>{item.receivedAmount}</td>
                                <td>{moment(item.paymentDate).format('LL')}</td>
                              </tr>

                            ))}
                              </tbody>
                            </table>
                          </>}
                         
                        </div>
                    </div>

                    <div className='row'>
                          <div className='col-xl-4 col-md-4 col-sm-4'>
                              <p style={{width:"100%"}}>Total: {this.state.purchaseInfoObj?.Amount}</p>
                          </div>
                          <div className='col-xl-4 col-md-4 col-sm-4'>
                              <p style={{width:"100%"}}>Received: {this.state.purchaseInfoObj?.receivedAmount}</p>
                          </div>

                          <div className='col-xl-4 col-md-4 col-sm-4'>
                              <p style={{width:"100%"}}>Dues: {(parseInt(this.state.purchaseInfoObj?.Amount) + parseInt(this.state.purchaseInfoObj?.gstAmount) ) - (parseInt(this.state.purchaseInfoObj?.receivedAmount))}</p>
                          </div>

                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button style={{backgroundColor:'#F39C12',borderColor:'#F39C12'}} onClick={this.handleCloseModal}>
                   Cancel
                </Button>
                <Button onClick={this.downloadPurchaseDetailsWithPayment} style={{backgroundColor:'#F39C12',borderColor:'#F39C12'}}>
                   Print Details
                </Button>
            </Modal.Footer>
        </Modal>



        {/* Model for allPaymentTransactionHistory */}

        <Modal size="md" show={this.state.showAllPaymentTransactionHistoryModal} >
            <Modal.Header >
                <Modal.Title>Payment details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12 col-md-12 col-sm-12 tableDiv"> 
                      
                          {this.state.allPaymentHistoryList?.length > 0 && <>
                            <table className='table'>
                              <thead>
                                  <tr>
                                    <td>S.no</td>
                                    <td>Bill-No</td>
                                    <td>Received amount</td>
                                    <td>Payment Date</td>
                                  </tr>
                              </thead>
                              <tbody>
                            {this.state.allPaymentHistoryList?.map((item,index)=>(
                              <tr>
                                <td>{index+1}</td>
                                <td>{`00${item.purchaseId}`}</td>
                                <td>{item.receivedAmount}</td>
                                <td>{moment(item.paymentDate).format('LL')}</td>
                              </tr>

                            ))}
                              </tbody>
                            </table>
                          </>}
                         
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button style={{backgroundColor:'#F39C12',borderColor:'#F39C12'}} onClick={()=>{this.setState({showAllPaymentTransactionHistoryModal:false})}}>
                   Cancel
                </Button>
                <Button style={{backgroundColor:'#F39C12',borderColor:'#F39C12'}} onClick={this.downloadPaymentHistory}>
                   Download
                </Button>
            </Modal.Footer>
        </Modal>
        <ToastContainer />
      </div>
    )
  }
}


const mapStateToProps = state => {
  return {
    customerDetailsData: state.customerDetailsReducer
  }
}

const mapDispatchToProps = {
      savePurchaseDetails:savePurchaseDetails
}
export default connect(mapStateToProps, mapDispatchToProps)(TransactionDetails);