import React, { Component } from 'react';
import logo from '../ClientFolder/Images/rainbow.png';
import Select from 'react-select';
import axios from 'axios';
import swal from 'sweetalert';
import { TECHNICAL_ERROR_MESSAGE } from '../Customer/Constant/Constant';
import WSppiner from '../../common/WSppiner';
import { Redirect, Link } from 'react-router-dom';
import Header from './Header';
import SideBar from '../Admin/SideBar';
import SideBarMobile from './sidebar/Sidebar';
import { Container,Row,Col } from 'reactstrap';
import './CSS/Style.css';
import { employessRole } from '../../common/CommonConstant/CommonConstant';

export default class CreateEmployess extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            mobile: "",
            altMobile: "",
            password:"",
            locality:'',
            landmark:'',
            city:'',
            country:'',
            state:'',
            pincode:'',
            errMobileMsg:'',
            aadharNumber:"",
            houseMobile:"",
            isLoading: false,
            employessRole:""
        }
    }
    componentDidMount(){
       // document.getElementById("currentEmailID").style.display = "none";
    }
    handleSubmit = (event) => {
        event.preventDefault();
        console.log(this.state);
        let password = this.generateP();
        console.log(password);
        this.setState({ password: password });
        const payload =   {

            name: this.state.name,
            mobile: this.state.mobile,
            altMobile: this.state.altMobile,
            aadharNumber: this.state.aadharNumber,
            houseMobile: this.state.houseMobile,  
            locality:this.state.locality,
            landmark:this.state.landmark,
            city:this.state.city,
            pincode:this.state.pincode,
            state:this.state.state,
            country:this.state.country,
            employessRole:this.state.employessRole
        }
        axios.post(`${process.env.REACT_APP_API_URL_BACKEND_ENDPOINT}/createEmployess`,
            payload,
            {headers : {'x-access-token' : localStorage.getItem("accessToken")}})

            .then(response => {

                if (response.data.status === "Success") {
                    this.setState({
                        isLoading: false,
                        name:"",
                        mobile:"",
                        altMobile:"",
                        locality:"",
                        landmark:"",
                        city:"",
                        pincode:"",
                        country:"",
                        state:"",
                        employessRole:"",
                        aadharNumber:"",
                        houseMobile:""
                    }); 
                    swal({
                        title: "Confirmed",
                        text: 'Customer created successfully',
                        icon: "success",
                        button: "Close",
                    });  
                }
                else {
                    this.setState({ isLoading: false });
                    swal({
                        title: "Oh snap! You got an error!!",
                        text: TECHNICAL_ERROR_MESSAGE,
                        icon: "error",
                        button: "Close",
                    });
                }

            })
            .catch(error => {
                this.setState({ isLoading: false });
                swal({
                    title: "Oh snap! You got an error!!",
                    text: TECHNICAL_ERROR_MESSAGE,
                    icon: "error",
                    button: "Close",
                });
                console.log(error)
            })
    }

    handleMobileValidation=(mobile)=>
    {
        if(this.state.mobile.length==10)
        {
            if(this.state.mobile.charAt(0)!=9)
            {
                this.setState({errMobileMsg:'Please renter your mobile number'})
            }
            else
            {
                this.setState({errMobileMsg:''})
            }
            
        }
    }

    generateP() {
        var pass = '';
        var str = 'ABCDEFGHJKMNOPQRSTUVWXYZ' +
            'abcdefghjkmnopqrstuvwxyz0123456789@#$';

        for (let i = 1; i <= 8; i++) {
            var char = Math.floor(Math.random()
                * str.length + 1);

            pass += str.charAt(char)
        }

        return pass;
    }

    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }
    
    handleDropdownChange = (event) =>
    {
        console.log('event ',event)
        this.setState({
            employessRole:event.value
        })
    }


    render() {
        if (this.state.checkStatus === true) {
            return <Redirect to="/" />
        }
        {this.state.isLoading && <WSppiner isLoading={this.state.isLoading} />}
        const { selectedOption } = this.state;
        return (
            
            <div>
                 <div className="headerBarTop">

                <Header />
                </div>

                <div className="mobileViewSidebar">
                <SideBarMobile />
                </div>

                <div className="container-fluid">
                <div className="row">
                <div className="col-xl-2 col-md-12 col-sm-12 sideBARColor">

                <SideBar />

                </div> 

              <div className="col-xl-10 col-md-12 col-sm-12  mainBar">
              <form onSubmit={this.handleSubmit}>
              <div className='row'>

                    <h4>Create Employee</h4>
                    <div className='col-xl-6 col-lg-6'>
                    <div className="field mt-2">
                        <div className="label">Employee Name</div>
                        <input type="text" name='name' value={this.state.name} onChange={this.handleChange} className='form-control-input form-control' />
                    </div>
                    <div className="field mt-2">
                        <div className="label">Employee Mobile</div>
                        <input type="number" id='mobile' name='mobile' value={this.state.mobile} onKeyUp={this.handleMobileValidation} onChange={this.handleChange} className='form-control-input form-control' />

                    </div>
                    <div className="field mt-2">
                        <div className="label">Employee Alt Mobile</div>
                        <input type="number" id='altMobile' name='altMobile' value={this.state.altMobile} onChange={this.handleChange} className='form-control-input form-control' />

                    </div>
                   
                    <div className="field mt-2">
                        <div className="label">Employee Role</div>
                        <Select isSearchable   value={selectedOption} onChange={this.handleDropdownChange} options={employessRole} placeholder="Role" />
                    </div>
                    <div className="field mt-2">
                        <div className="label">House Mobile</div>
                        <input type="number" id='houseMobile' name='houseMobile' value={this.state.houseMobile} onChange={this.handleChange} className='form-control-input form-control' />
                    </div>

                    <div className="field mt-2">
                        <div className="label">Aadhar Number</div>
                        <input type="text" id='aadharNumber' name='aadharNumber' value={this.state.aadharNumber} onChange={this.handleChange} className='form-control-input form-control' />
                    </div>

                    <div className="field mt-2">
                        <div className="label">Id Proof</div>
                        <input type="file" id='idProof' name='idProof' value={this.state.idProof} onChange={this.handleChange} className='form-control-input form-control' />
                    </div>
									 
                    </div>


                    <div className='col-xl-6 col-lg-6'>

                    <div className="field mt-2">
                                         <div className="label">Locality</div>
                                         <input type="text" id='locality' name='locality' value={this.state.locality} onChange={this.handleChange} className='form-control-input form-control' />
                                     </div>

                                     <div className="field mt-2">
                                         <div className="label">Landmark</div>
                                         <input type="text" id='landmark' name='landmark' value={this.state.landmark} onChange={this.handleChange} className='form-control-input form-control' />
                                     </div>

                                     <div className="field mt-2">
                                         <div className="label">City</div>
                                         <input type="text" id='city' name='city' value={this.state.city} onChange={this.handleChange} className='form-control-input form-control' />
                                     </div>
                                     <div className="field mt-2">
                                         <div className="label">Pincode</div>
                                         <input type="text" id='pincode' name='pincode' value={this.state.pincode} onChange={this.handleChange} className='form-control-input form-control' />
                                     </div>
                                     <div className="field mt-2">
                                         <div className="label">State</div>
                                         <input type="text" id='state' name='state' value={this.state.state} onChange={this.handleChange} className='form-control-input form-control' />
                                     </div>

                                     <div className="field mt-2">
                                         <div className="label">Country</div>
                                         <input type="text" id='coountry' name='country' value={this.state.country} onChange={this.handleChange} className='form-control-input form-control' />
                                     </div>
                    </div>

              </div>

             
                <div className="field mt-3">
                        <input type="submit" className="OrderButtionsAddPro" id='submitButton' value="Submit" />
                    </div>

                    </form>
              </div>
              </div>
              </div>
                
            </div>
         

        )
    }
}
