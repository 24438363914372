import React from 'react';
import * as FaIcons from 'react-icons/fa';
import * as AiIcons from 'react-icons/ai';
import * as IoIcons from 'react-icons/io';
import * as RiIcons from 'react-icons/ri';
import * as GrDocumentStore from 'react-icons/gr';
import * as AiOutlineUsergroupAdd from 'react-icons/ai';
import * as RiLockPasswordLine from 'react-icons/ri';
import * as AiOutlineOrderedList from 'react-icons/ai';
import * as CgProfile from 'react-icons/cg';
import * as AiOutlineLogout from 'react-icons/ai';
import { faHome, faSignOutAlt, faTable, faUser, faUserPlus,faCartPlus,faUserAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const SidebarData = [
  {
    title: 'Home',
    path: '/adminHome',
    icon: <AiIcons.AiFillHome />
  },
  {
    title: 'Customer',
    path: '#',
    icon: <AiOutlineOrderedList.AiOutlineOrderedList />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,

    subNav: [
      {
        title: "Create",
        path: "/createCustomer",
        //elemBefore: () => <FontAwesomeIcon icon={faUserPlus} />
      },
      {
        title: "View",
        path: "/TotalCustomer",
        //elemBefore: () => <FontAwesomeIcon icon={faUserPlus} />
      },
    ]
  },  
  {
    title: 'Transaction',
    path: '/totalTransaction',
    icon: <CgProfile.CgProfile />
  },
  {
    title: 'Stock',
    path: '/ProductStock',
    icon: <CgProfile.CgProfile />
  },
  {
    title: 'Employees',
    path: '#',
    icon: <AiOutlineOrderedList.AiOutlineOrderedList />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,

    subNav: [
      {
        title: "Create",
        path: "/createEmployess",
        //elemBefore: () => <FontAwesomeIcon icon={faUserPlus} />
      },
      {
        title: "View",
        path: "/viewEmployees",
        //elemBefore: () => <FontAwesomeIcon icon={faUserPlus} />
      },
    ]
  },
  {
    title: "Logout",
    path: "/Logout",
    icon : <AiOutlineLogout.AiOutlineLogout />
  },
];
