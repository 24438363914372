import * as ActionType from '../Constants/ServicesConstant';

export const saveCustomerDetails = payload => dispatch =>
{
    dispatch({
        data:payload,
        type:ActionType.CUSTOMER_DETAILS
    })
}

export const savePurchaseDetails = payload => dispatch =>
{
    console.log("check")
    dispatch({
        data:payload,
        type:ActionType.SAVE_PURCHASE_DETAILS
    })
}