import React, { Component } from "react";
import ContactUSComponent from "../../common/ContactUS/ContactUs.js";


class ContactUS extends Component
{
    constructor(props)
    {
        super(props);

        this.state={
          
        }
    }


    render() {
        return(
            <div>
                <ContactUSComponent />
            </div>
        )
    }

}

export default ContactUS;