import React from 'react';
import * as FaIcons from 'react-icons/fa';
import * as AiIcons from 'react-icons/ai';
import * as IoIcons from 'react-icons/io';
import * as RiIcons from 'react-icons/ri';
import * as GrDocumentStore from 'react-icons/gr';
import * as AiOutlineUsergroupAdd from 'react-icons/ai';
import * as RiLockPasswordLine from 'react-icons/ri';
import * as AiOutlineOrderedList from 'react-icons/ai';
import * as CgProfile from 'react-icons/cg';
import * as AiOutlineLogout from 'react-icons/ai';
import { faHome, faSignOutAlt, faTable, faUser, faUserPlus,faCartPlus,faUserAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const SidebarData = [
  {
    title: 'Home',
    path: '/employeeHome',
    icon: <AiIcons.AiFillHome />,
  },
  // {
  //   title: 'Profile',
  //   path: '/Profile',
  //   icon: <CgProfile.CgProfile />
  // },
  {
    title: "Total Customer",
    path: "/employeeTotalCustomer",
    icon : <AiOutlineUsergroupAdd.AiOutlineUsergroupAdd />
  },
  // {
  //   title: "Change Password",
  //   itemId: "/#",
  //   icon : <RiLockPasswordLine.RiLockPasswordLine />
  // },
  {
    title: "Logout",
    path: "/employeeLogin",
    icon : <AiOutlineLogout.AiOutlineLogout />
  }
];
