// import React from 'react'

// export default function AddToProductSlider() {
//   return (
//     <div>AddToProductSlider</div>
//   )
// }

import React, { Component } from "react";
import ReactDOM from "react-dom";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import swal from "sweetalert";
import Compressor from "compressorjs";
import { Button, Card } from "react-bootstrap";
import { TECHNICAL_ERROR_MESSAGE } from "../Customer/Constant/Constant";
import axios from "axios";
import "./css/AddToProductSlider.css";
import Header from "./Header";
import SideBar from "./SideBar";
import SideBarMobile from "./sidebar/Sidebar";
import { Redirect, Link } from "react-router-dom";
import Select from "react-select";
import WSppiner from "../../common/WSppiner";
import SearchbarProduct from "../sharedModuls/searchbar/SearchbarProduct";

class AddToProductSlider extends Component {
  constructor(props) {
    super(props);

    this.state = {
      checkLoginStatus: true,
      data: [],
      companySelect: [],
      productName: "",
      companyID: "",
      resultShow: true,

      file: [null],
      fileObj: [],
      fileArray: [],
      description: "",
      imageData: [],
      imageCheck: 0,

      name: "",
      price: "",
      discount: "",
      ratings: "",

      slider_name: "",
      slider_price: "",
      slider_discount: "",
      slider_ratings: "",
      arrivals_name: "",
      arrivals_price: "",
      arrivals_discount: "",
      arrivals_ratings: "",

      isLoading: false,
      showProductSliderForm: false,
      showNewArrivalsForm: false,
      buttonDisabled: true,
    };

    let token = sessionStorage.getItem("emailID");

    if (token == null || token == undefined || token == "") {
      this.state.checkLoginStatus = false;
    }
  }

  // Submit function start here

  uploadMultipleFiles = async (e) => {
    if (this.state.imageData.length < 1) {
      console.log("can be executed");
      new Compressor(e.target.files[0], {
        quality: 0.1,
        success: (compressedResult) => {
          this.state.imageData.push(compressedResult);
        },
      });
      console.log("this is the imageData now ", this.state.imageData);

      this.state.fileObj.push(e.target.files);

      for (let i = 0; i < this.state.fileObj[0].length; i++) {
        this.state.fileArray.push(
          URL.createObjectURL(this.state.fileObj[0][i])
        );
      }

      this.setState({ file: this.state.fileArray });
    } else {
      console.log("cant upload more than 1 image");
    }
  };

  setModeFunction = (mode) => {
    console.log("hadng set mote function setting to ", mode);

    axios
      .post(
        `${process.env.REACT_APP_API_URL_BACKEND_CALL}/setModeFunction`,
        { mode: mode },
        { headers: { "x-access-token": localStorage.getItem("accessToken") } }
      )

      .then((response) => {
        if (response.data == "modeUpdated") {
          console.log("response mil gaya without any error");
          toast.success("mode update", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch((error) => {
        console.log("got error here");
      });
  };

  handleSubmitNewArrivals = () => {
    // event.preventDefault();

    var formData = new FormData();
    formData.append("productname", this.state.arrivals_name);
    formData.append("product_price", this.state.arrivals_price);
    formData.append("discount_on_product", this.state.arrivals_discount);
    formData.append("ratings", this.state.arrivals_ratings);
    formData.append(`imageData`, this.state.imageData[0]);

    console.log("adding to new arrivals ");
    axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL_BACKEND_CALL}/InformationToNewArrivals`,
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((response) => {
        console.log("response mil gaya without any error");
        toast.success("Product added to new arrivals !!!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
      .catch((error) => {
        console.log("got error here");
      });
  };
  handleSubmitHomeProductSlider = () => {
    // event.preventDefault();

    var formData = new FormData();
    formData.append("productname", this.state.slider_name);
    formData.append("product_price", this.state.slider_price);
    formData.append("discount_on_product", this.state.slider_discount);
    formData.append("ratings", this.state.slider_ratings);
    formData.append(`imageData`, this.state.imageData[0]);
    console.log("this is the image Data", this.state.imageData.length);

    console.log("sending to product slider");

    axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL_BACKEND_CALL}/newInfoToSlider`,
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((response) => {
        console.log("response mil gaya without any error");
        toast.success("Product added to best selling slider  !!!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
      .catch((error) => {
        console.log("got error here");
      });
  };

  openProductSliderForm = () => {
    this.setState({ showProductSliderForm: true });
    this.setState({ showNewArrivalsForm: false });
  };
  openNewArrivalsForm = () => {
    this.setState({ showProductSliderForm: false });
    this.setState({ showNewArrivalsForm: true });
  };

  handleChangeAll = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  imageHandler = (e) => {
    const reader = new FileReader();

    reader.onload = () => {
      if (reader.readyState === 2) {
        this.setState({ profileImg: reader.result });
      }
    };

    reader.readAsDataURL(e.target.files[0]);

    console.log(e.target.files);
    this.state.imageData = e.target.files[0];

    // if(this.state.imageData.size >  10485760)
    // {

    //     this.state.fileSizeCheck = false;

    //     toast.error('🦄 Selected file size must be less then 10 MB !!!', {
    //         position: "top-right",
    //         autoClose: 3000,
    //         hideProgressBar: false,
    //         closeOnClick: true,
    //         pauseOnHover: true,
    //         draggable: true,
    //         progress: undefined,
    //         });

    // }
    // else
    // {
    //     this.state.fileSizeCheck = true;
    // }
  };

  //   accessAllProduct = () => {
  //     this.setState({ isLoading: true });
  //     axios
  //       .post("http://localhost/JaswalJee/ShowAllDisPro.php", {
  //         headers: { "x-access-token": localStorage.getItem("accessToken") },
  //       })
  //       .then((response) => {
  //         this.setState({ data: response.data, isLoading: false });

  //         this.setState({ demo: "kol" });
  //       })
  //       .catch(function (error) {
  //         this.setState({ isLoading: false });
  //         swal({
  //           title: "Oh snap! You got an error!!",
  //           text: TECHNICAL_ERROR_MESSAGE,
  //           icon: "error",
  //           button: "Close",
  //         });
  //         alert(error);
  //       });
  //   };

  //   componentDidMount() {
  //     this.setState({ isLoading: true });
  //     axios
  //       .post(
  //         `${process.env.REACT_APP_API_URL_BACKEND_CALL}/allRegProduct`,
  //         {},
  //         { headers: { "x-access-token": localStorage.getItem("accessToken") } }
  //       )
  //       .then((response) => {
  //         // for(let i=0;i<response.data.length;i++)
  //         // {
  //         //     this.state.companySelect.push({ name:"company",empID : response.data[i].companyCode ,value: response.data[i].companyName, label: response.data[i].companyName});
  //         // }
  //         // this.accessAllProduct();

  //         this.setState({ data: response.data, isLoading: false });
  //       })
  //       .catch(function (error) {
  //         this.setState({ isLoading: false });
  //         swal({
  //           title: "Oh snap! You got an error!!",
  //           text: TECHNICAL_ERROR_MESSAGE,
  //           icon: "error",
  //           button: "Close",
  //         });
  //         alert(error);
  //       });
  //   }

  render() {
    // if (this.state.checkLoginStatus === false) {
    //   return <Redirect to="/userLogin" />;
    // }

    // const { selectedOption } = this.state;
    return (
      <div>
        {this.state.isLoading && <WSppiner isLoading={this.state.isLoading} />}
        <div className="headerBarTop">
          <Header />
        </div>

        <div className="mobileViewSidebar">
          <SideBarMobile />
        </div>

        <div className="container-fluid ">
          <div className="row ">
            <div className="col-xl-2 col-md-12 col-sm-12 sideBARColor">
              <SideBar />
            </div>

            <div className="col-xl-10 col-md-12 col-sm-12  mainBar">
              {/* <button
                className="toggleShowFormButton "

                onClick={() => {
                  this.openProductSliderForm();
                }}
              >
                Best selling products slider
              </button> */}
<h6 className="toggleShowFormButton w-50">Best selling products slider</h6>
              {/* <button
                className="toggleShowFormButton"
                onClick={() => {
                  this.openNewArrivalsForm();
                }}
              >
                New arrivals product slider
              </button> */}

              {this.state.showProductSliderForm && (
                <div className="row mt-3">
                  <div className="col-xl-10 col-md-12 col-sm-12 ">
                    <form>
                      <Card className="text-center ">
                        <Card.Header>adding to product slider</Card.Header>
                        <Card.Body>
                          <Card.Text>
                            <div class="row">
                              <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 inMobileViewButton">
                                <input
                                  type="text"
                                  placeholder="name"
                                  class="form-control"
                                  name="slider_name"
                                  value={this.state.slider_name}
                                  onChange={this.handleChangeAll}
                                />
                              </div>
                              <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 inMobileViewButton">
                                <input
                                  type="text"
                                  placeholder="MRP"
                                  class="form-control"
                                  name="slider_price"
                                  value={this.state.slider_price}
                                  onChange={this.handleChangeAll}
                                />
                              </div>
                              <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 inMobileViewButton">
                                <input
                                  type="text"
                                  placeholder="discount"
                                  class="form-control"
                                  name="slider_discount"
                                  value={this.state.slider_discount}
                                  onChange={this.handleChangeAll}
                                />
                              </div>
                              <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 inMobileViewButton">
                                <input
                                  type="text"
                                  placeholder="ratings"
                                  class="form-control"
                                  name="slider_ratings"
                                  value={this.state.slider_ratings}
                                  onChange={this.handleChangeAll}
                                />
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12">
                                <div className="form-group"></div>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                <div className="form-group">
                                  <p>
                                    Upload Image &nbsp;&nbsp;&nbsp;&nbsp;
                                    <input
                                      type="file"
                                      id="file"
                                      // disabled = {true}
                                      onChange={this.uploadMultipleFiles}
                                      className="SubmitButtonAddToProductSlider"
                                    />
                                  </p>
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-md-12">
                                <div className="form-group"></div>
                              </div>
                            </div>

                            <div className="previewImage">
                              <div className="form-group multi-preview showSelectedImages">
                                {(this.state.fileArray || []).map((url) => (
                                  <center>
                                    <img
                                      src={url}
                                      className="ProductImage "
                                      height="350px"
                                      width="400px"
                                      alt="..."
                                    />
                                  </center>
                                ))}
                              </div>
                            </div>
                          </Card.Text>

                          <input
                            type="submit"
                            onClick={(e) => {
                              e.preventDefault();
                              this.handleSubmitHomeProductSlider();
                            }}
                            disabled={
                              this.state.slider_name != "" &&
                              this.state.slider_price != "" &&
                              this.state.slider_discount != "" &&
                              this.state.slider_ratings != ""
                                ? false
                                : true
                            }
                            className="SubmitButtonAddToProductSlider"
                            value="submit"
                          />
                        </Card.Body>
                        <Card.Footer className="text-muted"></Card.Footer>
                        <h6 id="para"></h6>
                      </Card>
                    </form>
                  </div>
                </div>
              )}
              {this.state.showNewArrivalsForm && (
                <div className="row mt-3">
                  <div className="col-xl-10 col-md-12 col-sm-12 ">
                    <form>
                      <Card className="text-center ">
                        <Card.Header>Adding to new arrivals </Card.Header>
                        <Card.Body>
                          <Card.Text>
                            <div class="row">
                              <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 inMobileViewButton">
                                <input
                                  type="text"
                                  placeholder="name"
                                  class="form-control"
                                  name="arrivals_name"
                                  value={this.state.arrivals_name}
                                  onChange={this.handleChangeAll}
                                />
                              </div>
                              <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 inMobileViewButton">
                                <input
                                  type="text"
                                  placeholder="MRP"
                                  class="form-control"
                                  name="arrivals_price"
                                  value={this.state.arrivals_price}
                                  onChange={this.handleChangeAll}
                                />
                              </div>
                              <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 inMobileViewButton">
                                <input
                                  type="text"
                                  placeholder="discount"
                                  class="form-control"
                                  name="arrivals_discount"
                                  value={this.state.arrivals_discount}
                                  onChange={this.handleChangeAll}
                                />
                              </div>
                              <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 inMobileViewButton">
                                <input
                                  type="text"
                                  placeholder="ratings"
                                  class="form-control"
                                  name="arrivals_ratings"
                                  value={this.state.arrivals_ratings}
                                  onChange={this.handleChangeAll}
                                />
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12">
                                <div className="form-group"></div>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                <div className="form-group">
                                  <p>
                                    Upload Image &nbsp;&nbsp;&nbsp;&nbsp;
                                    <input
                                      type="file"
                                      id="file"
                                      // disabled = {true}
                                      onChange={this.uploadMultipleFiles}
                                      className="SubmitButtonAddToProductSlider"
                                    />
                                  </p>
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-md-12">
                                <div className="form-group"></div>
                              </div>
                            </div>

                            <div className="previewImage">
                              <div className="form-group multi-preview showSelectedImages">
                                {(this.state.fileArray || []).map((url) => (
                                  <center>
                                    <img
                                      src={url}
                                      className="ProductImage "
                                      height="350px"
                                      width="400px"
                                      alt="..."
                                    />
                                  </center>
                                ))}
                              </div>
                            </div>
                          </Card.Text>

                          <input
                            type="submit"
                            onClick={(e) => {
                              e.preventDefault();
                              this.handleSubmitNewArrivals();
                            }}
                            disabled={
                              this.state.arrivals_name != "" &&
                              this.state.arrivals_price != "" &&
                              this.state.arrivals_discount != "" &&
                              this.state.arrivals_ratings != ""
                                ? false
                                : true
                            }
                            className="SubmitButtonAddToProductSlider"
                            value="submit"
                          />
                        </Card.Body>
                        <Card.Footer className="text-muted"></Card.Footer>
                        <h6 id="para"></h6>
                      </Card>
                    </form>
                  </div>
                </div>
              )}
             <div className='modeSetButtonsDiv'>
             <div>
                <button
                  className="modesetButton"
                  onClick={() => this.setModeFunction("automatic")}
                >
                  Automatic
                </button>
              </div>
              <div>
                <button
                  className="modesetButton"
                  onClick={() => this.setModeFunction("manual")}
                >
                  Manual
                </button>
              </div>
             </div>
            </div>

            {/* <div className="col-xl-10 col-md-12 col-sm-12  mainBar">
              <h3>Product Details</h3>
              <div className="row companyPro">
                <div className="col-xl-6 col-md-12 col-sm-12 Headsearch">
                  <h3>Total Registered Product</h3>

                  <SearchbarProduct
                    key={this.state.data}
                    data={this.state.data}
                    resultShow={this.state.resultShow}
                  />
                </div>

                <div className="col-xl-6 col-md-12 col-sm-12 mt-3">
                  <h4>Add New Company Product</h4>

                  <div>
                    <div className="">
                      <form className="formComPro" onSubmit={this.handleSubmit}>
                        <input
                          type="text"
                          className="form-control"
                          name="productName"
                          placeholder="Enter the New Product Name"
                          value={this.state.productName}
                          onChange={this.handleChangeAll}
                          required
                        />
                        <br />
                        <input className="stockSubmitButton" type="submit" />
                      </form>

                      <h6 id="para"></h6>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>

        <ToastContainer />
      </div>
    );
  }
}

export default AddToProductSlider;
