import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import swal from 'sweetalert';
import { TECHNICAL_ERROR_MESSAGE, TECHNICAL_OTP_ERROR_MESSAGE } from '../Customer/Constant/Constant';
import Header from '../ClientFolder/Header';
import SideBar from './SideBar';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../ClientFolder/css/Home.css';
import ReactPaginate from 'react-paginate';
import { Redirect, Link } from 'react-router-dom';
// import filters from '../ClientFolder/Filters';
// import moreDatails from './Images/moreDatails.png';
// import check from './Images/check.png';
// import addresImage from './Images/site.png';
// import customerImage from './Images/user.png';
// import close from './Images/close.png';
import { Button, Card, Modal } from 'react-bootstrap';
import SideBarMobile from './sidebar/Sidebar';
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import WSppiner from '../../common/WSppiner';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import '../ClientFolder/css/NewOrderDetails.css';
import { MdTrendingUp } from 'react-icons/md';
import { generateOTP } from '../sharedModuls/Utils/Utils';

let priceStoredObj = [];


class MoreOrderDetails extends Component {
    constructor(props) {
        super(props);

        this.state = {
            checkLoginStatus: true,
            data: [],
            data2: [],
            offset: 0,
            tableData: [],
            orgtableData: [],
            perPage: 10,
            currentPage: 0,
            orderID: 0,
            assignmentID: 0,
            afterDiscount: 0,
            totalQunatity: 0,

            usrData: this.props.match.params.orderNumber,
            totalAmt: 0,
            addressTab: false,
            customerTab: false,
            customerID: 0,
            delAddressID: 0,

            delLocality: "",
            delLandmark: "",
            delCity: "",
            delState: "",
            delCountry: "",
            delPincode: "",
            delMobileF: "",
            delMobileS: "",


            cusName: "",
            cusEmail: "",
            cusAddress: "",
            cusCity: "",
            cusState: "",
            cusCountry: "",
            cusPincode: "",
            cusMobile: "",
            cusGender: "",
            isLoading: false,
            productIDs: [],
            sentOTP: "",
            otp: "",
            assignmentid: "",
            resendButtonStatus: true,
            countdowntTime: 15000


        }

        let token = sessionStorage.getItem("deliveryBoyEmail");

        if (token == null || token == undefined || token == "") {

            this.state.checkLoginStatus = false;
        }
    }

    componentDidUpdate() {
        if (this.state.otp == this.state.sentOTP && this.state.otp != "") {
            document.getElementById("OtpError").style.display = "none";
            document.getElementById("OtpSubmit").disabled = false;
            document.getElementById("OtpSubmit").style.backgroundColor = "#f39c12";
        }
        else {
            document.getElementById("OtpError").style.display = "block";
            document.getElementById("OtpSubmit").disabled = true;
            document.getElementById("OtpSubmit").style.backgroundColor = "gray";
        }
    }

    componentDidMount() {
        //this.onLoadFunction()
        document.getElementById("resendButton").style.display = "none";
        document.getElementById("newAndConfirmPass").style.display = "none";
        // document.getElementById("OtpError").style.display = "none";
        let arrD = this.state.usrData.split("pro");
        this.getDeliveryOrderRecord(arrD[0]);
        this.getOrderDetails(arrD[1]);
        this.setState({assignmentid:arrD[0]});

    }

    handleModalAddressTab = () => {
        this.setState({ addressTab: !this.state.addressTab });
    }
    confirmDelivery=(event)=>{
        event.preventDefault();
        if(this.state.otp!=''){
           
        this.setState({ isLoading: true });
        axios.post(`${process.env.REACT_APP_API_URL_BACKEND_CALL}/ConfirmDelivery`, { assignmentid: this.state.assignmentid }
            , { headers: { 'x-access-token': localStorage.getItem("accessToken") } }).
            then(response => {
                console.log(response)
                this.setState({ isLoading: false,
                    resendButtonStatus:false });
                document.getElementById("confirmDelivery").style.backgroundColor = "gray";
                document.getElementById("confirmDelivery").disabled = false;
                document.getElementById("resendButton").style.display = "none";
                document.getElementById("newAndConfirmPass").style.display = "none";
            })
            .catch(error => {
                this.setState({ isLoading: false });
                swal({
                    title: "Oh snap! You got an error!!",
                    text: TECHNICAL_ERROR_MESSAGE,
                    icon: "error",
                    button: "Close",
                });
                console.log(error)
            })
        }
    }
    handleModalCustomerTab = () => {
        // this.setState({ customerTab: !this.state.customerTab });
        let otp = generateOTP();
        this.setState({ sentOTP: otp });
        axios.post(`${process.env.REACT_APP_API_URL_BACKEND_CALL}/SendCustomerOTP`, { mobile: this.state.delMobileF, otp: otp }
            , { headers: { 'x-access-token': localStorage.getItem("accessToken") } }).
            then(response => {
                this.setState({
                    isLoading: false
                });
                if (response.data.successStatus === true) {
                    document.getElementById("newAndConfirmPass").style.display = "block";
                    document.getElementById("OtpSubmit").disabled = false;
                    document.getElementById("OtpSubmit").style.backgroundColor = "#f39c12";
                    document.getElementById("OtpError").style.display = "none";
                    document.getElementById("resendButton").style.display = "none";
                    
                    setTimeout(() => {
                        
                        document.getElementById("OtpSubmit").disabled = true;
                        document.getElementById("OtpSubmit").style.backgroundColor = "gray";
                        if(this.state.resendButtonStatus){
                            document.getElementById("resendButton").style.display = "block";
                        }
                        
                        this.setState({ sentOTP: ''})
                    }, this.state.countdowntTime);
                }
                else {
                    swal({
                        title: "OTP Not Sent!!",
                        text: TECHNICAL_OTP_ERROR_MESSAGE,
                        icon: "error",
                        button: "Close",
                    });
                }
            })
            .catch(error => {
                this.setState({ isLoading: false });
                swal({
                    title: "Oh snap! You got an error!!",
                    text: TECHNICAL_ERROR_MESSAGE,
                    icon: "error",
                    button: "Close",
                });
                console.log(error)
            })

    }
    getProductdeliveryINformation = () => {
        this.setState({ isLoading: true });
        axios.post(`${process.env.REACT_APP_API_URL_BACKEND_CALL}/getProductdeliveryINformation`, { proID: this.state.productIDs }
            , { headers: { 'x-access-token': localStorage.getItem("accessToken") } }).
            then(response => {



                this.setState({
                    data: response.data,
                    isLoading: false
                });
            })
            .catch(error => {
                this.setState({ isLoading: false });
                swal({
                    title: "Oh snap! You got an error!!",
                    text: TECHNICAL_ERROR_MESSAGE,
                    icon: "error",
                    button: "Close",
                });
                console.log(error)
            })
    }
    getOrderDetails = (orderID) => {
        let sum = 0;
        let item = 0;
        this.setState({ isLoading: true });
        axios.post(`${process.env.REACT_APP_API_URL_BACKEND_CALL}/getOrderDetails`, { orderID: orderID }
            , { headers: { 'x-access-token': localStorage.getItem("accessToken") } }).
            then(response => {

                this.setState({});

                this.getCustomerAddress(response.data[0].dileveryAddId);
                for (let i = 0; i < response.data.length; i++) {
                    this.state.productIDs.push(response.data[i].productID);
                    sum = sum + (parseInt(response.data[i].proPrice) * parseInt(response.data[i].quantity));
                    item = item + parseInt(response.data[i].quantity);
                }
                this.setState({
                    isLoading: false,
                    totalAmt: sum,
                    totalQunatity: item
                })
                this.getProductdeliveryINformation();
            })
            .catch(error => {
                this.setState({ isLoading: false });
                swal({
                    title: "Oh snap! You got an error!!",
                    text: TECHNICAL_ERROR_MESSAGE,
                    icon: "error",
                    button: "Close",
                });
                console.log(error)
            })
    }

    getCustomerAddress = (delAddressID) => {
        this.setState({ isLoading: true });
        axios.post(`${process.env.REACT_APP_API_URL_BACKEND_CALL}/getCustomerAddress1`, { delAddress: delAddressID }
            , { headers: { 'x-access-token': localStorage.getItem("accessToken") } }).
            then(response => {



                this.setState({
                    delMobileF: response.data[0].mobile,
                    delMobileS: response.data[0].altmobile,
                    delLocality: response.data[0].locality,
                    delLandmark: response.data[0].landmark,
                    delCity: response.data[0].city,
                    delState: response.data[0].state,
                    delPincode: response.data[0].pincode,
                    delCountry: response.data[0].country,
                    addressTab: true,
                    isLoading: false
                });
            })
            .catch(error => {
                this.setState({ isLoading: false });
                swal({
                    title: "Oh snap! You got an error!!",
                    text: TECHNICAL_ERROR_MESSAGE,
                    icon: "error",
                    button: "Close",
                });
                console.log(error)
            })
    }
    verifyDelivery = () => {

    }

    // getCustomerDetails 

    getDeliveryOrderRecord = (ID) => {
        this.setState({ isLoading: true });
        axios.post(`${process.env.REACT_APP_API_URL_BACKEND_CALL}/getDeliveryOrderRecord`, { assignmentid: ID }
            , { headers: { 'x-access-token': localStorage.getItem("accessToken") } }).
            then(response => {
                this.setState({ isLoading: false });

            })
            .catch(error => {
                this.setState({ isLoading: false });
                swal({
                    title: "Oh snap! You got an error!!",
                    text: TECHNICAL_ERROR_MESSAGE,
                    icon: "error",
                    button: "Close",
                });
                console.log(error)
            })
    }
    handleChangeAll = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }




    render() {
        // if(this.state.checkLoginStatus ===  false)
        // {
        //     return <Redirect  to="/userLogin" />
        // }


        return (

            <div>
                {this.state.isLoading && <WSppiner isLoading={this.state.isLoading} />}
                <div className="headerBarTop">

                    <Header />
                </div>

                <div className="mobileViewSidebar">
                    <SideBarMobile />
                </div>

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-2 col-md-12 col-sm-12 sideBARColor">

                            <SideBar />

                        </div>

                        <div className="col-xl-10 col-md-12 col-sm-12  mainBar">


                            <div className="container-fluid">
                                <div className="row">

                                    <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12">

                                        <p>Product Information</p>
                                        <div className="tableDiv">

                                            <table className="table">
                                                <thead className="tableHead">
                                                    <tr>
                                                        <th>S.no</th>
                                                        <th>Product</th>
                                                        <th>Category</th>
                                                        <th>Color</th>
                                                        <th>Size</th>
                                                        <th>Price</th>
                                                        <th>Quantity</th>
                                                        <th>Offer</th>
                                                    </tr>
                                                </thead>

                                                <tbody>

                                                    {
                                                        this.state.data.map((user, index) => (

                                                            <tr key={index}>
                                                                <td>{index + 1}</td>
                                                                <td>{user.productName}</td>
                                                                <td>{user.pCategory + " " + user.pCategory1 + " " + user.pCategory2}</td>
                                                                <td>{user.pColor}</td>
                                                                <td>{user.pSize}</td>
                                                                <td>&#8377;{user.pMrp}</td>
                                                                <td>{user.pQuantity}</td>
                                                                <td>{user.offer}%</td>
                                                            </tr>

                                                        ))
                                                    }

                                                </tbody>

                                            </table>

                                        </div>

                                    </div>
                                    <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12" >
                                        <p>Delivery Address</p>
                                        <div className="row" style={{ backgroundColor: 'white' }}>
                                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                <table className="table">
                                                    <tr>
                                                        <td>Mobile : </td> <td>{this.state.delMobileF}</td>
                                                    </tr>
                                                    {/* <tr>
                                                        <td>Alt mobile : </td> <td>{this.state.delMobileS}</td>
                                                    </tr> */}
                                                    <tr>
                                                        <td>Locality : </td> <td>{this.state.delLocality}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Landmark : </td> <td>{this.state.delLandmark}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>City : </td> <td>{this.state.delCity}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>State : </td> <td>{this.state.delState}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Country : </td> <td>{this.state.delCountry}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Pincode : </td> <td>{this.state.delPincode}</td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div className="row inMobileView" style={{ justifyContent: 'center' }}>
                                    <div className="col-xl-2 col-lg-2 col-md-12 col-sm-12" style={{ marginTop: "5%", height: "0%" , textAlign:'left', }}>
                                        <p className="" style={{lineHeight:'7px'}}> <b>Quantity: {this.state.totalQunatity}</b></p>
                                        <p className="" style={{lineHeight:'7px'}} > <b>Amount: &#8377;{this.state.totalAmt}</b></p>
                                        <p className="" style={{lineHeight:'7px'}} > <b>GST: {this.state.totalAmt*(0.05)}</b></p>
                                        <p className="" style={{lineHeight:'7px'}} > <b>Total Amount: {this.state.totalAmt+this.state.totalAmt*(0.05)}</b></p>
                                    </div>
                                    <div className="col-xl-2 col-lg-2 col-md-12 col-sm-12" style={{ marginTop: "5%", height: "0%" }}>
                                        <h6 className="newOrderDetailsButton " id='confirmDelivery' onClick={this.handleModalCustomerTab}>Confirm Delivery</h6>
                                    </div>
                                    <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                                        <div id="currentEmailID" className="newAndConfirmPass">

                                            <form style={{ marginTop: "0%", marginLeft: "10%", width: "80%" }} >
                                                <div className="newAndConfirmPass" id="newAndConfirmPass">
                                                    <div style={{ marginTop: "10px" }}>
                                                        <input type="text" name="otp" value={this.state.otp} onChange={this.handleChangeAll} className="form-control" placeholder="Enter OTP" />
                                                        <p id='OtpError'>Invalid OTP</p>
                                                    </div>
                                                    <input type="submit" value="Submit" id='OtpSubmit'onClick={this.confirmDelivery} className="addAddress" style={{ marginTop: "2%", marginBottom: "5%" }} />
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    <div className="col-xl-2 col-lg-2 col-md-12 col-sm-12" style={{ marginTop: "5%", height: "0%" }}>
                                    <h6 className="newOrderDetailsButton " id='resendButton' onClick={this.handleModalCustomerTab}>Re send</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}



export default MoreOrderDetails;