import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import Header from './Header';
import SideBar from './SideBar';
import './../Customer/css/HomeCustomer.css';
import SideBarMobile from './sidebar/Sidebar';
import {Redirect,Link} from 'react-router-dom';
import {totalCustomerApi,totalUserApi}from '../ApiFolder/AdminInformationApi';
import WSppiner from '../../common/WSppiner';
import currencyFormatter from 'currency-formatter';

export class employessHomeComponent extends Component {


    constructor(props)
    {
        super(props);

        this.state = {
            totalCustomer:0,
            totalCredit:0,
          checkLoginStatus : true, 
          loading:false,
        }

        let token  = JSON.parse(sessionStorage.getItem("employessLoginDetails"));

        if(token ==  null || token == undefined || token == "")
        {
            
            this.state.checkLoginStatus = false;
        }
    }

    componentDidMount()
    {
        this.totalCustomer()
    }

    totalCustomer = ()=>{

        axios.post(`${process.env.REACT_APP_API_URL_BACKEND_ENDPOINT}/totalCustomer`,{},
        {headers : {'x-access-token' : localStorage.getItem("accessToken")}})
        .then(response=>{
        this.setState({
            totalCustomer:response.data.totalCustomer,
            totalCredit:response.data.totalCredit})
       })
        .catch(error=>{
            console.log(error)
        })
    }

    render(){


        if(this.state.checkLoginStatus ===  false)
        {
            return <Redirect  to="/employeeLogin" />
        }

        return(

            <div>
                {this.state.loading && 
                
                <WSppiner isLoading={this.state.loading}/>
                
                }

                <div className="headerBarTop">

                <Header />
                </div>

                <div className="mobileViewSidebar">
                <SideBarMobile />
                </div>

                <div className="container-fluid">
                <div className="row">
                <div className="col-xl-2 col-md-12 col-sm-12 sideBARColor">

                <SideBar />

                </div> 

              <div className="col-xl-10 col-md-12 col-sm-12  mainBar">


                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-3 col-md-12 col-sm-12">

                            <div className="allDiveHere">

                            <Link to="/employeeTotalCustomer"  style={{textDecoration : "none" , color : "orange" ,fontSize : "30px",marginTop : "5px"}}>Total Customer</Link>

                            <h3>{this.state.totalCustomer}</h3>
                            </div>
                       

                        </div>

                       
                    </div>


                </div>

              </div>


              </div>
              </div>



            </div>
        )
    }
}



export default employessHomeComponent;
